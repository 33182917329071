<template>
	<div>
		<Banner :bannerinfo="alldata.society.banner"></Banner>
		<div class="wrapper">
			<div class="option">
				<div class="fun">
					<div class="filter" v-text="alldata.society.sift.fun.filt" @click="handlecate('filter')"></div>
					<div class="clear" v-text="alldata.society.sift.fun.clear" @click="handlecate('clear')"></div>
				</div>
				<!-- 职位类别树形 -->
				<div class="type">
					<div class="title" v-text="alldata.society.sift.job.title"></div>
					<el-tree ref="catetree" :default-expanded-keys="[0, 1, 2, 3]" :data="catelog" show-checkbox
						node-key="id" :props="defaultProps" @node-click="test1" @check-change="checkChange('cate')">
					</el-tree>
				</div>
				<!-- 工作地点树形 -->
				<div class="address">
					<div class="title" v-text="alldata.society.sift.city.title"></div>
					<el-tree ref="worktree" :data="alldata.society.sift.city.list" show-checkbox node-key="id"
						:props="defaultProps" @check-change="checkChange('work')">
					</el-tree>
				</div>
			</div>
			<div class="result">
				<div class="title" v-text="alldata.society.choose.title"></div>
				<el-collapse v-model="activeNames" @change="handleChange">
					<el-collapse-item v-for="(item, index) in positionlist" :name="index" :key="index">
						<template slot="title">
							<div class="tite_container">
								<div>
									<span>{{ item.job_name }}</span>
									<img src="@/assets/img/society/expand-arrow.png" class="origin"
										:class="{ rota: judgeActive(index) !== -1 }" alt="pointer">
								</div>
								<div class="info">
									<div class="item" v-text="item.workplace_txt"></div>
									<!-- <div class="item" v-text="item.job_title"></div> -->
									<div class="item" v-text="item.age"></div>
									<div class="item" v-text="item.salary"></div>
									<div class="item" v-text="item.educational_requirements"></div>
								</div>
							</div>
						</template>
						<div class="details">
							<div class="item">
								<div class="smalltitle">
									<div class="line"></div>
									<div class="text" v-text="alldata.society.choose.job"></div>
								</div>
								<div class="content" v-html="item.subheading_title">
									<!-- 暂无内容（后端需要补充的数据） -->
								</div>
							</div>
							<div class="item">
								<div class="smalltitle">
									<div class="line"></div>
									<div class="text" v-text="alldata.society.choose.require"></div>
								</div>
								<div class="content" v-html="item.detail_requirement">
									<!-- 暂无内容（后端需要补充的数据） -->
								</div>
							</div>
						</div>
						<div class="resumbtn">
							<!-- gotopage('/upload') -->
							<button v-text="alldata.society.choose.btn" @click="gopage(item.id)"></button>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
	</div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
export default {
	name: 'Society',
	data() {
		return {
			// 社会招聘代码
			category: 27,
			activeNames: [0],
			catelog: [],
			defaultProps: {
				children: 'childlist',
				label: 'name'
			},
			positionlist: [],
			jobId: [],
			workPlace: []
		};
	},
	components: {
		Banner
	},
	mounted() {
		this.getPreviousData();
	},
	methods: {
		getPreviousData() {
			this.$http.post('commons/job/index', {
				language: this.alldata.nav.current.id,
				category: this.category
			}).then(res => {
				console.log(res);
				const { data } = res;
				if (data.code == 1) {
					let catelog = data.data;
					catelog.forEach((item, index) => {
						item.disabled = true;
						if (item.childlist.length) {
							item.disabled = false;
						}
					})
					console.log(catelog);
					this.catelog = catelog;
				} else {
					this.$message.error(data.msg);
				}
			}).catch(error => { });
			this.$http.post('commons/job/jobindex', {
				category: this.category,
				language: this.alldata.nav.current.id
			}).then(res => {
				const { data } = res;
				console.log(data);
				if (data.code == 1) {
					this.positionlist = data.data.data;
				} else {
					this.$message.error(data.msg);
				}
			}).catch(error => { });
		},
		handleChange(val) {
			console.log(val);
		},
		//判断是否打开
		judgeActive(data) {
			return this.activeNames.indexOf(data);
		},
		async handlecate(state) {
			if (state == 'filter') {
				console.log("处理职位筛选功能");
				const { data: res } = await this.$http.post('commons/job/jobindex', {
					category: this.category,
					language: this.alldata.nav.current.id,
					job_id: this.jobId.join(','),
					workplace: this.workPlace.join(',')
				});
				console.log(res);
				if (res.code != 1) return this.$message.error(res.msg);
				this.positionlist = res.data.data;
			} else if (state == 'clear') {
				console.log("处理职位清除功能");
				this.getPreviousData();
			}
		},
		test1(obj, node, itself) {
			// console.log("节点被点击时的回调",obj,node,itself);
			console.log(obj.id);
			// this.refs.node.getCurrentKey();
		},
		checkChange(state) {
			if (state == 'cate') {
				this.jobId = [];
				// console.log(this.$refs.tree.getCheckedNodes().concat(this.$refs.tree.getHalfCheckedNodes()));
				this.$refs.catetree.getCheckedNodes().concat(this.$refs.catetree.getHalfCheckedNodes()).forEach(item => {
					this.jobId.push(item.id);
				})
				console.log(this.jobId);
			} else if (state == 'work') {
				console.log("工作地点选择");
				this.workPlace = [];
				this.$refs.worktree.getCheckedNodes().forEach(item => {
					this.workPlace.push(item.id);
				})
				console.log(this.workPlace);
			}
		},
		gopage(type) {
			this.$router.push({
				path: '/upload',
				query: {
					type: type
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
.wrapper {
	background-color: var(--bg-color-lighter);
	padding: 5.9375vw 18.75vw 2vw;
	display: flex;

	.option {
		width: 18vw;
		margin-right: 3.125vw;
		// background-color: pink;

		.fun {
			display: flex;
			justify-content: space-between;
			padding-bottom: 1vw;
			border-bottom: 1px solid var(--bg-color-grey);

			.filter {
				cursor: pointer;
				color: var(--text-color);
				font-size: var(--font-size-sm);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}

			.clear {
				cursor: pointer;
				color: var(--color-disabled);
				font-size: var(--font-size-sm);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}
		}

		.type {
			margin-top: 2vw;

			.title {
				margin-bottom: 1vw;
				color: var(--text-color);
				font-size: var(--font-size-mid);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}

			.el-tree {
				background-color: var(--bg-color-lighter);

				::v-deep .el-tree-node__content:hover,
				::v-deep .el-upload-list__item:hover {
					background-color: var(--bg-color-lighter);
				}

				::v-deep .el-tree-node__content>.el-tree-node__expand-icon {
					padding: 0.3125vw;
				}

				// 三角形
				::v-deep .el-icon-caret-right:before {
					font-size: 0.3125vw;
				}

				//复选框
				::v-deep .el-tree-node__content>label.el-checkbox {
					margin-right: 0.5vw;
				}

				//复选框后文字
				::v-deep .el-tree-node__label {
					color: var(--color-disabled);
					font-size: var(--font-size-ens);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}

		.address {
			margin-top: 2vw;

			.title {
				margin-bottom: 1vw;
				color: var(--text-color);
				font-size: var(--font-size-mid);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}

			.el-tree {
				background-color: var(--bg-color-lighter);

				::v-deep .el-tree-node__expand-icon.is-leaf {
					display: none;
				}

				::v-deep .el-tree-node__content:hover,
				::v-deep .el-upload-list__item:hover {
					background-color: var(--bg-color-lighter);
				}

				::v-deep .el-tree-node__content>label.el-checkbox {
					margin-right: 0.5vw;
				}

				::v-deep .el-tree-node__label {
					color: var(--color-disabled);
					font-size: var(--font-size-ens);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}

	.result {
		width: 100%;
		// background-color: pink;

		.title {
			margin-bottom: 2vw;
			color: var(--text-color);
			font-size: var(--font-size-h);
			font-family: var(--font-family);
			font-weight: var(--font-weight-s);
		}

		.el-collapse {
			border: none;

			::v-deep .el-collapse-item__arrow {
				display: none !important;
			}

			.el-collapse-item {
				border-top: 2px solid var(--bg-color-origin);

				&:not(:last-child) {
					margin-bottom: 1.5625vw;
				}

				.tite_container {
					width: 100%;

					&>div:first-child {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.origin {
							width: 1.5625vw;
							height: 1.5625vw;
							transition: transform 1s;
						}

						.rota {
							transform: rotate(-180deg);
						}
					}
				}
			}

			::v-deep .el-collapse-item__header {
				height: auto;
				border: none;
				padding: 0 1vw;
				color: var(--text-color);
				font-size: var(--font-size-lg);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);

				.el-collapse-item__arrow {
					width: 1.6vw;
					height: 1.6vw;
					border-radius: 50%;
					border: 1px solid;
					display: flex;
					justify-content: center;
					align-items: center;
					// color: var(--text-color);
				}
			}
		}

		.info {
			// background-color: pink;
			padding: 0.5vw 0vw;
			display: flex;

			.item {
				// background-color: skyblue;
				padding: 0 0.5vw;
				border-right: 1px solid var(--color-disabled);
				line-height: 0.8vw;
				color: var(--color-disabled);
				font-size: var(--font-size-ens);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);

				&:first-child {
					padding-left: 0
				}

				&:last-child {
					padding-right: 0;
					border: 0;
				}
			}
		}

		.details {
			padding: 0.625vw 2.5vw 1.25vw;

			.item {
				&:not(:last-child) {
					margin-bottom: 3vw;
				}

				.smalltitle {
					display: flex;
					align-items: center;

					.line {
						width: 0.52vw;
						height: 0.9375vw;
						background-color: var(--bg-color-origin);
						margin-right: 0.5vw;
					}

					.text {
						color: var(--text-color);
						font-size: var(--font-size-lg);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}
				}

				.content {
					color: var(--color-disabled);
					font-size: var(--font-size-ens);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}

		.resumbtn {
			padding: 0 2.5vw;
			// background-color: pink;

			&>button {
				width: 10vw;
				height: 2.1875vw;
				border-radius: 1.09375vw;
				background-color: var(--color-success);
				color: var(--text-color-base);
				font-size: var(--font-size-sm);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}
		}
	}
}
</style>

<template>
    <div>
        <Banner :bannerinfo="alldata.independence.banner" @consult="gotopage('/consult')"></Banner>
        <Enindependence v-if="alldata.nav.current.text==='English'"></Enindependence>
        <Zhindependence v-else></Zhindependence>
    </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue';
import Enindependence from '@/components/Specified/Enindependence.vue';
import Zhindependence from '@/components/Specified/Zhindependence.vue';
export default {
    name: 'Independence',
    data() {
        return {
            navIndex: 0
        }
    },
    components: {
        Banner,
        Enindependence,
        Zhindependence
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods: {
        /* waiting() {
            console.log("咨询啊！！");
        } */
    }
}
</script>

<style scoped lang="scss">
</style>
<template>
  <div>
    <div class="build_service">
      <div class="heading">稳定安全的建站服务</div>
      <div class="stitle">
        为了满足企业和商家的各种精细化需求，夸父自研建站系统，旨在通过多元的技术解决方案实现高品质建站
      </div>

      <DoubleSwiper :banner="banner" />
    </div>
    <div class="onestep">
      <div class="title">一站式服务，解决建站难题</div>
      <div class="result">
        <div
          class="item"
          v-for="(item, index) in onestep_list"
          :key="index"
        >
          <div class="figure">
            <img :src="item.image" />
          </div>
          <div class="text">{{ item.text }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="btn">
        <img
          @click="gotopage('/consult')"
          src="@/assets/img/independence/lianxi-anniu-2.png"
        />
      </div>
    </div>
    <div class="more">
      <div class="otitle">
        <div class="small">涵盖</div>
        <div
          class="big"
          v-text="alldata.independence.website.more.otitle.big"
        ></div>
        <div
          class="small"
          v-text="alldata.independence.website.more.otitle.small"
        ></div>
      </div>
      <div
        class="subheading"
        v-text="alldata.independence.website.more.stitle"
      ></div>
      <div
        class="result"
        v-show="false"
      >
        <div
          class="item"
          :class="{ border: index == navIndex }"
          v-for="(item, index) in alldata.independence.website.more.result"
          :key="index"
        >
          <div
            class="nav"
            v-text="item.nav"
          ></div>
        </div>
      </div>
      <div class="pictures">
        <div
          class="content_wrapper"
          v-for="(item, index) in alldata.independence.pictures"
          :key="index"
        >
          <img :src="item.image" />
          <span>{{ item.text }}</span>
        </div>
      </div>
      <div
        class="btn"
        v-show="false"
      >
        <button v-text="alldata.independence.website.more.btn"></button>
      </div>
    </div>
    <div class="service">
      <div
        class="official"
        v-text="alldata.independence.service.title"
      ></div>
      <div class="result">
        <div
          class="item"
          :class="{ reverse: index % 2 != 0 }"
          v-for="(item, index) in alldata.independence.service.result"
          :key="index"
        >
          <div class="left">
            <div
              class="title"
              v-text="item.title"
            ></div>
            <div class="content">
              <div
                class="item"
                v-for="(aitem, aindex) in item.content"
                :key="aindex"
              >
                <img
                  src="@/assets/img/icon/check-icon.png"
                  alt="icon"
                />
                <span v-text="aitem"></span>
              </div>
            </div>
          </div>
          <div class="right">
            <img
              :src="item.image"
              alt="figure"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="website_result">
      <div
        class="item"
        v-for="(item, index) in alldata.independence.website.result"
        :key="index"
      >
        <div
          class="title"
          v-text="item.title"
        ></div>
        <div class="content">
          <div
            class="aitem"
            v-for="(aitem, aindex) in item.content"
            :key="aindex"
          >
            <div class="check">
              <img
                src="@/assets/img/icon/check-white-icon.png"
                alt="icon"
              />
            </div>
            <div class="explain">
              <div
                class="toprice"
                v-text="aitem.title"
              ></div>
              <div
                class="text"
                v-text="aitem.text"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Addvertisement :addverse="alldata.independence.team"></Addvertisement>
    <div class="about">
      <div class="title">
        <div
          class="big"
          v-text="alldata.independence.about.title.big"
        ></div>
        <div
          class="small"
          v-text="alldata.independence.about.title.small"
        ></div>
      </div>
      <div class="content">
        <div
          class="toprice"
          v-text="alldata.independence.about.content.title"
        ></div>
        <div
          class="content"
          v-text="alldata.independence.about.content.text"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Addvertisement from '@/components/Common/Addvertisement.vue'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import DoubleSwiper from './DoubleSwiper.vue'
export default {
  name: 'Zhindependence',
  data() {
    return {
      onestep_list: [
        {
          image: require('@/assets/img/independence/yizhanshi-1.png'),
          text: '深入的市场分析，洞悉用户画像'
        },
        {
          image: require('@/assets/img/independence/yizhanshi-2.png'),
          text: '资深UI设计界面，精美模板选择'
        },
        {
          image: require('@/assets/img/independence/yizhanshi-3.png'),
          text: '前端和后端开发，沉浸式交互体验'
        },
        {
          image: require('@/assets/img/independence/yizhanshi-4.png'),
          text: '搭建收付渠道，销售宣传一体化'
        },
        {
          image: require('@/assets/img/independence/yizhanshi-5.png'),
          text: '专业团队指导，安全合规易上手'
        }
      ],
      navIndex: 0,
      banner: [],
      myswiper: ''
    }
  },
  components: {
    Addvertisement,
    DoubleSwiper
  },
  created() {
    this.getCar(31)
  },

  methods: {
    getCar(id) {
      this.banner = []
      this.$http
        .post('commons/caseshow/index', {
          language: this.alldata.nav.current.id,
          category: id
        })
        .then((res) => {
          const { data } = res
          this.banner = data.data.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.build_service {
  padding: 7.5vw 18.75vw 5.2vw;
  text-align: center;

  .heading {
    color: var(--text-color);
    font-size: var(--font-size-h);
    font-weight: var(--font-weight-m);
    font-family: var(--font-family-zh);
  }

  .stitle {
    width: 42%;
    margin: 1vw auto 4.2vw;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-sm);
    font-family: var(--font-family-zh);
  }
}

.onestep {
  // background-color: pink;
  padding-bottom: 2.86vw;

  .title {
    margin-bottom: 3.38vw;
    text-align: center;
    color: var(--text-color);
    font-size: var(--font-size-h);
    font-weight: var(--font-weight-m);
    font-family: var(--font-family-zh);
  }

  .result {
    display: flex;
    justify-content: center;

    .item {
      width: 11.45vw;
      height: 14.58vw;
      background-color: var(--bg-color-origin);
      border-radius: 1.5625vw;
      box-sizing: border-box;
      padding: 1.5625vw;

      &:not(:last-child) {
        margin-right: 1.3vw;
      }

      .figure {
        & > img {
          width: 5.2vw;
        }
      }

      .text {
        margin: 1.5vw 0;
        color: var(--text-color-base);
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-sm);
        font-family: var(--font-family-zh);
      }

      .line {
        width: 1.25vw;
        height: 0.1vw;
        background-color: #ffffff;
      }
    }
  }

  .btn {
    margin-top: 3vw;
    text-align: center;

    & > img {
      width: 16.45vw;
      cursor: pointer;
    }
  }
}

.more {
  padding: 5.5vw 0 3.5vw;
  // margin-top: 6vw;
  background-color: var(--bg-color-black);

  .otitle {
    display: flex;
    justify-content: center;
    align-items: center;

    .big {
      color: var(--text-color-inverse);
      font-size: var(--font-size-xxxl);
      font-family: var(--font-family);
      font-weight: var(--font-weight-m);
      font-style: italic;
    }

    .small {
      // width: 20%;
      color: var(--text-color-base);
      font-size: var(--font-size-hug);
      font-family: var(--font-family);
      font-weight: var(--font-weight-m);
    }
  }

  .subheading {
    width: 50%;
    margin: 1.5vw auto 2vw;
    text-align: center;
    color: var(--text-color-base);
    font-size: var(--font-size-sm);
    font-family: var(--font-family);
    font-weight: var(--font-weight-s);
  }

  .result {
    display: flex;
    justify-content: center;

    .item {
      margin: 0 0 3vw;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      padding: 0.5vw;

      &:not(:last-child) {
        margin-right: 2.5vw;
      }

      .nav {
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
      }
    }

    .border {
      border-bottom: 2px solid var(--color-active);
    }
  }

  .pictures {
    height: 38vw;
    padding: 0 2vw;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: wrap;

    .content_wrapper {
      display: flex;
      flex-direction: column;

      & > img {
        width: 22.395vw;
      }

      & > span {
        margin-top: 0.9375vw;
        text-align: center;
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-weight: var(--font-weight-s);
        font-family: var(--font-family-zh);
      }
    }
  }

  .btn {
    text-align: center;

    & > button {
      border: 1px solid var(--color-active);
      width: 15.5625vw;
      height: 3.75vw;
      background-color: transparent;
      color: var(--text-color-base);
      font-size: var(--font-size-sm);
      font-family: var(--font-family);
      font-weight: var(--font-weight-s);
    }
  }
}

.service {
  margin-top: 7.8125vw;

  .official {
    width: 32%;
    margin: 0 auto 3.125vw;
    word-break: keep-all;
    word-wrap: keep-all;
    text-align: center;
    color: var(--text-color);
    font-size: var(--font-size-hug);
    font-family: var(--font-family);
    font-weight: var(--font-weight-m);
  }

  .result {
    margin: 0 18.4375vw;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        .title {
          margin-bottom: 1.875vw;
          color: var(--text-color);
          font-size: var(--font-size-lg);
          font-family: var(--font-family);
          font-weight: var(--font-weight-s);
        }

        .content {
          .item {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            &:not(:last-child) {
              margin-bottom: 0.5vw;
            }

            & > img {
              width: 1vw;
              margin-right: 0.52vw;
            }

            & > span {
              color: var(--text-color);
              font-size: var(--font-size-sm);
              font-family: var(--font-family);
              font-weight: var(--font-weight-s);
            }
          }
        }
      }

      .right {
        & > img {
          width: 31.25vw;
        }
      }
    }

    .reverse {
      flex-direction: row-reverse;
    }
  }
}

.website_result {
  // margin: 5.2vw 18.4375vw 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .item {
    padding: 3.125vw 2vw;
    background-color: skyblue;
    border-radius: 1vw;

    &:first-child {
      width: 36.45vw;
      background-color: var(--bg-color-origin);
      box-sizing: border-box;
      padding-right: 4vw;
    }

    &:last-child {
      width: 26.125vw;
      background-color: var(--color-success);
      margin-left: -5vw;
    }

    .title {
      margin-bottom: 1.5vw;
      // text-align: center;
      color: var(--text-color-base);
      font-size: var(--font-size-hug);
      font-family: var(--font-family);
      font-weight: var(--font-weight-m);
    }

    .content {
      .aitem {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 1vw;
        }

        .check {
          margin-right: 0.5vw;

          & > img {
            width: 1vw;
          }
        }

        .explain {
          .toprice {
            // text-align: left;
            color: var(--text-color-base);
            font-size: var(--font-size-lg);
            font-family: var(--font-family);
            font-weight: var(--font-weight-m);
          }

          .text {
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
          }
        }
      }
    }
  }
}

.about {
  width: 80.67vw;
  height: 33.64vw;
  margin: 2.5vw auto 0;
  /* margin: 3.5vw 9.6875vw 0;
    padding: 9.5vw 9vw 10.5vw; */
  box-sizing: border-box;
  padding: 0 9vw;
  background: url('@/assets/img/independence/map_BG.png') no-repeat scroll
    center center/100% auto;
  display: flex;
  // justify-content: center;
  align-items: center;

  .title {
    // flex: 1;
    text-align: right;
    margin-right: 6vw;

    .big {
      color: var(--text-color);
      font-size: var(--font-size-ll);
      font-family: var(--font-family);
      font-weight: var(--font-weight-m);
    }

    .small {
      color: var(--text-color);
      font-size: var(--font-size-sm);
      font-family: var(--font-family);
      font-weight: var(--font-weight-s);
    }
  }

  .content {
    flex: 1;

    .toprice {
      margin-bottom: 1.7vw;
      color: var(--color-success);
      font-size: var(--font-size-xl);
      font-family: var(--font-family);
      font-weight: var(--font-weight-m);
    }

    .content {
      color: var(--text-color);
      font-size: var(--font-size-sm);
      font-family: var(--font-family);
      font-weight: var(--font-weight-s);
    }
  }
}
</style>

<template>
    <div>
        <!-- <div class="back" @click="goBack(-1)">
            <img :src="alldata.login.btn.back.icon" alt="icon">
            <span>返回</span>
        </div> -->
        <div class="news_detail">
            <div class="title">{{ news.title }}</div>
            <div class="info">
                <div class="time">{{ news.updatetime_txt || news.createtime_txt }}</div>
                <div class="source">
                    <span>来源：</span>
                    <span>{{ news.source }}</span>
                </div>
            </div>
            <div class="detail" v-html="news.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'News',
    data() {
        return {
            news: ''
        }
    },
    mounted() {
        this.getPreviousData();
    },
    methods: {
        async getPreviousData() {
            const { data: res } = await this.$http.post('commons/Journalism/detail', {
                language: this.alldata.nav.current.id,
                category: this.$route.query.category,
                id: this.$route.query.id
            });
            if (res.code !== 1) return this.$message.error(res.msg);
            this.news = res.data;
            //浏览量记录
            this.$http.post('commons/Journalism/browse', {
                language: this.alldata.nav.current.id,
                category: this.$route.query.category,
                id: this.$route.query.id
            }).then(res => {
                console.log(res.data);
            })
        }
    },
}
</script>

<style lang="scss" scoped>
/* .back {
    cursor: pointer;
    margin: 0 18.75vw;
    margin-bottom: 1vw;
    display: flex;
    align-items: center;

    &>img {
        width: 0.3125vw;
        margin-right: 0.3vw;
    }

    &>span {
        color: var(--color-disabled);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
    }
} */

.news_detail {
    // background-color: pink;
    border-top: 1px solid #E6E6E6;
    padding: 2.4vw 24.0625vw;
    
    .title {
        color: var(--text-color);
        font-size: 1.7vw;
        font-family: var(--font-family-zh);
        // font-weight: var(--font-weight-s);
        font-weight: bold;
    }

    .info {
        margin: 2vw 0 1.5625vw;
        display: flex;
        align-items: center;
        color: var(--color-disabled);
        font-size: var(--font-size-sm);
        font-family: var(--font-family-zh);
        font-weight: var(--font-weight-sm);

        .time {
            margin-right: 1vw;
        }
    }

    .detail {

    }

    ::v-deep .show_text {
        margin: 0 !important;
        width: 100% !important;
    }
}
</style>
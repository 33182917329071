<template>
    <div class="banner">
        <img :src="bannerinfo.image" alt="homeBanner">
        <button :class="bannerinfo.btnstyle" v-if="bannerinfo.btn.image == ''" v-text="bannerinfo.btn.text" @click="waiting"></button>
        <div :class="bannerinfo.btnstyle" @click="waiting" v-else>
            <span v-text="bannerinfo.btn.text"></span>
            <img :src="bannerinfo.btn.image" alt="pointer">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    props: ['bannerinfo'],
    methods: {
        waiting(){
            this.$emit('consult');
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
    font-size: 0;

    &>img {
        width: 100%;
    }

    .homebtn {
        opacity: 0;
        width: 14.6%;
        height: 10.2%;
        position: absolute;
        left: 18.75%;
        bottom: 22%;
        background-color: var(--bg-color-base);
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);

        &:hover {
            opacity: 1;
            color: var(--color-success);
        }
    }

    .encasebtn {
        opacity: 0;
        width: 10.5%;
        height: 7%;
        border-radius: 30px;
        background-color: var(--bg-color-base);
        position: absolute;
        left: 18.75%;
        bottom: 34%;
        // bottom: 31%;
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
        &:hover {
            opacity: 1;
            color: var(--color-success);
        }
    }

    .zhcasebtn {
        opacity: 0;
        width: 10.5%;
        height: 7%;
        border-radius: 30px;
        background-color: var(--bg-color-base);
        position: absolute;
        left: 18.75%;
        // bottom: 34%;
        bottom: 31%;
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
        &:hover {
            opacity: 1;
            color: var(--color-success);
        }
    }

    .independencebtn{
        cursor: pointer;
        opacity: 1;
        height: 6.5%;
        background-color: var(--bg-color-btn);
        // background-image: linear-gradient(to right,var(--bg-color-base),var(--bg-color-btn));
        // background-color:rgba(255,0,0,0.5);
        padding: 0 2.5vw;
        border-radius: 1.5625vw;
        position: absolute;
        left: 41.9%;
        top: 26.74%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* &:hover{
            opacity: 1;
            
            &>span{
                color: var(--color-pass);
            }
        } */

        &>span{
            margin-right: 1.5vw;
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }

        &>img{
            width: 3vw;
        }
    }
}
</style>
<template>
    <div style="background-color: var(--bg-color-lighter);padding: 5vw 0;">
        <div class="formcard">
            <div class="otitle" v-text="alldata.consult.formcard.otitle"></div>
            <div class="stitle" v-text="alldata.consult.formcard.stitle"></div>
            <div class="form">
                <div class="keyin">
                    <div class="inputs" v-for="(item, index) in alldata.consult.formcard.placeholder"
                        :key="index">
                        <input class="textstyle" type="text" :placeholder="item.text" v-model="form[item.value]" v-if="!item.isarea" />
                        <textarea class="textstyle" :placeholder="item.text" v-model="form[item.value]" resize="false" v-else></textarea>
                    </div>
                </div>
                <div class="submitbtn">
                    <button v-text="alldata.consult.formcard.btn" style="background-color: #F8A506" @click="submitdata"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Consult',
    data(){
        return {
            form: {
                name: '',
                phone: '',
                email: '',
                content: ''
            }
        }
    },
    methods: {
        async submitdata(){
            if(!this.form.name.trim()) return this.$message(this.alldata.tips.consult.name);
            if(!this.form.phone.trim()) return this.$message(this.alldata.tips.consult.phone);
            if(!this.form.email.trim()) return this.$message(this.alldata.tips.consult.email);
            if(!this.form.content.trim()) return this.$message(this.alldata.tips.consult.message);
            this.form.language = this.alldata.nav.current.id;
            const {data: res} = await this.$http.post('commons/message/add',this.form);
            console.log(res);
            if(res.code !== 1) return this.$message.error(res.msg);
            this.$message.success(res.msg);
        }
    }
}
</script>

<style lang="scss" scoped>
input,textarea{
    border: none;
}
.formcard {
    margin: 0vw 18.4375vw;
    padding: 2vw;
    background-color: #fff;
    box-shadow: 0px 30px 66px 0px rgba(0, 0, 0, 0.16);
    border-radius: 1.5625vw;

    .otitle {
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-lg);
        font-family: var(--font-family);
        font-weight: var(--font-weight-m);
    }

    .stitle {
        margin: 1vw 0 1.2vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
    }

    .form {

        .keyin {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;


            .inputs {
                width: 100%;
                height: 5.2vw;
                padding: 0.8vw 1vw;
                background-color: var(--bg-color-input);
                border-radius: 1.5625vw;
                margin-bottom: 1vw;

                &:not(:last-child) {
                    width: 43%;
                    height: auto;
                    display: flex;
                    align-items: center;
                }

                &>input {
                    width: 100%;
                    height: 100%;
                    background-color: var(--bg-color-input);
                }

                &>textarea {
                    width: 100%;
                    height: 100%;
                    background-color: var(--bg-color-input);
                }

                .textstyle {
                    color: var(--text-color);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }

                ::-webkit-input-placeholder {
                    /* WebKit, Blink, Edge */
                    color: var(--text-color-placeholder);
                }

                :-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: var(--text-color-placeholder);
                }

                ::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: var(--text-color-placeholder);
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: var(--text-color-placeholder);
                }

            }
        }

        .submitbtn {

            &>button {
                background-color: var(--bg-color-btn);
                border-radius: 1.5625vw;
                padding: 1vw 3vw;
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }
}
</style>
<template>
    <div>
        <Banner :bannerinfo="alldata.product.banner"></Banner>
        <div class="mix_container">
            <div class="matrix">
                <div class="heading">{{ alldata.product.matrix.otitle }}</div>
                <div class="stitle">{{ alldata.product.matrix.stitle }}</div>
                <div class="result">
                    <div class="item" v-for="(item, index) in alldata.product.matrix.result" :key="index">
                        <div class="figure">
                            <img :src="item.image" />
                        </div>
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </div>
            </div>
            <div class="require">
                <div class="heading">{{ alldata.product.require.otitle }}</div>
                <div class="stitle">{{ alldata.product.require.stitle }}</div>
                <div class="figure">
                    <img :src="alldata.product.require.image" />
                </div>
            </div>
            <div class="all">
                <div class="heading">{{ alldata.product.all.otitle }}</div>
                <div class="stitle">{{ alldata.product.all.stitle }}</div>
                <div class="result">
                    <div class="item" v-for="(item, index) in alldata.product.all.result">
                        <div class="figure">
                            <img :src="item.image" />
                        </div>
                        <div class="title">{{ item.title}}</div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue';
export default {
    name: 'Product',
    components: {
        Banner
    },
}
</script>

<style lang="scss" scoped>
.mix_container {
    padding: 1.5vw 0 5.2vw;
    /* background: url('@/assets/img/product/zhuangshitu-1.png') no-repeat scroll right 6%/9.79vw 53.8vw,
                url('@/assets/img/product/zhuangshitu-2.png') no-repeat scroll left 30%/9.79vw 53.8vw;  */
    background-image: url('@/assets/img/product/zhuangshitu-1.png'),url('@/assets/img/product/zhuangshitu-2.png');
    background-repeat: no-repeat,no-repeat;
    background-position: right 6%,left 75%;
    background-size: 9.79vw 53.8vw,9.79vw 53.8vw;

    .matrix {
        padding-bottom: 7.13vw;

        .heading {
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-h);
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .stitle {
            margin: 1vw 0 3.6vw;
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-mid);
            font-weight: var(--font-weight-sm);
            font-family: var(--font-family-zh);
        }

        .result {
            display: flex;
            justify-content: center;

            .item {
                width: 14.0625vw;
                background-color: var(--bg-color-base);
                box-shadow: 0px 12px 42px 0px rgba(0, 0, 0, 0.08);
                border-radius: 2vw;
                padding: 3.38vw 1.97vw;
                box-sizing: border-box;
                text-align: center;

                &:not(:last-child) {
                    margin-right: 2vw;
                }

                .figure {

                    &>img {
                        width: 5.2vw;
                    }
                }

                .title {
                    margin: 1.5625vw 0;
                    color: var(--text-color-inverse);
                    font-size: var(--font-size-lls);
                    font-weight: var(--font-weight-sm);
                    font-family: var(--font-family-zh);
                }

                .text {
                    color: var(--color-disabled);
                    font-size: var(--font-size-ens);
                    font-weight: var(--font-weight-sm);
                    font-family: var(--font-family-zh);
                }
            }
        }
    }

    .require {
        padding-bottom:2.29vw;
        text-align: center;

        .heading {
            color: var(--text-color);
            font-size: var(--font-size-h);
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .stitle {
            margin: 1vw 0 3.6vw;
            color: var(--text-color);
            font-size: var(--font-size-mid);
            font-weight: var(--font-weight-sm);
            font-family: var(--font-family-zh);
        }

        .figure {

            &>img {
                width: 63.54vw;
            }
        }
    }


    .all {
        text-align: center;

        .heading {
            color: var(--text-color);
            font-size: 2.8125vw;
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .stitle {
            margin: 1.97vw 0 3.125vw;
            color: var(--text-color);
            font-size: var(--font-size-sm);
            font-weight: var(--font-weight-s);
            font-family: var(--font-family-zh);
        }

        .result {
            width: 62.5vw;
            height: 36vw;
            margin: 0 auto;
            // background-color: pink;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-between;
            align-items: flex-start;

            .item {
                width: 18.22vw;
                border: 0.1vw solid #F0F0F0;
                border-radius: 1vw;
                box-sizing: border-box;
                padding: 2.6vw 0;
                /* &:not(:last-child){
                    margin-right: 3.9vw;
                } */

                .figure {

                    &>img {
                        width: 8.85vw;
                    }
                }

                .title {
                    margin: 1.5625vw 0;
                    color: var(--text-color);
                    font-size: 1.35vw;
                    font-weight: var(--font-weight-sm);
                    font-family: var(--font-family-zh);
                }

                .line{
                    width: 2.91vw;
                    height: 0.1vw;
                    background-color: var(--text-color);
                    margin: 0 auto;
                }
            }
        }
    }
}
</style>
<template>
    <div class="box">
        <div class="official" v-text="addverse.otitle"></div>
        <div class="subheading" v-text="addverse.stitle"></div>
        <div :class="addverse.resultstyle">
            <div class="item" v-for="(item, index) in addverse.result" :key="index">
                <div class="figure">
                    <img :src="item.image" alt="figure">
                </div>
                <div class="explain">
                    <div class="title" v-text="item.title"></div>
                    <div class="content" v-text="item.text"></div>
                </div>
            </div>
        </div>
        <div class="btn" v-if="addverse.btn.text != ''">
            <button @click="learning" style="background-color: #F8A506">
                <span>{{ addverse.btn.text }}</span>
                <img :src="addverse.btn.image" alt="pointer">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Advertisement',
    props: ['addverse'],
    methods: {
        learning(){
            this.$emit('learn');
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    // background-color: pink;
    margin: 6.25vw 18.4375vw 0;

    .official {
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-hug);
        font-family: var(--font-family);
        font-weight: var(--font-weight-m);
    }

    .subheading {
        margin: 1vw 0 3.125vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
    }

    .stepstyle {
        display: flex;
        justify-content: space-between;

        .item {
            flex: 1;
            background-color: #fff;
            border-radius: 1.5625vw;
            box-shadow: 0px 32px 66px 0px rgba(0, 0, 0, 0.1);
            padding: 3.125vw 2vw;

            &:not(:last-child) {
                margin-right: 1vw;
            }

            .figure {
                // background-color: yellow;
                display: flex;
                justify-content: center;

                &>img {
                    width: 7.8125vw;
                }
            }

            .explain {
                // background-color: skyblue;

                .title {
                    margin: 1vw 0;
                    text-align: center;
                    color: var(--text-color);
                    font-size: var(--font-size-lg);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-m);
                }

                .content {
                    text-align: center;
                    color: var(--text-color-add);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }
        }
    }

    .prostyle{
        display: flex;
        justify-content: space-between;

        .item {
            flex: 1;
            // background-color: #fff;
            // border-radius: 1.5625vw;
            // box-shadow: 0px 32px 66px 0px rgba(0, 0, 0, 0.1);
            // padding: 3.125vw 2vw;

            &:not(:last-child) {
                margin-right: 1vw;
            }

            .figure {
                // background-color: yellow;
                display: flex;
                justify-content: center;

                &>img {
                    width: 17vw;
                }
            }

            .explain {
                // background-color: skyblue;

                .title {
                    margin: 1vw 0;
                    text-align: center;
                    color: var(--text-color-inverse);
                    font-size: var(--font-size-xl);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-m);
                }

                .content {
                    text-align: center;
                    color: var(--text-color-add);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }
        }
    }

    .teamstyle{
        // background-color: pink;
        display: flex;
        justify-content: space-between;

        .item {
            flex: 1;
            // background-color: yellow;

            &:not(:last-child) {
                margin-right: 11.97vw;
            }

            .figure {
                display: flex;
                justify-content: center;

                &>img {
                    width: 12vw;
                }
            }

            .explain {

                .title {
                    margin: 1vw 0;
                    text-align: center;
                    color: var(--text-color-inverse);
                    font-size: var(--font-size-lg);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-m);
                }

                .content {
                    text-align: center;
                    color: var(--text-color);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }
        }
    }

    .btn {
        margin-top: 4vw;
        display: flex;
        justify-content: center;

        &>button {
            padding: 1vw 2vw;
            border-radius: 30px;
            background-color: var(--bg-color-btn);

            &>span {
                margin-right: 1.25vw;
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            &>img {
                width: 3vw;
            }
        }
    }
}
</style>
<template>
    <div>
        <div class="banner">
            <div class="box">
                <div class="official" v-text="alldata.talent.banner.otitle"></div>
                <div class="subheading" v-text="alldata.talent.banner.stitle"></div>
            </div>
        </div>
        <div class="profile_wrapper">
            <div class="title" :class="{zh: this.alldata.nav.current.id===2}" v-text="alldata.talent.profile.title"></div>
            <div class="verbar"></div>
            <div class="content" v-text="alldata.talent.profile.content"></div>
            <div class="points">
                <div class="item" v-for="(item, index) in alldata.talent.profile.points" :key="index">
                    <div class="summary">
                        <div class="block"></div>
                        <div class="text" v-text="item"></div>
                    </div>
                    <div class="crossing"></div>
                </div>
            </div>
            <div class="introduce">
                <div class="item" v-for="(item, index) in alldata.talent.profile.imgText" :key="index"
                    :style="{ height: item.height }">
                    <div class="seat_figure"></div>
                    <div class="describe">
                        <div class="order">
                            <img :src="item.order" :alt="index" />
                        </div>
                        <div class="topic" v-text="item.otitle"></div>
                        <div class="sub" v-text="item.stitle"></div>
                        <div class="text" v-html="item.content"></div>
                        <div class="crossing"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="other">
            <div class="item" v-for="(item, index) in alldata.talent.cards" :key="index">
                <div class="title" v-text="item.title"></div>
                <div class="content" v-text="item.content"></div>
                <div class="btn">
                    <button v-text="item.btn" @click="gotopage(item.path)"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Talent'
}
</script>

<style lang="scss" scoped>
.banner {
    background: url('@/assets/img/talent/park-zone-bg.png') no-repeat scroll center top/100% auto;
    padding: 19vw 0;

    .box {
        text-align: center;

        .official {
            margin-bottom: 2vw;
            color: var(--text-color-base);
            font-size: var(--font-size-x);
            font-weight: var(--font-weight-s);
            font-family: var(--font-family);
        }

        .subheading {
            color: var(--text-color-base);
            font-size: var(--font-size-xl);
            font-weight: var(--font-weight-s);
            font-family: var(--font-family);
        }
    }
}

.profile_wrapper {
    margin-top: 7.135vw;

    .title {
        background: url('@/assets/img/talent/title-bg.png') no-repeat scroll 72% 30%/28% auto;
        width: 30vw;
        margin: 0 auto;
        text-align: center;
        color: var(--text-color);
        font-size: 2.9vw;
        font-weight: var(--font-weight-s);
        font-family: var(--font-family);
    }

    .zh{
        background: url('@/assets/img/talent/title-bg.png') no-repeat scroll 62% 90%/18% 25%;
    }

    .verbar {
        margin: 1.5625vw auto;
        width: 1px;
        height: 2vw;
        background-color: var(--color-disabled);
    }

    .content {
        width: 53.6vw;
        margin: 0 auto;
        line-height: var(--font-size-hug);
        text-align: center;
        color: var(--color-disabled);
        font-size: var(--font-size-ens);
        font-weight: var(--font-weight-s);
        font-family: var(--font-family);
    }

    .points {
        width: 53.6vw;
        margin: 4.5vw auto 7vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
            width: 49%;
            margin-bottom: 1.5625vw;

            .summary {
                margin-bottom: 1vw;
                display: flex;
                align-items: center;

                .block {
                    width: 0.3125vw;
                    height: 0.7vw;
                    background-color: var(--bg-color-origin);
                    margin-right: 0.5vw;
                }

                .text {
                    color: var(--text-color);
                    font-size: var(--font-size-ens);
                    font-weight: var(--font-weight-s);
                    font-family: var(--font-family);
                }
            }

            .crossing {
                width: 100%;
                height: 1px;
                background-color: var(--text-color);
            }
        }
    }

    .introduce {
        // background-color: pink;

        .item {
            display: flex;
            margin-bottom: 8.54vw;
            // justify-content: center;

            .order {

                &>img {
                    width: 3.645vw;
                }
            }

            .topic {
                margin: 1.5625vw 0 1vw;
                line-height: 3.4375vw;
                color: var(--text-color);
                font-size: 3.4375vw;
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .sub {
                color: var(--color-success);
                font-size: var(--font-size-xl);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .text {
                margin: 2.6vw 0 3.64vw;
                line-height: var(--font-size-l);
                color: var(--text-color);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .crossing {
                width: 3.28125vw;
                height: 1px;
                background-color: var(--text-color);
            }

            &:first-of-type {
                height: 36.45vw;
                padding-left: 17vw;
                background: url('@/assets/img/talent/image-1.png') no-repeat scroll 10.42vw top/auto 100%;
                display: flex;
                align-items: center;

                .seat_figure {
                    width: 47.6vw;
                    height: 100%;
                    // background-color: rgba(0, 0, 0, 0.5);
                    margin-right: 1.5625vw;
                }

                .describe {
                    width: 24.4375vw;
                    box-sizing: border-box;
                }
            }

            &:nth-of-type(2) {
                height: 31.25vw;
                padding: 0 10.41vw;
                background: url('@/assets/img/talent/image-2.png') no-repeat scroll 23vw top/auto 100%;
                display: flex;
                align-items: center;

                .describe {
                    width: 25.48vw;
                    box-sizing: border-box;
                }
            }
            &:nth-of-type(3){
                height: 41.66vw;
                padding: 0 10.41vw;
                background: url('@/assets/img/talent/image-3.png') no-repeat scroll 16.42vw top/auto 100%;
                display: flex;
                align-items: center;

                .seat_figure {
                    width: 42.3vw;
                    height: 100%;
                    // background-color: rgba(0, 0, 0, 0.5);
                    margin-right: 1.5625vw;
                }

                .describe {
                    width: 23.125vw;
                    box-sizing: border-box;
                }
            }
            &:last-of-type{
                height: 26.04vw;
                padding: 0 10.41vw;
                background: url('@/assets/img/talent/image-4.png') no-repeat scroll 40.42vw top/auto 100%;
                display: flex;
                align-items: center;

                .seat_figure{
                    width: 6.3vw;
                    height: 100%;
                    // background-color: rgba(0, 0, 0, 0.5);
                }

                .describe {
                    width: 17.76vw;
                    box-sizing: border-box;
                }
            }
        }
    }
}

.other {
    margin: 0 14.45vw;
    display: flex;
    display: flex;
    justify-content: space-between;

    .item {
        width: 28.25vw;
        // height: 17.65625vw;
        height: 13.5vw;
        padding: 2.8vw 4.5vw;

        &:first-of-type {
            background: url('@/assets/img/talent/image-5.png') no-repeat scroll center top/100% auto;
        }

        &:last-of-type {
            background: url('@/assets/img/talent/image-6.png') no-repeat scroll center top/100% auto;
        }

        .title {
            margin-bottom: 2vw;
            color: var(--text-color-base);
            font-size: var(--font-size-h);
            font-weight: var(--font-weight-s);
            font-family: var(--font-family);
        }

        .content {
            margin-bottom: 2vw;
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-weight: var(--font-weight-s);
            font-family: var(--font-family);
        }

        .btn {

            &>button {
                width: 9.375vw;
                height: 2.5vw;
                border: 2px solid var(--text-color-base);
                border-radius: 1.25vw;
                background-color: transparent;
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-weight: var(--font-weight-s);
                font-family: var(--font-family);
            }
        }
    }
}
</style>
<template>
    <div class="box">
        <div class="item" v-for="(item, index) in info" :key="index">
            <div class="aitem" v-for="(aitem, aindex) in item" :key="aindex">
                <div class="figure">
                    <img :src="aitem.image" alt="figure">
                </div>
                <div class="explain" v-text="aitem.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Waterfall',
    props: ['info']
}
</script>

<style lang="scss" scoped>
.box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
        width: 14.5vw;
        &:nth-child(2n){
            margin-top: 5vw;
        }

        .aitem {
            height: 14.5vw;
            background-color: var(--bg-color-base);
            border-radius: 1.5625vw;
            box-sizing: border-box;
            box-shadow: 0px 16px 42px 0px rgba(0, 0, 0, 0.1);
            padding: 1.5625vw;

            &:not(:last-child) {
                margin-bottom: 1.5625vw;
            }

            .figure {
                margin-bottom: 1vw;

                &>img {
                    width: 6.25vw;
                }
            }

            .explain {
                color: var(--text-color);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }
}
</style>
<template>
    <div style="position: relative;">
        <div class="box">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
                        <img :src="item.image" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
    name: 'Independence',
    data() {
        return {
            banner: [],
            myswiper: ''
        }
    },
    props: ['cateId'],
    created() {
        this.getCar(this.cateId);
    },
    updated() {
        this.myswiper = new Swiper('.swiper-container', {
            autoplay: true,
            loop: true, // 循环模式选项
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
            }
        });
    },
    methods: {
        getCar(id) {
            this.banner = [];
            this.$http.post('commons/caseshow/index', {
                language: this.alldata.nav.current.id,
                category: id
            }).then(res => {
                const { data } = res;
                this.banner = data.data.data;
                console.log(id, this.banner);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .swiper-button-next:after,
.swiper-button-prev:after {
    color: var(--color-disabled);
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-m);
}

.box {
    width: 60%;
    margin: 0 auto;
    background: url('@/assets/img/home/bijibendiannao.png') no-repeat scroll center top/100% auto;
    padding: 2.5% 9.4% 5%;

    .swiper-container {
        margin: 0 auto;
        position: relative;

        .swiper-wrapper {
            width: 100%;

            .swiper-slide {
                width: 100%;

                &>img {
                    width: 100%;
                    vertical-align: top;
                }
            }
        }
    }
}

.swiper-pagination {
    width: 100%;
    bottom: -2vw;

    ::v-deep .swiper-pagination-bullet:not(:last-child) {
        margin-right: 1vw;
    }
}
</style>
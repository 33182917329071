<template>
  <div>
    <Banner :bannerinfo="alldata.payment.banner" @consult="waiting"></Banner>
    <Addvertisement :addverse="alldata.payment.process" @learn="learning"></Addvertisement>
    <div class="trust">
      <div class="otitle" v-text="alldata.payment.trust.otitle"></div>
      <div class="stitle" v-text="alldata.payment.trust.stitle"></div>
      <div class="content">
        <div class="item" v-for="(item, index) in alldata.payment.trust.result" :key="index">
          <div class="figure">
            <img :src="item.image">
          </div>
          <div class="summary">
            <div class="top" v-text="item.title"></div>
            <div class="bottom" v-text="item.text"></div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button style="background-color: #F8A506" @click="gotopage('/consult')">
          <span v-text="alldata.payment.trust.btn.text"></span>
          <img :src="alldata.payment.trust.btn.image" alt="pointer">
        </button>
      </div>
    </div>
    <div class="case">
      <div class="otitle" v-text="alldata.payment.paycase.otitle"></div>
      <div class="stitle" v-text="alldata.payment.paycase.stitle"></div>
      <div class="content">
        <div class="item" v-for="(item, index) in alldata.payment.paycase.result" :key="index">
          <img :src="item.image" alt="">
          <span v-text="item.text"></span>
        </div>
      </div>
    </div>
    <div class="mix">
      <div class="solution">
        <div class="stitle" v-text="alldata.payment.solution.stitle"></div>
        <div class="content">
          <div class="item" v-for="(item, index) in alldata.payment.solution.result" :key="index">
            <div class="block"></div>
            <div class="wrapper">
              <div class="title" v-text="item.title"></div>
              <div class="text" v-text="item.text"></div>
            </div>
          </div>
        </div>
        <div class="btn">
          <button style="background-color: #F8A506" @click="gotopage('/consult')">
            <span v-text="alldata.payment.solution.btn.text"></span>
            <img :src="alldata.payment.solution.btn.image" alt="pointer">
          </button>
        </div>
      </div>
      <div class="option">
        <div class="stitle" v-text="alldata.payment.option.stitle"></div>
        <div class="content">
          <div class="item" v-for="(item, index) in alldata.payment.option.result" :key="index">
            <div class="figure">
              <img :src="item.image" alt="" />
            </div>
            <div class="wrapper">
              <div class="title" v-text="item.title"></div>
              <div class="text" v-text="item.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
import Addvertisement from '@/components/Common/Addvertisement.vue'
export default {
  name: 'Payment',
  components: {
    Banner,
    Addvertisement
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    waiting() {
      console.log("和首页跳转表单是否相同？");
      console.log(this.$store.state.userinfo);
      this.gotopage('/consult')
    },
    learning() {
      console.log("与首页是否相同");
      this.gotopage('/consult')
    }
  }
}
</script>

<style scoped lang="scss">
.trust {
  margin-top: 4vw;
  padding: 2vw 0;
  background: url('@/assets/img/payment/xinlai-beijing.png') no-repeat scroll center top/100% auto;

  .otitle {
    text-align: center;
    color: var(--text-color-base);
    font-size: var(--font-size-h);
    font-weight: var(--font-weight-m);
    font-family: var(--font-family-zh);
  }

  .stitle {
    margin: 0.6vw 0 3vw;
    text-align: center;
    color: var(--text-color-base);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-sm);
    font-family: var(--font-family-zh);
  }

  .content {
    display: flex;
    justify-content: center;

    .item {
      background-color: var(--bg-color-base);
      border-radius: 0.52vw;

      &:not(:last-of-type) {
        margin-right: 1.4vw;
      }

      .figure {
        // background-color: antiquewhite;

        &>img {
          width: 14.5vw;
          vertical-align: middle;
        }
      }

      .summary {
        width: 14.5vw;
        padding: 1vw 1vw 1.8125vw;
        box-sizing: border-box;

        .top {
          margin-bottom: 0.83vw;
          color: var(--text-color);
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-sm);
          font-family: var(--font-family-zh);
        }

        .bottom {
          color: var(--color-disabled);
          font-size: var(--font-size-ens);
          font-weight: var(--font-weight-sm);
          font-family: var(--font-family-zh);
        }
      }
    }
  }

  .btn {
    margin-top: 1.5625vw;
    display: flex;
    justify-content: center;

    &>button {
      padding: 1vw 2vw;
      border-radius: 30px;
      background-color: var(--bg-color-btn);

      &>span {
        margin-right: 1.25vw;
        color: var(--text-color-base);
        font-size: var(--font-size-sm);
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);
      }

      &>img {
        width: 3vw;
      }
    }
  }
}

.case {
  margin: 5vw 19.53125vw 0;

  .otitle {
    text-align: center;
    color: var(--text-color);
    font-size: var(--font-size-h);
    font-family: var(--font-family-zh);
    font-weight: var(--font-weight-m);
  }

  .stitle {
    margin: 1vw 0 3.8vw;
    text-align: center;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-family: var(--font-family-zh);
    font-weight: var(--font-weight-sm);
  }

  .content {
    height: 45vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>img {
        width: 18.22vw;
        margin-bottom: 1.3vw;
      }

      &>span {
        color: var(--text-color);
        font-size: var(--font-size-mid);
        font-family: var(--font-family-zh);
        font-weight: var(--font-weight-sm);
      }
    }
  }
}

.mix {
  background-image: url('@/assets/img/payment/lantiao.png'), url('@/assets/img/payment/choose-BG.png');
  background-repeat: no-repeat, no-repeat;
  background-position: left top, center bottom;
  background-size: 50.67vw 49.53125vw, 100% auto;

  .solution {
    padding: 9.42vw 21.35vw 22vw 56.25vw;
    // background-color: antiquewhite;

    .stitle {
      margin-bottom: 2.5vw;
      color: var(--text-color);
      font-size: var(--font-size-h);
      font-family: var(--font-family-zh);
      font-weight: var(--font-weight-m);
    }

    .content {

      .item {
        // background-color: aqua;
        display: flex;
        margin-bottom: 2.1875vw;

        .block {
          width: 0.52vw;
          height: 1.14vw;
          background-color: var(--bg-color-origin);
          margin-top: 0.25vw;
          margin-right: 0.73vw;
        }

        .wrapper {
          width: 100%;
          padding-bottom: 1vw;
          border-bottom: 1px solid var(--bg-color-grey);

          .title {
            margin-bottom: 0.83vw;
            color: var(--text-color-inverse);
            font-size: var(--font-size-lg);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }

          .text {
            color: var(--color-disabled);
            font-size: var(--font-size-ens);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }
        }
      }
    }

    .btn {
      // margin-top: 1.5625vw;
      display: flex;
      justify-content: center;

      &>button {
        padding: 1vw 2vw;
        border-radius: 30px;
        background-color: var(--bg-color-btn);

        &>span {
          margin-right: 1.25vw;
          color: var(--text-color-base);
          font-size: var(--font-size-sm);
          font-family: var(--font-family);
          font-weight: var(--font-weight-s);
        }

        &>img {
          width: 3vw;
        }
      }
    }
  }

  .option {

    .stitle {
      margin-bottom: 2.44vw;
      text-align: center;
      color: var(--text-color-base);
      font-size: var(--font-size-h);
      font-family: var(--font-family-zh);
      font-weight: var(--font-weight-m);
    }

    .content {
      height: 20.1vw;
      padding: 0 18.75vw 4.0625vw;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-content: space-between;
      flex-wrap: wrap;

      .item {
        background-color: var(--bg-color-base);
        border-radius: 1.5625vw;
        display: flex;
        align-items: center;
        padding: 1.82vw 3.9vw 2.6vw 1.5625vw;

        .figure {
          margin-right: 0.625vw;

          &>img {
            width: 4.6875vw;
          }
        }

        .wrapper {
          width: 9vw;
          padding-top: 0.5vw;

          .title {
            margin-bottom: 0.9375vw;
            color: var(--color-active);
            font-size: var(--font-size-lg);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }

          .text {
            color: var(--color-disabled);
            font-size: var(--font-size-ens);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }
        }
      }
    }
  }
}
</style>

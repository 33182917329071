<template>
    <div>
        <div class="wrapper">
            <div class="main">
                <!-- 登录区域 -->
                <div class="center">
                    <div class="logo">
                        <img :src="alldata.nav.logo" alt="logo">
                    </div>
                    <div class="card">
                        <div class="back" @click="goBack(alldata.login.btn.back.tohome)">
                            <img :src="alldata.login.btn.back.icon" alt="icon">
                            <span v-text="alldata.login.btn.back.text"></span>
                        </div>
                        <div class="navtab">
                            <div class="item" v-for="(item, index) in alldata.login.type" :key="index">
                                <div class="text" v-text="item.title" @click="toggleform(index)"></div>
                                <div class="line" :class="{ lineActive: index == currentform }"></div>
                            </div>
                        </div>
                        <!-- 登录表单区域 -->
                        <div class="form">
                            <div class="item" v-for="(item, index) in alldata.login.type[currentform].placeholder"
                                :key="index">
                                <input :type="item.type" :maxlength="item.maxlength" :placeholder="item.text"
                                    v-model="inputlist[currentform][index].text" />
                                <button v-show="item.code" @click="checksend" :disabled="time!==60">
                                    {{time===60?alldata.login.btn.code.success:alldata.login.btn.code.progress.left + time + alldata.login.btn.code.progress.right}}
                                    </button>
                            </div>
                        </div>
                        <!-- 提交登录用户信息并验证 -->
                        <div class="submitbtn">
                            <button v-text="alldata.login.btn.ok" @click="submituser"></button>
                        </div>
                        <!-- 跳转到注册或者密码重置页面 -->
                        <div class="other">
                            <div class="item" v-for="(item, index) in alldata.login.btn.to" :key="index"
                                v-text="item.text" @click="gotopage(item.path)"></div>
                        </div>
                        <!-- 微信等第三方登录 -->
                        <div class="third" style="display: none;">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text" v-text="alldata.login.third.title"></div>
                                <div class="line"></div>
                            </div>
                            <div class="content">
                                <div class="item" v-for="(item, index) in alldata.login.third.part" :key="index">
                                    <div class="figure">
                                        <img :src="item.image" alt="figure">
                                    </div>
                                    <div class="text" v-text="item.text"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Form from '@/components/Form/Form.vue'
export default {
    name: 'Login',
    data() {
        return {
            currentform: 0,
            inputlist: [
                [
                    {
                        text: ''
                    },
                    {
                        text: ''
                    }
                ],
                [
                    {
                        text: '',
                    },
                    {
                        text: '',
                    }
                ]
            ],
            event: 'mobilelogin',
            time: 60,
            timer: ''
        };
    },
    components: {
        // Form
    },
    methods: {
        toggleform(index) {
            this.currentform = index;
        },
        async checksend() {
            if (this.inputlist[this.currentform][0].text.trim() == '') {
                this.$message(this.alldata.tips.login.phoneempty);
            } else {
                /* this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                },1000); */
                console.log("发送！！！！",this.inputlist[this.currentform][0].text, this.event);
                const {data:result} = await this.sendCode(this.inputlist[this.currentform][0].text, this.event);
                console.log(result);
                if(result.code !== 1) return this.$message.error(result.msg);
                this.$message.success(this.alldata.tips.code.success);
                this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                }, 1000);
            }
        },
        submituser() {
            if (this.currentform) {
                console.log('短信登录');
                if (this.inputlist[this.currentform][0].text.trim() == '') {
                    this.$message(this.alldata.tips.login.phoneempty);
                } else if (this.inputlist[this.currentform][1].text.trim() == '') {
                    this.$message(this.alldata.tips.login.codeempty);
                } else {
                    let mobile = this.inputlist[this.currentform][0].text.trim();
                    let captcha = this.inputlist[this.currentform][1].text.trim();
                    this.$http.post('user/mobilelogin', {
                        language: this.alldata.nav.current.id,
                        mobile: mobile,
                        captcha: captcha
                    }).then(res => {
                        const { data } = res;
                        if (data.code == 1) {
                            const { token } = data.data.userinfo;
                            //本地缓存token值
                            sessionStorage.setItem('TOKEN', token);
                            //vuex存储用户信息
                            console.log("用户信息", data.data.userinfo);
                            sessionStorage.setItem('userinfo', JSON.stringify(data.data.userinfo));
                            this.$message.success(this.alldata.tips.login.success);
                            setTimeout(() => {
                                this.$router.push('/home');
                            }, 1000)
                        } else {
                            this.$message.error(data.msg);
                        }
                    }).catch(error => { });
                }
            } else {
                console.log("密码登录");
                // 账号密码登录
                if (this.inputlist[this.currentform][0].text.trim() == '') {
                    this.$message(this.alldata.tips.login.accountempty);
                } else if (this.inputlist[this.currentform][1].text.trim() == '') {
                    this.$message(this.alldata.tips.login.passempty);
                } else {
                    let account = this.inputlist[this.currentform][0].text.trim();
                    let password = this.inputlist[this.currentform][1].text.trim();
                    this.$http.post('user/login', {
                        language: this.alldata.nav.current.id,
                        account: account,
                        password: password
                    }).then(res => {
                        console.log(res.data);
                        const { data } = res;
                        if (data.code == 1) {
                            //获取用户token并存储
                            const { token } = data.data.userinfo;
                            //本地缓存token值
                            sessionStorage.setItem('TOKEN', token);
                            //vuex存储用户信息
                            console.log("用户信息", data.data.userinfo);
                            sessionStorage.setItem('userinfo', JSON.stringify(data.data.userinfo));
                            this.$message.success(this.alldata.tips.login.success);
                            setTimeout(() => {
                                this.$router.push('/home');
                            }, 1000)
                        } else {
                            this.$message.error(data.msg);
                        }
                    }).catch(error => { });
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
input {
    border: none;
}

.wrapper {
    width: 100%;
    height: 100vh;
    background: url('@/assets/img/login/right-image.png') no-repeat right top/auto 100%;

    .main {
        width: 42.1875%;
        height: 100vh;
        overflow: hidden;
        // background-color: pink;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .center {
            // background-color: pink;

            .logo {
                flex: 1;
                margin: 0 0 2.5vw 1.25vw;

                &>img {
                    width: 10.95vw;
                }
            }

            .card {
                // border: 1px solid black;
                padding: 0 2vw 0.5vw;

                .navtab {
                    width: 100%;
                    background-color: var(--bg-color-grey);
                    display: flex;

                    .item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .text {
                            cursor: pointer;
                            width: 100%;
                            padding: 1.2vw 0;
                            background-color: var(--bg-color-base);
                            text-align: center;
                            color: var(--text-color);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .line {
                            width: 0%;
                            height: 2px;
                            background-color: var(--bg-color-origin);
                            transition: width .2s;
                        }

                        .lineActive {
                            width: 100%;
                        }
                    }
                }

                .form {
                    width: 18.75vw;

                    .item {
                        margin-top: 1.5625vw;
                        position: relative;
                        border-bottom: 1px solid var(--bg-color-grey);
                        // background-color: pink;

                        &>input {
                            width: 100%;
                            padding: 1vw 0;
                            color: var(--text-color);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        /* number输入框隐藏右边加减数字按钮 */
                        /* input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                        }

                        input[type="number"] {
                            -moz-appearance: textfield;
                        } */

                        &>button {
                            position: absolute;
                            right: 0;
                            top: 1vw;
                            color: var(--color-success);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }

                .submitbtn {
                    margin-top: 1.5625vw;

                    &>button {
                        width: 100%;
                        padding: 1vw 0;
                        background-color: var(--bg-color-origin);
                        color: var(--text-color-base);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-m);
                    }
                }

                .other {
                    margin-top: 0.8vw;
                    display: flex;
                    justify-content: space-between;
                    color: var(--color-disabled);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);

                    .item {
                        cursor: pointer;
                    }
                }

                .third {
                    margin-top: 1.2vw;

                    .title {
                        display: flex;
                        align-items: center;

                        .line {
                            width: 100%;
                            height: 1px;
                            background-color: var(--bg-color-grey);
                        }

                        .text {
                            width: 100%;
                            margin: 0 1vw;
                            text-align: center;
                            color: var(--color-disabled);
                            font-size: var(--font-size-ens);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }

                    .content {
                        margin-top: 1.2vw;

                        .item {
                            cursor: pointer;
                            display: flex;
                            justify-content: center;

                            .figure {

                                &>img {
                                    width: 1.875vw;
                                }
                            }

                            .text {
                                color: var(--text-color);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }
                        }
                    }
                }

                .back {
                    cursor: pointer;
                    margin-bottom: 1vw;
                    display: flex;
                    align-items: center;

                    &>img {
                        width: 0.3125vw;
                        margin-right: 0.3vw;
                    }

                    &>span {
                        color: var(--color-disabled);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }
                }
            }
        }
    }
}

// 小屏幕适配
@media screen and (max-width: 900px) {
    .wrapper {
        background: none;

        .main {
            width: 100%;
            justify-content: center;
        }
    }
}
</style>
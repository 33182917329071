const lang = {
	nav: {
		logo: require('@/assets/img/logo/LOGO.png'),
		menu: [
			{
				text: 'Home',
				path: '/home',
				children: []
			},
			{
				text: 'E-Commerce Services',
				path: '/independence',
				children: [
					{
						text: 'Cases',
						path: '/independence'
					},
					{
						text: 'Aggregation payment',
						path: '/case'
					}
				]
			},
			{
				text: 'About Kuafu',
				path: '/brand',
				children: [
					{
						text: 'Brand',
						path: '/brand'
					},
					{
						text: 'Contact',
						path: '/contact'
					}
				]
			},
			{
				text: 'Careers',
				path: '/talent',
				children: [
					{
						text: 'Job fair',
						path: '/society'
					},
					{
						text: 'Campus recruiting',
						path: '/campus'
					}
				]
			},{ 
				text: 'balance inquiry', 
				path: '', 
				children: [],
				isOutside:true,
			}
		],
		login: {
			image: require('@/assets/img/login/loginRes.png'),
			path: '/login',
			children: [
				{
					text: 'Enter the backstage',
					image: require('@/assets/img/login/backstage.png'),
					path: '/backstage'
				},
				{
					text: 'Log out',
					image: require('@/assets/img/login/exit.png'),
					path: ''
				}
			]
		},
		current: {
			id: 1,
			image: require('@/assets/img/country/en-circle.png'),
			text: "English"
		},
		locales: [
			{
				id: 1,
				path: 'en',
				text: 'US-English',
				image: require('@/assets/img/country/en-circle.png')
			},
			{
				id: 2,
				path: 'zh',
				text: 'CN-简体中文',
				image: require('@/assets/img/country/zh-circle.png')
			}
		]
	},
	footer: {
		signinfo: {
			text: 'Enter a phone number, try faster, more transparent cross-borde payment.',
			signbtn: require('@/assets/img/home/registe-button.png')
		},
		scan: {
			title: 'Scan and Follow us',
			result: [
				{
					// image: require(''),
					title: 'Official WeChat'
				},
				{
					// image: require(''),
					title: 'Official Account'
				},
				{
					// image: require(''),
					title: 'Official Weibo'
				}
			]
		},
		type: {
			title: 'Contact us',
			result: [
				{
					th: 'Telephone:',
					td: 'hahessmoissanite@gmail.com'
				},
				{
					th: 'Email: ',
					td: 'hahessmoissanite@gmail.com'
				},
				{
					th: 'Address:',
					td: 'Floor 3A, Building B, Ziguang Science and Technology Park, High-tech Zone, Minhou County, Fuzhou City, Fujian Province\''
				}
			]
		},
		special: [
			{
				text: 'Brand Introduction',
				path: '/brand'
			},
			{
				text: 'Contact Us',
				path: '/contact'
			},
			{
				text: 'Social Recruitment',
				path: '/society'
			},
			{
				text: 'Campus Recruitment',
				path: '/campus'
			}
		],
		copyright: 'Copyright: Kuafu Information Technology Co., Ltd. Record number:0000000000000000'
	},
	tips: {
		code: {
			success: 'The verification code was sent successfully. Please check'
		},
		register: {
			accountempty: 'Please enter your phone number/email address',
			codeempty: 'Please enter the verification code',
			passempty: 'Please set the password',
			uncheck: "Please tick the User Agreement",
			success: 'Register succeeded'
		},
		login: {
			accountempty: 'Please enter your phone number/email address',
			phoneempty: 'Please enter your phone number',
			codeempty: 'Please enter the verification code',
			passempty: 'Please enter the password',
			success: 'Login succeeded',
			exitsuccess: 'Log out successfully'
		},
		reset: {
			accountempty: 'Please enter your phone number/email address',
			codeempty: 'Please enter the verification code',
			newpassempty: 'Please enter a new password',
			confirmpassempty: 'Please confirm the new password',
			passdiffer: 'The two passwords are different, please re-enter',
			success: 'Password reset succeeded'
		},
		backstage: {
			personal: {
				loading: 'Please wait for the address data to be loaded before saving your personal information',
				success: 'Successfully modified information'
			},
			account: {
				mailempty: 'Please enter your email',
				truemail: 'Incorrect mailbox format',
				phoneempty: 'Please enter a phone number',
				codempty: 'Please enter the verification code',
				success: 'Binding succeeded',
				delete: {
					left: 'Are you sure you want to unbind the',
					right: '?'
				},
				btn: {
					ok: 'Confirm',
					no: 'Cancel'
				},
				unbindsuccess: 'Unbinding succeeded',
				cancel: 'Canceled'
			},
			password: {
				code: 'Please enter the verification code',
				password: 'Please enter a new password',
				repassword: 'Please confirm the new password',
				passdiff: 'The two passwords are different, please re-enter',
				success: 'Password modification succeeded'
			}
		},
		upload: {
			name: 'Please enter your name',
			phone: 'Please enter a phone number',
			email: 'Please enter your email',
			resume: 'Please upload your resume',
			fileuploading: 'The file is being uploaded. Please try again later',
			imgformat: 'The picture format does not meet the requirements',
			success: 'Resume submitted successfully'
		},
		consult: {
			name: 'Please enter your name',
			phone: 'Please enter your phone number',
			email: 'Please enter your email',
			message: 'Please enter your message'
		}
	},
	home: {
		banner: {
			image: require('@/assets/img/home/index-banner.png'),
			btn: {
				text: 'Click to Consult Now',
				image: ''
			},
			btnstyle: 'homebtn'
		},
		describe: [
			{
				image: require('@/assets/img/home/guarantee-icon-1.png'),
				title: 'Comprehensive',
				content: 'One-stop cross-border service solution Based on payment, focus on brand.'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-2.png'),
				title: 'Professional',
				content: '10+ years of experience in cross-border e-commerce Provide comprehensive solutions,and practical leadership.'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-3.png'),
				title: 'Service',
				content: 'Professional services, providing satisfactory solutions and domestic customer service, inline with business habits.'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-4.png'),
				title: 'Zero Risk',
				content: 'Mature model, zero-risk entrepreneurship Safe and secure, easy to be the boss.'
			}
		],
		power: {
			otitle: 'Empowering cross-border brands everywhere',
			stitle: 'Connecting Businesses with Global Consumers',
			result: [
				{
					image: require('@/assets/img/home/service-icon-1.png'),
					title: 'Intelligent construction of  independent online store',
					content: 'Provide a variety of templates to help build a brand store easily and quickly.'
				},
				{
					image: require('@/assets/img/home/service-icon-2.png'),
					title: 'Marketing automation system',
					content: 'Diverse marketing sectors, optimized advertising strategy, precise marketing reach.'
				},
				{
					image: require('@/assets/img/home/service-icon-3.png'),
					title: 'Consumer insight solution',
					content: 'Visualize data charts, gain insight into customer portraits, and increase website traffic.'
				},
				{
					image: require('@/assets/img/home/service-icon-4.png'),
					title: 'Global payment solution',
					content: 'Self-developed aggregated payment system, one account with global business opportunities.'
				}
			]
		},
		service: {
			otitle: 'Kuafu\'s core services',
			stitle: 'Provide efficient management methods and solutions for cross-border sellers',
			describe: {
				summarize: [
					'Strategy',
					'Professional',
					'Technology'
				],
				advert: {
					top: '100%',
					bottom: 'Satisfaction'
				}
			},
			result: {
				title: 'Quickly build a stylized  independent store website',
				content: [
					{
						image: require('@/assets/img/home/build-icon-1.png'),
						content: 'DIY visual operation, easy and fast construction.'
					},
					{
						image: require('@/assets/img/home/build-icon-2.png'),
						content: 'Free templates from all walks of life, one-click application.'
					},
					{
						image: require('@/assets/img/home/build-icon-3.png'),
						content: 'Unlimited custom pages to add more marketing scenarios.'
					},
					{
						image: require('@/assets/img/home/build-icon-4.png'),
						content: 'Humanized operation experience, comfortable and smooth to use.'
					}
				]
			},
			btn: {
				text: 'Quick experience of building a website',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		payment: {
			title: 'Perfect cross-border payment system',
			content: [
				{
					bg: require('@/assets/img/home/payment-function-1.png'),
					text: 'Support global collection, receipt, exchange and payment.'
				},
				{
					bg: require('@/assets/img/home/payment-function-2.png'),
					text: 'Support 11 mainstream currencies.'
				},
				{
					bg: require('@/assets/img/home/payment-function-3.png'),
					text: 'Support account funds to withdraw quickly and safely.'
				}
			],
			btn: {
				text: 'Experience it now',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		onestep: {
			otitle: 'ONE-STOP AGENCY OPERATION SERVICE',
			stitle: 'Provide independent online store agency operation services to empower business owners everywhere.',
			resultstyle: 'stepstyle',
			result: [
				{
					image: require('@/assets/img/home/advantage-icon-1.png'),
					title: 'Talent Advantage',
					text: 'Operators have 3-8 years of practical experience in operation and promotion and work in major project management.'
				},
				{
					image: require('@/assets/img/home/advantage-icon-2.png'),
					title: 'Technical Advantages',
					text: 'Full operation of independent station construction, product selection promotion, precision marketing, cross-border payment solutions'
				},
				{
					image: require('@/assets/img/home/advantage-icon-3.png'),
					title: 'Resource Advantages',
					text: 'Rich platform resources, promotion resources, professional cross-border team resources'
				}
			],
			btn: {
				text: 'Learn about brand hosting',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		}
	},
	case: {
		banner: {
			image: require('@/assets/img/case/banner.png'),
			btn: {
				text: 'Consult Now',
				image: ''
			},
			btnstyle: 'encasebtn'
		},
		process: {
			otitle: 'No cumbersome, simplified use process',
			stitle: '',
			resultstyle: 'prostyle',
			result: [
				{
					image: require('@/assets/img/case/intro-icon-1.png'),
					title: '5 Minutes',
					text: 'Complete the joint debugging test'
				},
				{
					image: require('@/assets/img/case/intro-icon-2.png'),
					title: '4 Steps',
					text: 'Complete the docking and go online'
				},
				{
					image: require('@/assets/img/case/intro-icon-3.png'),
					title: '7 Lines',
					text: 'Code access'
				}
			],
			btn: {
				text: 'Start using',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		trust: {
			otitle: 'Trusted for safety and convenience',
			stitle: '',
			style: {
				vertical: false
			},
			result: [
				{
					image: require('@/assets/img/case/choose-icon-1.png'),
					title: 'Safety and Security',
					text: 'Transaction data encryption, it is a reliable and reliable choice.'
				},
				{
					image: require('@/assets/img/case/choose-icon-2.png'),
					title: 'Quick Access',
					text: 'Simple and safe interface, professional person to assist with quick access.'
				},
				{
					image: require('@/assets/img/case/choose-icon-3.png'),
					title: 'Fund  Management',
					text: 'Solve fund management problems on different platforms with one account.'
				},
				{
					image: require('@/assets/img/case/choose-icon-4.png'),
					title: 'Technical Support',
					text: 'The high-level service team timely maintenance and problem solving.'
				},
				{
					image: require('@/assets/img/case/choose-icon-5.png'),
					title: 'No Additional  Requirements',
					text: 'Suitable for B2C and B2B scenarios No qualification requirements, reasonable price.'
				},
				{
					image: require('@/assets/img/case/choose-icon-6.png'),
					title: 'Intimate Customer  Service',
					text: 'Solve problems for you in time, Professional guidance and high efficiency.'
				}
			],
			btn: {
				text: '',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		custom: {
			otitle: 'Customer Trust',
			stitle: 'Leading the new pattern of cross-border.',
			result: [
				{
					title: '1',
					text: 'One-stop cross-border closed-loop service'
				},
				{
					title: '80%',
					text: 'Serving 80% of the countries and regions.'
				},
				{
					title: '100K+',
					text: 'Accumulative 100,000+ cross-border enterprises.'
				},
				{
					title: '11',
					text: 'Accumulative 100,000+ cross-border enterprises.'
				},
				{
					title: '99%',
					text: ' High user satisfaction'
				}
			]
		},
		service: {
			otitle: 'Cross-border payment service',
			stitle: '',
			style: {
				vertical: true,
			},
			result: [
				{
					image: require('@/assets/img/case/service-icon-1.png'),
					title: 'Cross-border collection',
					text: 'Provide faster and safer payment, collection, exchange, cash withdrawal, and other solutions for cross-border traders.'
				},
				{
					image: require('@/assets/img/case/service-icon-2.png'),
					title: 'Global Payment',
					text: 'Global local payment network, supporting business-efficient, low-cost multi-promotion payment, and withdrawal.'
				},
				{
					image: require('@/assets/img/case/service-icon-3.png'),
					title: 'Receive Money',
					text: 'Complete the recovery of funds from multiple mainstream international e-commerce platforms with high efficiency and low cost.'
				},
				{
					image: require('@/assets/img/case/service-icon-4.png'),
					title: 'Currency Exchange',
					text: 'Support settlement in 11 mainstream foreign currencies such as USD, EUR, and HKD.'
				},
				{
					image: require('@/assets/img/case/service-icon-5.png'),
					title: 'VAT Service',
					text: 'Provide VAT payment channels for cross-border merchants, without the need to withdraw cash and exchange foreign currency.'
				},
				{
					image: require('@/assets/img/case/service-icon-6.png'),
					title: 'Tax Rebate Service',
					text: 'Integrate export tax rebate service to help enterprises increase net profit.'
				},
				{
					image: require('@/assets/img/case/service-icon-7.png'),
					title: 'Global Acquiring',
					text: 'Mainstream credit cards in 150+ countries and regions, localized payment acquiring, high success rate.'
				},
				{
					image: require('@/assets/img/case/service-icon-8.png'),
					title: 'Independent Online Store',
					text: 'Open stores very quickly, help sellers to find global consumers, and help cross-border enterprises to get goods around the world.'
				}
			],
			btn: {
				text: '',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		solution: {
			otitle: 'Custom Payment Solutions',
			result: [
				{
					image: require('@/assets/img/case/solution-1.png'),
					title: 'Applicable to All Scenarios',
					text: 'support transaction methods of different business scenarios'
				},
				{
					image: require('@/assets/img/case/solution-2.png'),
					title: 'Personalized Customization',
					text: 'support personalized customization and privatized deployment.'
				},
				{
					image: require('@/assets/img/case/solution-3.png'),
					title: 'Professional team',
					text: 'one-to-one sorting out requirements and customized solutions'
				},
				{
					image: require('@/assets/img/case/solution-4.png'),
					title: 'Transparency of charges',
					text: 'accurate service and transparent charge information'
				}
			],
			formcard: {
				otitle: 'Consult to Get Started',
				stitle: 'Please enter your contact information and questions below.',
				placeholder: [
					{
						text: 'Your Name',
						isarea: false
					},
					{
						text: 'Your Phone',
						isarea: false
					},
					{
						text: 'Your Email',
						isarea: false
					},
					{
						text: 'Enter Message',
						isarea: true
					}
				],
				btn: 'Submit'
			}
		},
		mix: {
			result: [
				{
					image: require('@/assets/img/case/cooperation-brand-image.png'),
					title: 'The secret to connecting global business opportunities',
					content: [
						{
							text: 'one Point access'
						},
						{
							text: 'Realize unified management of multi-platform funds'
						},
						{
							text: 'Safe and convenient'
						}
					],
					btn: {
						text: 'Our Excellent Partners',
						image: require('@/assets/img/home/more-arrow-icon.png')
					}
				},
				{
					image: require('@/assets/img/case/register-image.png'),
					title: 'Just 3 steps to get it done',
					content: [
						{
							text: 'Register an account with a mobile phone number'
						},
						{
							text: 'Link your card or account'
						},
						{
							text: 'Checkout with Kuafu Integrated Payment'
						}
					],
					btn: {
						text: 'Go to register',
						image: require('@/assets/img/home/more-arrow-icon.png')
					}
				}
			]
		},
		exhibition: {
			top: 'Kuafu Payment',
			bottom: 'Safety you can rely on',
			result: [
				{
					title: 'Information Security Management System',
					text: 'Use safe and reasonable information security technology and management process to protect the confidentiality, integrity, and availability of information assets, and provide secure cross-border transaction protection.'
				},
				{
					title: 'International Professional Compliance Team',
					text: 'Strictly abide by international financial regulations, incorporate compliance requirements into product design, and protect the safety of your funds.'
				},
				{
					title: 'Privacy Protection Design',
					text: 'Kuafu attaches great importance to the security of users\' personal information. We have adopted multi-layered protection measures such as security technology that conforms to international card payment industry standards and supporting management systems to minimize the risks of your information being leaked, damaged, and unauthorized access.'
				},
				{
					title: 'Safe and Reliable  Operation',
					text: 'A reasonable and effective security emergency response system has been established, and appropriate emergency response plans have been formulated according to different types of security incidents.'
				}
			]
		}
	},
	independence: {
		banner: {
			image: require('@/assets/img/independence/site-banner.png'),
			btn: {
				text: 'Start Your Brand',
				image: require('@/assets/img/home/more-arrow-icon.png')
			},
			btnstyle: 'independencebtn'
		},
		service: {
			title: 'Highly Acclaimed Case Sharing',
			subheading: 'More than 1000+ industries have successfully established their platforms for promotion and sales; they have found more consumers and made their products and services renowned abroad.',
			result: [
				{
					image: require('@/assets/img/independence/anli-1.png'),
					title: 'Moissanite E-Commerce Website',
					content: 'Customize attractive web design according to personal style and professional needs. It covers product upload, promotion page, transaction payment, background management, and other services to create a personalized e-commerce website for you.'
				},
				{
					image: require('@/assets/img/independence/anli-2.png'),
					title: 'Mobile App/Mall Website',
					content: 'The mobile and the PC are combined to complement each other, develop synchronously, go online quickly, and customize the development of APP applications (website construction) that integrates product sales and recharge services.'
				},
				{
					image: require('@/assets/img/independence/anli-3.png'),
					title: 'WeChat Mini Program Development',
					content: 'According to user needs, build a mini program platform with the core functions of meal order verification, brand promotion, and product sales that can effectively solve the problem of temple allocation and management.'
				}
			],
			specify: [
				{
					image: require('@/assets/img/independence/advantage-icon-1.png'),
					text: 'Customization'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-2.png'),
					text: 'High Efficiency'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-3.png'),
					text: 'Precision Marketing'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-4.png'),
					text: 'Safe & Reliable'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-5.png'),
					text: 'One-Stop Service'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-6.png'),
					text: 'Kuafu Aggregate Help'
				}
			]
		},
		brand: require('@/assets/img/independence/store-image.png'),
		website: {
			title: 'How to customize',
			image: require('@/assets/img/independence/register-image.png'),
			result: [
				{
					title: 'What we have done',
					content: [
						{
							title: 'Beautiful template:',
							text: 'Senior UI completes interface design'
						},
						{
							title: 'Front-end development:',
							text: 'Create the most engaging pages'
						},
						{
							title: 'Backend maintenance:',
							text: 'Excellent stability and security'
						},
						{
							title: 'Operational tools:',
							text: 'Professional marketing tools for promotion'
						},
						{
							title: 'Hassle-free payment:',
							text: 'Self-developed system escorts receipt and payment'
						}
					]
				},
				{
					title: 'What do you need to do',
					content: [
						{
							title: 'Choose a template:',
							text: 'Lots of beautiful templates to customize your store'
						},
						{
							title: 'Add products:',
							text: 'Upload an attractive image/price/description'
						},
						{
							title: 'Start Selling:',
							text: 'Set up payment gateway/logistics platform and start selling'
						},
					]
				}
			],
			more: {
				otitle: {
					big: '50+',
					small: 'Industries Style Templates'
				},
				stitle: 'We have pre-built over 60 virtual websites and import them with just one click. Mix, match, change. Create a perfect foundation for your new website in minutes!',
				result: [
					{
						nav: 'All',
						content: []
					},
					{
						nav: 'Multi Pages',
						content: []
					},
					{
						nav: 'Single Pages',
						content: []
					},
					{
						nav: 'Business',
						content: []
					},
					{
						nav: 'Shop',
						content: []
					},
					{
						nav: 'Gallery',
						content: []
					}
				],
				btn: 'More Website'
			}
		},
		onestep: {
			title: 'What kind of service does Kuafu provide you',
			image: require('@/assets/img/independence/five.png'),
			result: [
				{
					title: 'Early guidance',
					text: 'Provide product selection and pricing guidance through market research'
				},
				{
					title: 'Start building',
					text: 'Ten years of IT team with rich experience in website construction'
				},
				{
					title: 'Market plan',
					text: 'Provide website operation guidance and agency operation plan'
				},
				{
					title: 'Consulting help',
					text: 'Professional customer service, assist in improving website information'
				},
				{
					title: 'Advertising Marketing',
					text: 'Seamless connection with global promotion methods such as Google and Facebook'
				}
			]
		},
		team: {
			otitle: 'Professional team to escort the brand',
			resultstyle: 'teamstyle',
			result: [
				{
					image: require('@/assets/img/independence/professional-icon-1.png'),
					title: 'Full Service',
					text: 'From website construction, operation, marketing, product selection, to logistics and payment, we provide full-process professional services.'
				},
				{
					image: require('@/assets/img/independence/professional-icon-2.png'),
					title: 'Technical Support',
					text: 'A powerful technical team, continuous product upgrades, and massive feature updates every month provide stable and secure technical support.'
				},
				{
					image: require('@/assets/img/independence/professional-icon-3.png'),
					title: 'Senior Team',
					text: '3-8 years of independent station operation team, deep cultivation of cross-border industry, rich industry experience and data accumulation.'
				}
			],
			btn: {
				text: '',
				image: ''
			}
		},
		about: {
			title: {
				big: 'ABOUT',
				small: 'KUAFU Technology'
			},
			content: {
				title: 'Build Your International Brand',
				text: 'Kuafu adheres to comprehensive self-research and innovation, which takes software development and platform operation as its core business. Relying on a complete payment system and website building services, it provides global brand sellers with integrated cross-border e-commerce operation and management solutions, including store opening, traffic drainage, payment, logistics, and other services. Help good products find more buyers and enhance the brand\'s international influence.If you also want to expand your sales globally, join us to connect with consumers  everywhere.'
			}
		},
		mix: {
			standing: {
				title: 'Standing on the shoulders of giants means you will reduce the cost of early exploration, focus more on brand management, and make success close at hand.',
				result: [
					{
						image: require('@/assets/img/independence/youshi-1.png'),
						title: 'I\'ve found a way to succeed in business',
						text: 'With no high-value investment, you can connect with global consumers through a website and use simple and powerful marketing tools to help grow.'
					},
					{
						image: require('@/assets/img/independence/youshi-2.png'),
						title: 'I\'ve easy access to a global customer base',
						text: 'Through accurate analysis, localized business philosophy to help you find suitable buyers and increase sales.'
					},
					{
						image: require('@/assets/img/independence/youshi-3.png'),
						title: 'Let more people know about my brand.',
						text: 'You can upload your products on this website, promote your services, and win more trust and popularity.'
					}
				]
			}
		}
	},
	payment: {},
	appdev: {},
	applet: {},
	brand: {
		banner: {
			otitle: 'Fast, flexible service for cross-border sellers',
			stitle: 'Facilitating global trade simplification',
			image: require('@/assets/img/brand/brand-profile-line.png')
		},
		tech: {
			otitle: 'KUAFU Technology',
			stitle: 'Adhere to independent innovation and continuous innovation,Boost the pattern and operation of cross-border payments.',
			result: [
				{
					title: '2015',
					text: 'Established Time'
				},
				{
					title: '50+',
					text: 'Elite Team'
				},
				{
					title: '30+',
					text: 'Patents and Cases'
				},
				{
					title: '100K+',
					text: 'Cross-border enterprises that have been served'
				},
				{
					title: '￥30M+',
					text: 'Cooperation Amount'
				},
			],
			content: 'In 2015, we followed the trend and entered a new era of cross-border payment collection, providing services such as cross-border payment, global acquiring, financial services, customized development, and other services for many small and medium-sized enterprises. The cross-border service platform has provided services for 100,000+ cross-border enterprises, with a transaction volume of 30 million yuan.'
		},
		world: {
			title: 'Based on the local,serving the world',
			content: 'Since its establishment, Kuafu has continuously enriched its business model and provided intelligent services that include planning, design, and operation management for enterprise informatization layout, which helps to speed up enterprise workflow and reduce informatization construction costs;Kuafu provides scientific, accurate, and humanized professional IT technical services to help enterprises expand online channels, deploy globally, and achieve a win-win future. '
		},
		break: {
			otitle: 'NEVER STOP AT PRESENT ACHIEVEMENT ALWAYS PURSUES INNOVATION AND BREAKTHROUGH',
			stitle: 'This is the unchanging belief of Kuafu. Kuafu builds a multi-product sales platform, expands product sales channels, builds an independent cross-border e-commerce website, explores global business opportunities, and helps you create an international brand.',
			image: require('@/assets/img/brand/data-increase-bg.png')
		}
	},
	contact: {
		title: 'Contact us',
		result: [
			{
				image: require('@/assets/img/contact/telephone-icon.png'),
				title: 'Service Hotline',
				text: 'hahessmoissanite@gmail.com'
			},
			{
				image: require('@/assets/img/contact/email-icon.png'),
				title: 'Company Email',
				text: 'hahessmoissanite@gmail.com'
			},
			{
				image: require('@/assets/img/contact/address-icon.png'),
				title: 'Company Address',
				text: 'Floor 3A, Building B, Ziguang Science and Technology Park, High-tech Zone, Minhou County, Fuzhou City, Fujian Province'
			}
		],
		image: require('@/assets/img/contact/map-image.png')
	},
	talent: {
		banner: {
			otitle: 'Join Now and Create the Future Together',
			stitle: 'Join Kuafu Family',
			image: require('@/assets/img/talent/park-zone-bg.png')
		},
		profile: {
			title: 'About Kuafu Company Profile',
			content: 'Kuafu Information Technology (Pingtan) Co., Ltd. Founded in 2015, its headquarters is in Fujian Pingtan Comprehensive Experimental Zone, and currently has a branch in Fuzhou High-tech Zone. The company adheres to comprehensive self-research and innovation, has several patent achievements, and adheres to the development concept of "becoming the leading cross-border e-commerce service provider that promotes the development of the industry." The company adheres to technology as the driving force, user demand as the guidance, and focuses on serving cross-border independent site merchants.',
			points: [
				'High-tech enterprises in Pingtan Comprehensive Experimental Zone',
				'Key Enterprises in Pingtan Comprehensive Experimental Zone',
				'High-tech enterprises in Fujian Province',
				'National High-Tech Enterprise'
			],
			imgText: [
				{
					order: require('@/assets/img/talent/order-1.png'),
					otitle: 'Office environment',
					stitle: 'Spacious and Bright',
					content: 'The workplace is in the Tsinghua Ziguang Science and Technology Park, Fuzhou High-tech Zone, with convenient road network transportation and perfect supporting facilities in the park. The office environment is clean and tidy, with open communication channels,  and it also has a pantry and fitness practice points, providing you with a space to show off your skills and a stage to play your value!',
					image: require('@/assets/img/talent/image-1.png'),
					height: '36.45vw'
				},
				{
					order: require('@/assets/img/talent/order-2.png'),
					otitle: 'Work Atmosphere',
					stitle: 'Equality and Pleasure',
					content: 'A warm and harmonious working atmosphere, an equal and efficient communication method, and a young team structure allow you to develop unlimited creativity and potential in a free and unfettered environment.',
					image: require('@/assets/img/talent/image-2.png'),
					height: '31.25vw'
				},
				{
					order: require('@/assets/img/talent/order-3.png'),
					otitle: 'Welfare treatment',
					stitle: 'Comprehensive Improvement',
					content: '> Five insurances and one housing fund<br />> Transportation allowance<br />> Paid annual leave<br />> Statutory holidays<br />> Year-end bonuses<br />> Project bonus<br />> Annual salary adjustment<br />> Holiday benefits<br />> Internal vocational training<p style="margin-top: 0.5%">Benefits will continue to be upgraded, making the company a solid backing for you along the way.</p>',
					image: require('@/assets/img/talent/image-3.png'),
					height: '41.66vw'
				},
				{
					order: require('@/assets/img/talent/order-4.png'),
					otitle: 'WORKING HOURS',
					stitle: '6.5 hours * 5 days',
					content: 'Monday to Friday: 9:00-12:00; 14:00-17:30 Two-day weekend and overtime is not encouraged.',
					image: require('@/assets/img/talent/image-4.png'),
					height: '26.04vw'
				}
			]
		},
		cards: [
			{
				title: 'Social Recruitment ',
				content: 'Facing society, actively attracting outstanding talents from all walks of life at home and abroad.',
				btn: 'View Jobs',
				path: '/society'
			},
			{
				title: 'Campus Recruiting',
				content: 'We offer a customized 3-year training program, open to all current students.',
				btn: 'View Jobs',
				path: '/campus'
			}
		]
	},
	society: {
		banner: {
			image: require('@/assets/img/society/social-recruitment-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		sift: {
			fun: {
				filt: 'Filter',
				clear: 'Clear'
			},
			job: {
				title: 'Occupation Category'
			},
			city: {
				title: 'Work City',
				list: [
					{
						id: 0,
						name: 'Fuzhou'
					},
					{
						id: 1,
						name: 'Pingtan'
					},
					{
						id: 2,
						name: 'Shenyang'
					}
				]
			}
		},
		choose: {
			title: 'Choose a position and start your career!',
			job: 'Job description:',
			require: 'Work requirements:',
			btn: 'Submit Your Resume'
		}
	},
	campus: {
		banner: {
			image: require('@/assets/img/campus/Campus-Recruiting-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		project: {
			otitle: 'Campus Recruitment project',
			stitle: 'Kuafu\'s recruitment program for current students/fresh graduates',
			result: [
				{
					image: require('@/assets/img/campus/progranm-image-1.png'),
					title: 'For 2022&2023 fresh graduates',
					text: 'We sincerely invite goal-oriented, hard-working, enterprising, and persistent graduates to join the team and rapidly become the backbone of the company through a tailored 3-year training plan.'
				},
				{
					image: require('@/assets/img/campus/progranm-image-2.png'),
					title: 'For 2023 & 2024 interns',
					text: 'For juniors and seniors, high-quality summer/long-term internship programs are provided, and those with excellent performance can also directly get a school recruitment offer.'
				}
			]
		},
		features: {
			otitle: 'Program features',
			stitle: 'Comprehensive Talent Growth Plan',
			result: [
				{
					image: require('@/assets/img/campus/feature-icon-1.png'),
					title: 'Mentoring Program',
					text: 'One-on-one tutoring take you to integrate and grow quickly.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-2.png'),
					title: 'Customized training program',
					text: 'For individual abilities and interests Specially designated training plan.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-3.png'),
					title: 'Job rotation training',
					text: 'Short-term rotational experiential learning All-round contact with the company\'s business.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-4.png'),
					title: 'Broad development platform',
					text: 'Core Project Learning Opportunities Give talents a platform.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-5.png'),
					title: 'Improve the promotion mechanism',
					text: 'Open and transparent promotion mechanism The road to growth is smooth and unimpeded.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-6.png'),
					title: 'Salary and welfare',
					text: 'Attractive remuneration package Perfect welfare security.'
				},
				{
					image: require('@/assets/img/campus/feature-icon-7.png'),
					title: 'Welfare policy',
					text: 'Enjoy the high-tech zone talent apartment Talent policies such as landing subsidies.'
				}
			]
		},
		support: {
			otitle: 'Intrapreneurship support',
			stitle: 'Create a high-growth "golden track"',
			content: 'The company will provide entrepreneurial support such as funds, resources, and personnel matching for passionate and creative employee teams. The project will serve as the company\'s internal entrepreneurial incubation project, allowing employees to achieve self-achievement at work and realize their dreams.',
			image: require('@/assets/img/campus/support-image.png')
		}
	},
	backstage: {
		banner: {
			image: require('@/assets/img/backstage/backstage-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		card: [
			{
				label: 'Personal Information',
				content: {
					avatar: {
						btn: 'Upload Avatar',
						tip: 'Please upload an image of no less than 100 * 100 pixels, which supports JPG, JPEG, PNG, GIF and other formats.'
					},
					list: [
						{
							title: 'Name',
							placeholder: 'Please enter...',
							type: 'text',
							value: 'name'
						},
						{
							title: 'Nationality,City',
							loadingholder: 'loading...',
							placeholder: 'Please select...',
							type: 'cascader',
							value: 'region'
						},
						{
							title: 'Gender',
							placeholder: 'Please enter...',
							type: 'select',
							value: 'sex',
							options: [
								{
									value: 1,
									label: 'Male'
								},
								{
									value: 0,
									label: 'Female'
								}
							]
						},
						{
							title: 'Birthday',
							placeholder: 'Please enter...',
							type: 'date',
							value: 'birth'
						},
						{
							title: 'Occupation',
							placeholder: 'Please enter...',
							type: 'text',
							value: 'job'
						},
						{
							title: 'Personal Description',
							placeholder: 'Please enter...',
							type: 'textarea',
							value: 'desc'
						}
					],
					btn: 'Save'
				}
			},
			{
				label: 'Account Settings',
				content: {
					list: [
						{
							title: 'Current Mailbox',
							placeholder: 'Please enter...',
							type: 'text',
							value: 'email',
							flag: ''
						},
						{
							title: 'Current Phone',
							placeholder: 'Please enter...',
							type: 'number',
							value: 'phone',
							flag: ''
						},
						{
							title: '',
							placeholder: 'Enter verification code...',
							type: 'number',
							value: 'code'
						}
					],
					btn: {
						code: {
							progress: {
								left: 'Resend after ',
								right: 's'
							},
							success: 'Get verification code'
						},
						isbind: {
							unbind: 'Unbind',
							replace: 'Replace'
						},
						ok: 'Confirm',
						state: {
							bind: 'Bind',
							unbind: 'Unbind'
						}
					},
					third: {
						otitle: 'Bind third-party account',
						stitle: 'You can log in directly by binding a third-party account, and you can also synchronize the information of the bound mobile phone number and email account.',
						content: {
							image: require('@/assets/img/backstage/grey-wechat.png'),
							id: ''
						}
					}
				}
			},
			{
				label: 'Change Password',
				content: {
					list: [
						{
							title: 'Current Login Account',
							placeholder: 'Account',
							type: 'text',
							value: 'account'
						},
						{
							title: '',
							placeholder: 'Verification code...',
							type: 'number',
							value: 'code'
						},
						{
							title: '',
							placeholder: 'New Password...',
							type: 'password',
							value: 'password'
						},
						{
							title: '',
							placeholder: 'Confirm Password...',
							type: 'password',
							value: 'repassword'
						}
					],
					btn: {
						code: {
							progress: {
								left: 'Resend after ',
								right: 's'
							},
							success: 'Get verification code'
						},
						ok: 'Comfirm'
					}
				}
			},
			{
				label: 'Resume Management',
				content: {
					tip: 'Submitted on:',
					success: 'Your resume had submitted successfully.',
					list: [
						{
							otitle: 'Back end software development engineer, Minhou, Fuzhou',
							stitle: 'Submitted on: October 9, 2022, 16:30:28',
							success: 'Your resume had submitted successfully.',
							image: require('@/assets/img/backstage/success-tag.png')
						}
					]
				},
				space: 'Haven\'t submitted your resume yet'
			}
		]
	},
	upload: {
		info: {
			title: 'Personal information',
			textbox: [
				{
					label: 'Name',
					name: 'name',
					placeholder: 'Name'
				},
				{
					label: 'Phone Number',
					name: 'phone',
					placeholder: 'Phone Number'
				},
				{
					label: 'E-mail',
					name: 'email',
					placeholder: 'E-mail'
				}
			]
		},
		files: {
			title: 'Upload Files',
			textbox: [
				{
					label: 'Upload Resume',
					placeholder: 'Click to upload resume',
					tip: 'Support resume formats such as pdf, doc, ppt, docx, pptx, wps, jpg, jpeg, png, txt.',
					require: true
				},
				{
					label: 'Upload Attachment',
					placeholder: 'Click to upload attachments',
					tip: 'Support documents, pictures, compressed packages, video, audio, design files and other formats, the total size of all attachments does not exceed 50MB.',
					require: false
				}
			]
		},
		btn: 'Confirm Submit'
	},
	consult: {
		formcard: {
			otitle: 'Consult to Get Started',
			stitle: 'Please enter your contact information and questions below.',
			placeholder: [
				{
					text: 'Your Name',
					value: 'name',
					isarea: false
				},
				{
					text: 'Your Phone',
					value: 'phone',
					isarea: false
				},
				{
					text: 'Your Email',
					value: 'email',
					isarea: false
				},
				{
					text: 'Enter Message',
					value: 'content',
					isarea: true
				}
			],
			btn: 'Submit'
		}
	},
	login: {
		type: [
			{
				title: 'Password Login',
				name: 'first',
				placeholder: [
					{
						text: 'Mobile phone number or email',
						maxlength: 20,
						type: 'text'
					},
					{
						text: 'Password',
						maxlength: 20,
						type: 'password'
					}
				]
			},
			{
				title: 'SMS Login',
				name: 'second',
				placeholder: [
					{
						text: 'Mobile phone number',
						maxlength: 20,
						type: 'number'
					},
					{
						text: 'Verification Code',
						code: true,
						maxlength: 10,
						type: 'number'
					}
				]
			}
		],
		btn: {
			back: {
				icon: require('@/assets/img/icon/Back-icon.png'),
				text: 'Back to Home',
				tohome: true
			},
			code: {
				progress: {
					left: 'Resend after ',
					right: 's'
				},
				success: 'Get verification code'
			},
			ok: 'Sign In',
			to: [
				{
					text: 'Registered Account',
					path: '/register'
				},
				{
					text: 'Forgot Password',
					path: '/reset'
				}
			]
		},
		third: {
			title: 'Third Party Login',
			part: [
				{
					image: require('@/assets/img/login/WeChat-logo.png'),
					text: 'WeChat Login'
				}
			]
		},
		agreement: {}
	},
	register: {
		type: [
			{
				title: 'Register',
				run: {
					text: 'Already have an account,',
					to: {
						text: 'log in',
						path: '/login'
					}
				},
				placeholder: [
					{
						text: 'Mobile phone number or email',
						maxlength: 20,
						type: 'text'
					},
					{
						text: 'Verification Code',
						code: true,
						maxlength: 10,
						type: 'number'
					},
					{
						text: 'Set password',
						maxlength: 20,
						type: 'password'
					}
				]
			}
		],
		btn: {
			back: {},
			code: {
				progress: {
					left: 'Resend after ',
					right: 's'
				},
				success: 'Get verification code'
			},
			ok: 'Confirm Registration',
			to: []
		},
		third: {},
		agreement: {
			text: 'Sign up and agree to the',
			to: {
				text: 'User Agreement',
				path: ''
			}
		}
	},
	reset: [
		{
			type: [
				{
					title: 'Retrieve Password',
					run: {},
					placeholder: [
						{
							text: 'Mobile phone number or Email',
							type: 'text'
						},
						{
							text: 'Verification Code',
							code: true,
							type: 'number'
						}
					]
				}
			],
			btn: {
				back: {
					icon: require('@/assets/img/icon/Back-icon.png'),
					text: 'Back',
					tohome: false
				},
				code: {
					progress: {
						left: 'Resend after ',
						right: 's'
					},
					success: 'Get verification code'
				},
				ok: {
					text: 'Next Step',
					type: true
				},
				to: []
			},
			third: {},
			agreement: {}
		},
		{
			type: [
				{
					title: 'Change Password',
					run: {},
					placeholder: [
						{
							text: 'Set Password',
							type: 'password'
						},
						{
							text: 'Confirm Password',
							type: 'password'
						}
					]
				}
			],
			btn: {
				back: {
					icon: require('@/assets/img/icon/Back-icon.png'),
					text: 'Back',
					tohome: false
				},
				code: {
					progress: {
						left: 'Resend after ',
						right: 's'
					},
					success: 'Get verification code'
				},
				ok: {
					text: 'Confirm',
					type: true
				},
				to: []
			},
			third: {},
			agreement: {}
		},
		{
			type: [],
			show: {
				image: require('@/assets/img/success/Reset Succeeded-icon.png'),
				text: 'Reset Succeeded'
			},
			btn: {
				back: {},
				code: {},
				ok: {
					text: 'Back to Login',
					type: false
				},
				to: []
			},
			third: {},
			agreement: {}
		}
	],
	agreement: {
		back: 'Return to Register'
	},
	test: 'English',
	countryImg: require('@/assets/img/country/en.png'),
	tabBar: [
		{ text: 'Home', path: '/home' },
		{ text: 'E-Commerce Services', path: '/payment' },
		{ text: 'About Kuafu', path: '/brand' },
		{ text: 'Careers', path: '/recruit' }
	],
	tabBarImg: {
		home: require('@/assets/img/tabbar/en-home.png'),
		about: require('@/assets/img/tabbar/en-about.png'),
		service: require('@/assets/img/tabbar/en-service.png'),
		career: require('@/assets/img/tabbar/en-career.png')
	}
}

export default lang

<script>
export default {
  data() {
    return {
      activeId: '34',
      isLoad: true,
      isFinished: true,
      total: 15,
      page: 1,
      activeList: [],
      news: [
        { name: '精选要闻', id: '34' },
        { name: '科技先锋', id: '8' },
        { name: '国内资讯', id: '7' },
        { name: '国际观察', id: '19' }
      ]
    }
  },
  created() {
    this.idInit()
    this.getListData(this.$route.query.id || this.activeId)
  },

  watch: {
    $route() {
      this.idInit()
    },
    activeId(newValue) {
      this.activeList = []
      this.page = 1
      this.getListData(newValue)
    }
  },

  mounted() {
    window.addEventListener('scroll', this.loadData, true)
  },

  methods: {
    idInit() {
      const id = this.$route.query.id
      if (this.news.find((item) => item.id === id)) {
        this.activeId = id
      }
    },
    getListData(id) {
      if (!this.isFinished) {
        return
      }
      this.isFinished = false
      const sendTime = new Date().getTime()
      this.$http
        .post('commons/Journalism/index', {
          language: this.alldata.nav.current.id,
          orderby: 'updatetime',
          order: 'desc',
          page: this.page++,
          category: id
        })
        .then((res) => {
          const { code, msg, data } = res.data
          const endTime = new Date().getTime()
          const gapMs = endTime - sendTime
          const waitTime = 480

          if (gapMs < waitTime) {
            setTimeout(() => {
              if (code === 1) {
                this.total = data.total
                this.isLoad = false
                this.isFinished = true
                data.data.length > 0 && this.activeList.push(...data.data)
              } else {
                this.$message.error(msg)
              }
            }, waitTime - gapMs)
          } else {
            if (code === 1) {
              this.total = data.total
              this.isLoad = false
              this.isFinished = true
              data.data.length > 0 && this.activeList.push(...data.data)
            } else {
              this.$message.error(msg)
            }
          }
        })
    },

    loadData() {
      this.isLoad = false
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.body.scrollHeight
      const scrollTop = document.documentElement.scrollTop
      const footerHeight =
        document.querySelector('.footer_container')?.clientHeight || 0
      const distance = 80 + footerHeight

      if (
        scrollTop + clientHeight >= scrollHeight - distance &&
        scrollTop !== 0 &&
        this.isLoad === false &&
        this.activeList.length < this.total
      ) {
        this.isLoad = true
        this.getListData(this.activeId)
      }
    }
  }
}
</script>

<template>
  <div class="article-list">
    <div class="main">
      <el-tabs v-model="activeId">
        <el-tab-pane
          v-for="item in news"
          :key="item.id"
          :label="item.name"
          :name="item.id"
        />
      </el-tabs>

      <!-- list -->
      <div class="wrap">
        <div
          class="list"
          ref="list"
        >
          <div
            class="item"
            v-for="(item, index) in activeList"
            :key="index"
            @click="
              gotopage(
                `/news?category=${item.official_category_id}&id=${item.id}`
              )
            "
          >
            <div class="title">{{ item.title }}</div>
            <div class="sub-title">
              <span class="time">{{ item.updatetime_txt }}</span>
              <span class="source">来源：{{ item.source }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 加载 -->
      <div
        class="load"
        v-if="this.total > this.activeList.length"
        v-show="!isFinished"
      >
        加载中...
      </div>
      <div
        class="load empty"
        v-else
      >
        无更多内容
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.article-list {
  background: #f5f5f5;
  padding: 40px 0 110px;
}

.main {
  width: 52.08vw;
  min-width: 720px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #fff;
  padding: 36px 30px;

  ::v-deep .el-tabs__nav {
    padding-bottom: 8px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #c91116;
  }

  ::v-deep .el-tabs__item {
    font-size: 24px;
    font-family: MiSans;
    font-weight: 400;
    padding: 0 32px;
    &:hover {
      color: #c91116;
    }
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #c91116;
  }
}

.wrap {
  padding-top: 4px;
  .item {
    margin-bottom: 10px;
    cursor: pointer;
    .title {
      font-size: 20px;
      line-height: 36px;
      font-family: MiSans;
      font-weight: 400;
      color: #2e2e2e;
    }

    .sub-title {
      font-size: 14px;
      font-family: MiSans;
      font-weight: 400;
      color: #999999;

      .time {
        margin-right: 16px;
      }
    }
  }
}

.load {
  font-size: 16px;
  font-family: MiSans;
  font-weight: 400;
  color: #2e2e2e;
  line-height: 32px;

  &.empty {
    font-size: 14px;
    color: #999;
  }
}
</style>

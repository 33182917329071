<template>
    <div>
        <div class="wrapper">
            <div class="main">
                <div class="center">
                    <div class="logo">
                        <img :src="alldata.nav.logo" alt="logo">
                    </div>
                    <div class="card">
                        <template v-if="!alldata.reset[currentform].show">
                            <div class="back" @click="goBack(alldata.reset[currentform].btn.back.tohome)">
                                <img :src="alldata.reset[currentform].btn.back.icon" alt="icon">
                                <span v-text="alldata.reset[currentform].btn.back.text"></span>
                            </div>
                            <!-- <div class="navtab">
                            <div class="item" v-for="(item, index) in alldata.login.type" :key="index">
                                <div class="text" v-text="item.title" @click="toggleform(index)"></div>
                                <div class="line" :class="{ lineActive: index == currentform }"></div>
                            </div>
                        </div> -->
                            <div class="title">
                                <div class="item" v-for="(item, index) in alldata.reset[currentform].type" :key="index">
                                    <div class="left" v-text="item.title"></div>
                                    <!-- <div class="right">
                                    <span class="text" v-text="item.run.text"></span>
                                    <span class="fun" v-text="item.run.to.text"
                                        @click="gotopage(item.run.to.path)"></span>
                                </div> -->
                                </div>
                            </div>
                            <div class="form">
                                <div class="item"
                                    v-for="(item, index) in alldata.reset[currentform].type[0].placeholder"
                                    :key="index">
                                    <input :type="item.type" :placeholder="item.text" v-model="inputlist[currentform][index].text" />
                                    <button v-show="item.code" @click="checksend" :disabled="time!==60">
                                        {{time===60?alldata.login.btn.code.success:alldata.login.btn.code.progress.left + time + alldata.login.btn.code.progress.right}}
                                    </button>
                                </div>
                            </div>
                        </template>
                        <div class="success" v-else>
                            <div class="figure">
                                <img :src="alldata.reset[currentform].show.image" alt="figure">
                            </div>
                            <div class="text" v-text="alldata.reset[currentform].show.text"></div>
                        </div>
                        <div class="submitbtn">
                            <button v-text="alldata.reset[currentform].btn.ok.text" @click="addform(alldata.reset[currentform].btn.ok.type)"></button>
                        </div>
                        <!-- <div class="other">
                            <div class="item" v-for="(item, index) in alldata.reset[currentform].btn.to" :key="index"
                                v-text="item.text" @click="gotopage(item.path)"></div>
                        </div> -->
                        <!-- <div class="third">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text" v-text="alldata.login.third.title"></div>
                                <div class="line"></div>
                            </div>
                            <div class="content">
                                <div class="item" v-for="(item, index) in alldata.login.third.part" :key="index">
                                    <div class="figure">
                                        <img :src="item.image" alt="figure">
                                    </div>
                                    <div class="text" v-text="item.text"></div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Form from '@/components/Form/Form.vue'
export default {
    name: 'Reset',
    data() {
        return {
            currentform: 0,
            inputlist: [
                [
                    {
                        text: ''
                    },
                    {
                        text: ''
                    }
                ],
                [
                    {
                        text: ''
                    },
                    {
                        text: ''
                    }
                ]
            ],
            event: 'resetpwd',
            time: 60,
            timer: ''
        }
    },
    components: {
        // Form
    },
    mounted(){
    },
    methods: {
        async checksend(){
            if(this.inputlist[this.currentform][0].text.trim()==''){
                this.$message(this.alldata.tips.reset.accountempty);
            }else{
                /* this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                },1000); */
                const {data:result} = await this.sendCode(this.inputlist[this.currentform][0].text,this.event);
                console.log(result);
                if(result.code !== 1) return this.$message.error(result.msg);
                this.$message.success(this.alldata.tips.code.success);
                this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                }, 1000);
            }
        },
        async addform(state) {
            if(!state) return this.goBack(state);
            if(this.currentform){
                if(this.inputlist[this.currentform][0].text == '') return this.$message(this.alldata.tips.reset.newpassempty);
                if(this.inputlist[this.currentform][1].text == '') return this.$message(this.alldata.tips.reset.confirmpassempty);
                if(this.inputlist[this.currentform][0].text != this.inputlist[this.currentform][1].text) return this.$message(this.alldata.tips.reset.passdiffer);
                let type = /@/.test(this.inputlist[0][0].text.trim())? 'email':'mobile';
                let mobile = /@/.test(this.inputlist[0][0].text.trim())? '':this.inputlist[0][0].text.trim();
                let email = /@/.test(this.inputlist[0][0].text.trim())? this.inputlist[0][0].text.trim():'';
                const {data: res} = await this.$http.post('user/resetpwd',{
                    language: this.alldata.nav.current.id,
                    type: type,
                    mobile: mobile,
                    email: email,
                    newpassword: this.inputlist[1][0].text.trim(),
                    captcha: this.inputlist[0][1].text.trim()
                })
                console.log(res);
                if(res.code != 1) return this.$message.error(res.msg);
                this.$message.success(this.alldata.tips.reset.success);
                this.currentform++;
            }else{
                if(this.inputlist[this.currentform][0].text == '') return this.$message(this.alldata.tips.reset.accountempty);
                if(this.inputlist[this.currentform][1].text == '') return this.$message(this.alldata.tips.reset.codeempty);
                this.currentform++;
            }
        }
    }
}
</script>

<style scoped lang="scss">
input{
    border: none;
}
.wrapper {
    width: 100%;
    height: 100vh;
    background: url('@/assets/img/login/right-image.png') no-repeat right top/auto 100%;

    .main {
        width: 42.1875%;
        height: 100vh;
        overflow: hidden;
        // background-color: yellow;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .center {
            // background-color: pink;

            .logo {
                flex: 1;
                margin: 0 0 3.5vw 1.25vw;

                &>img {
                    width: 10.95vw;
                }
            }

            .card {
                // border: 1px solid black;
                padding: 0 2vw 0.5vw;

                .title {

                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 1.5625vw;
                        padding: 1vw 0;
                        border-bottom: 1px solid var(--bg-color-grey);

                        .left {
                            color: var(--text-color);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .right {
                            color: var(--color-disabled);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);

                            .fun {
                                cursor: pointer;
                                color: var(--color-success);
                            }
                        }
                    }
                }

                .form {
                    width: 18.75vw;

                    .item {
                        margin-top: 1.5625vw;
                        position: relative;
                        border-bottom: 1px solid var(--bg-color-grey);
                        // background-color: pink;

                        &>input {
                            width: 100%;
                            padding: 1vw 0;
                            color: var(--text-color);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        &>button {
                            position: absolute;
                            right: 0;
                            top: 1vw;
                            color: var(--color-success);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }

                .success{

                    .figure{
                        width: 18.75vw;
                        margin-bottom: 2.1875vw;
                        margin-top: 9vw;
                        display: flex;
                        justify-content: center;

                        &>img{
                            width: 7.8125vw
                        }
                    }

                    .text{
                        display: flex;
                        justify-content: center;
                        color: var(--text-color);
                        font-size: var(--font-size-xl);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }
                }

                .submitbtn {
                    margin-top: 1.5625vw;

                    &>button {
                        width: 100%;
                        padding: 1vw 0;
                        background-color: var(--bg-color-origin);
                        color: var(--text-color-base);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-m);
                    }
                }

                .other {
                    margin-top: 0.8vw;
                    display: flex;
                    justify-content: space-between;
                    color: var(--color-disabled);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);

                    .item {
                        cursor: pointer;
                    }
                }

                .third {
                    margin-top: 1.2vw;

                    .title {
                        display: flex;
                        align-items: center;

                        .line {
                            width: 100%;
                            height: 1px;
                            background-color: var(--bg-color-grey);
                        }

                        .text {
                            width: 100%;
                            margin: 0 1vw;
                            text-align: center;
                            color: var(--color-disabled);
                            font-size: var(--font-size-ens);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }

                    .content {
                        margin-top: 1.2vw;

                        .item {
                            cursor: pointer;
                            display: flex;
                            justify-content: center;

                            .figure {

                                &>img {
                                    width: 1.875vw;
                                }
                            }

                            .text {
                                color: var(--text-color);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }
                        }
                    }
                }

                .back {
                    cursor: pointer;
                    margin-bottom: 1vw;
                    display: flex;
                    align-items: center;

                    &>img {
                        width: 0.3125vw;
                        margin-right: 0.3vw;
                    }

                    &>span {
                        color: var(--color-disabled);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 900px){
    .wrapper{
        background: none;

        .main{
            width: 100%;
            justify-content: center;
        }
    }
}
</style>
<template>
    <div>
        <div class="mix">
            <div class="standing">
                <div class="title">"{{ alldata.independence.mix.standing.title }}"</div>
                <div class="result">
                    <div class="item" v-for="(item, index) in alldata.independence.mix.standing.result" :key="index">
                        <div class="figure">
                            <img :src="item.image" />
                        </div>
                        <div class="item_title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </div>
            </div>
            <div class="service">
                <div class="official" v-text="alldata.independence.service.title"></div>
                <div class="subheading">{{ alldata.independence.service.subheading }}</div>
                <div class="result">
                    <div class="item" :class="{ reverse: index % 2 != 0 }"
                        v-for="(item, index) in alldata.independence.service.result" :key="index">
                        <div class="left">
                            <div class="title" v-text="item.title"></div>
                            <div class="content">{{ item.content }}</div>
                            <div class="line"></div>
                        </div>
                        <div class="right">
                            <img :src="item.image" alt="figure">
                        </div>
                    </div>
                </div>
            </div>
            <div class="onestep">
                <div class="official" v-text="alldata.independence.onestep.title"></div>
                <div class="box">
                    <div class="figure">
                        <img :src="alldata.independence.onestep.image" alt="figure">
                    </div>
                    <div class="result">
                        <div class="item" v-for="(item, index) in alldata.independence.onestep.result" :key="index">
                            <div class="icon">
                                <img src="@/assets/img/icon/check-icon.png" alt="icon">
                            </div>
                            <div class="content">
                                <div class="title" v-text="item.title"></div>
                                <div class="text" v-text="item.text"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="website">
            <div class="official" v-text="alldata.independence.website.title"></div>
            <div class="figure">
                <img :src="alldata.independence.website.image" alt="figure">
            </div>
        </div>
    </div>
</template>

<script>
import Addvertisement from '@/components/Common/Addvertisement.vue'
export default {
    name: 'Enindependence',
    data() {
        return {
            navIndex: 0
        }
    },
    components: {
        Addvertisement
    },
}
</script>

<style lang="scss" scoped>
.mix {

    .standing {
        text-align: center;

        .title {
            padding: 5.1vw 28vw 3.6vw;
            color: var(--text-color);
            font-size: 1.145vw;
            font-family: Comic Sans MS;
            font-weight: 400;
        }

        .result {
            display: flex;
            justify-content: center;

            .item {
                width: 18.22vw;
                height: 26vw;
                background-color: var(--bg-color-base);
                box-shadow: 3px 16px 42px 0px rgba(0, 0, 0, 0.08);
                border-radius: 1.5625vw;
                box-sizing: border-box;
                padding: 2.96875vw 2vw 0;

                &:not(:last-child) {
                    margin-right: 3.5vw;
                }

                .figure {

                    &>img {
                        width: 13.54vw;
                    }
                }

                .item_title {
                    margin: 1.5625vw 0;
                    color: var(--text-color);
                    font-size: var(--font-size-lg);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-m);
                }

                .text {
                    color: var(--color-disabled);
                    font-size: var(--font-size-ens);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }
        }
    }

    .service {
        padding-top: 6.77vw;

        .official {
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-hug);
            font-family: var(--font-family);
            font-weight: var(--font-weight-m);
        }

        .subheading {
            padding: 2vw 26.51vw 3.64vw;
            text-align: center;
        }

        .result {
            margin: 0 18.4375vw;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {

                    .title {
                        color: var(--text-color);
                        font-size: var(--font-size-h);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-m);
                    }

                    .content {
                        margin: 2.29vw 0 2.7vw;
                        color: var(--color-disabled);
                        font-size: var(--font-size-ens);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }

                    .line {
                        width: 10.3125vw;
                        height: 1px;
                        background-color: var(--bg-color-origin);
                    }
                }

                .right {

                    &>img {
                        width: 31.25vw;
                    }
                }
            }

            .reverse {
                flex-direction: row-reverse;
            }
        }

        /* .specify {
            margin: 0 18.4375vw;
            display: flex;

            .item {
                flex: 1;
                border: 1px solid #E9E9E9;
                padding: 0.5vw 0 1vw;

                &:not(:last-child) {
                    margin-right: 1.25vw;
                }

                .figure {
                    display: flex;
                    justify-content: center;
                    // background-color: pink;

                    &>img {
                        width: 5.2vw;
                    }
                }

                .text {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    // background-color: skyblue;

                    &>span {
                        color: var(--text-color);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }
                }
            }
        } */
    }

    .onestep {
        padding-top: 6.5vw;

        .official {
            // width: 40%;
            margin: 0 auto 3vw;
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-hug);
            font-family: var(--font-family);
            font-weight: var(--font-weight-m);
        }

        .box {
            display: flex;
            justify-content: center;
            align-items: center;

            .figure {
                margin-right: 4vw;

                &>img {
                    width: 34vw;
                }
            }

            .result {
                width: 25%;

                .item {
                    display: flex;
                    align-items: flex-start;

                    &:not(:last-child) {
                        margin-bottom: 2.5vw;
                    }

                    .icon {
                        margin-right: 0.5vw;
                        padding: 0.2vw 0;

                        &>img {
                            width: 1vw;
                            vertical-align: bottom;
                        }
                    }

                    .content {

                        .title {
                            margin-bottom: 0.9375vw;
                            color: var(--text-color);
                            font-size: var(--font-size-lg);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-m);
                        }

                        .text {
                            color: var(--color-disabled);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }
            }
        }
    }
}

.brand {
    margin-top: 6.5vw;

    &>img {
        width: 100%;
    }
}

.website {
    margin-top: 5vw;
    // background-color: pink;

    .official {
        width: 25%;
        margin: 0 auto 3vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-hug);
        font-family: var(--font-family);
        font-weight: var(--font-weight-m);
    }

    .figure {

        &>img {
            width: 100%;
        }
    }

    .result {
        // margin: 5.2vw 18.4375vw 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .item {
            padding: 3.125vw 2vw;
            background-color: skyblue;
            border-radius: 1vw;

            &:first-child {
                background-color: var(--bg-color-origin);
                padding-right: 4vw;
            }

            &:last-child {
                background-color: var(--color-success);
                margin-left: -3vw;
            }

            .title {
                margin-bottom: 1.5vw;
                // text-align: center;
                color: var(--text-color-base);
                font-size: var(--font-size-hug);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .content {

                .aitem {
                    display: flex;

                    &:not(:last-child) {
                        margin-bottom: 1vw;
                    }

                    .check {
                        margin-right: 0.5vw;

                        &>img {
                            width: 1vw;
                        }
                    }

                    .explain {

                        .toprice {
                            // text-align: left;
                            color: var(--text-color-base);
                            font-size: var(--font-size-lg);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-m);
                        }

                        .text {
                            color: var(--text-color-base);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }
            }
        }
    }

    .more {
        padding: 5.5vw 0 3.5vw;
        margin-top: 6vw;
        background-color: var(--bg-color-black);

        .otitle {
            display: flex;
            justify-content: center;
            align-items: center;

            .big {
                color: var(--text-color-inverse);
                font-size: var(--font-size-xxxl);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .small {
                width: 20%;
                color: var(--text-color-base);
                font-size: var(--font-size-hug);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }
        }

        .subheading {
            width: 50%;
            margin: 1.5vw auto 2vw;
            text-align: center;
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }

        .result {
            display: flex;
            justify-content: center;

            .item {
                margin: 0 0 3vw;
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                padding: 0.5vw;

                &:not(:last-child) {
                    margin-right: 2.5vw;
                }

                .nav {
                    color: var(--text-color-base);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }

            .border {
                border-bottom: 2px solid var(--color-active);
            }
        }

        .btn {
            text-align: center;

            &>button {
                border: 1px solid var(--color-active);
                width: 15.5625vw;
                height: 3.75vw;
                background-color: transparent;
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }
}

.about {
    width: 80.67vw;
    height: 33.64vw;
    margin: 2.5vw auto 0;
    /* margin: 3.5vw 9.6875vw 0;
    padding: 9.5vw 9vw 10.5vw; */
    box-sizing: border-box;
    padding: 0 9vw;
    background: url('@/assets/img/independence/map_BG.png') no-repeat scroll center center/100% auto;
    display: flex;
    // justify-content: center;
    align-items: center;

    .title {
        // flex: 1;
        text-align: right;
        margin-right: 6vw;

        .big {
            color: var(--text-color);
            font-size: var(--font-size-ll);
            font-family: var(--font-family);
            font-weight: var(--font-weight-m);
        }

        .small {
            color: var(--text-color);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }
    }

    .content {
        flex: 1;

        .toprice {
            margin-bottom: 1.7vw;
            color: var(--color-success);
            font-size: var(--font-size-xl);
            font-family: var(--font-family);
            font-weight: var(--font-weight-m);
        }

        .content {
            color: var(--text-color);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }
    }
}
</style>
const lang = {
	nav: {
		logo: require('@/assets/img/logo/LOGO.png'),
		menu: [
			{ 
				text: '首页', 
				path: '/home',
				children: []
			},
			{ 
				text: '产品中心', 
				path: '/product',
				children: [
					{
						text: '聚合支付',
						path: '/payment'
					},
					{
						text: '独立站建设',
						path: '/independence'
					},
					{
						text: 'App开发',
						path: '/appdev'
					},
					{
						text: '小程序',
						path: '/applet'
					},
				]
			},
			{ 
				text: '关于我们', 
				path: '/brand',
				children: [
					{
						text: '品牌介绍',
						path: '/brand'
					},
					{
						text: '联系我们',
						path: '/contact'
					}
				] 
			},
			{ 
				text: '人才招聘', 
				path: '/talent',
				children: [
					{
						text: '社会招聘',
						path: '/society'
					},
					{
						text: '校园招聘',
						path: '/campus'
					}
				] 
			},
			{ 
				text: '卡余额查询', 
				path: '', 
				children: [],
				isOutside:true,
			}
		],
		login: {
			image: require('@/assets/img/login/loginRes.png'),
			path: '/login',
			children: [
				{
					text: '进入后台',
					image: require('@/assets/img/login/backstage.png'),
					path: '/backstage'
				},
				{
					text: '退出登录',
					image: require('@/assets/img/login/exit.png'),
					path: ''
				}
			]
		},
		current: {
			id: 2,
			image: require('@/assets/img/country/zh-circle.png'),
			text: "简体中文"
		},
		locales: [
			{
				id: 1,
				path: 'en',
				text: 'US-English',
				image: require('@/assets/img/country/en-circle.png')
			},
			{
				id: 2,
				path: 'zh',
				text: 'CN-简体中文',
				image: require('@/assets/img/country/zh-circle.png')
			}
		]
	},
	footer: {
		signinfo: {
			text: '输入电话号码，尝试更快、更透明的跨境支付。',
			signbtn: require('@/assets/img/home/registe-button.png')
		},
		scan:{
			title: '扫码关注',
			result: [
				{
					// image: require(''),
					title: '官方微信'
				},
				{
					// image: require(''),
					title: '官方账户'
				},
				{
					// image: require(''),
					title: '官方微博'
				}
			]
		},
		type: {
			title: '联系我们',
			result: [
				{
					th: '电话号码：',
					td: 'hahessmoissanite@gmail.com'
				},
				{
					th: '电子邮箱：',
					td: 'hahessmoissanite@gmail.com'
				},
				{
					th: '公司地址：',
					td: '福州市闽侯县上街镇乌龙江南大道30号福州清华紫光科技园-海峡科技研发区1-2#研发楼4层405研发'
				}
			]
		},
		special: [
			{
				text: '品牌介绍',
				path: '/brand'
			},
			{
				text: '联系我们',
				path: '/contact'
			},
			{
				text: '社会招聘',
				path: '/society'
			},
			{
				text: '校园招聘',
				path: '/campus'
			}
		],
		copyright: '版权所有：夸父信息技术有限公司 备案号：0000000000000000'
	},
	tips: {
		code: {
			success: '验证码发送成功，请注意查收'
		},
		register: {
			accountempty: '请输入电话号码/电子邮箱',
			codeempty: '请输入验证码',
			passempty: '请设置密码',
			uncheck: "请勾选用户协议",
			success: '注册成功'
		},
		login: {
			accountempty: '请输入电话号码/电子邮箱',
			phoneempty: '请输入电话号码',
			codeempty: '请输入验证码',
			passempty: '请输入密码',
			success: '登录成功',
			exitsuccess: '退出登录成功'
		},
		reset: {
			accountempty: '请输入电话号码/电子邮箱',
			codeempty: '请输入验证码',
			newpassempty: '请输入新密码',
			confirmpassempty: '请确认密码',
			passdiffer: '两次输入的密码不同，请重新输入',
			success: '重置密码成功'
		},
		backstage: {
			personal: {
				loading: '请等待地址数据加载完成，再保存个人信息',
				success: '修改信息成功'
			},
			account: {
				mailempty: '请输入邮箱',
				truemail: '邮箱格式不正确',
				phoneempty: '请输入电话号码',
				codempty: '请输入验证码',
				success: '绑定成功',
				delete: {
					left: '确定要解绑',
					right: '吗？'
				},
				btn: {
					ok: '确定',
					no: '取消'
				},
				unbindsuccess: '解绑成功',
				cancel: '取消操作'
			},
			password: {
				code: '请输入验证码',
				password: '请输入新密码',
				repassword: '请确认新密码',
				passdiff: '两次输入的密码不同，请重新输入',
				success: '修改密码成功'
			}
		},
		upload: {
			name: '请输入你的姓名',
			phone: '请输入电话号码',
			email: '请输入你的邮箱',
			resume: '请上传你的简历',
			fileuploading: '文件上传中，请稍后再试',
			imgformat: '图片格式不符合要求',
			success: '简历提交成功'
		},
		consult: {
			name: '请输入您的姓名',
			phone: '请输入您的电话号码',
			email: '请输入您的邮箱',
			message: '请输入您的留言'
		}
	},
	home: {
		about: {
			title: '关于我们',
			content: {
				firsttitle: '夸父科技',
				lasttitle: '专业赋能',
				content: '夸父信息科技有限公司成立于2015年，总部位于福建平潭，2022年于福州高新区设立分公司，为省高新技术企业，我们以创新为起点、以技术为核心、以团队为支撑，致力于为政府、企事业单位提供定制化网络应用解决方案及互联网+增值服务。'
			},
			features: [
				{
					image: require('@/assets/img/home/advantage-1.png'),
					title: '技术',
					text: '稳定的核心研发人员<br/>各项技术全面覆盖'
				},
				{
					image: require('@/assets/img/home/advantage-2.png'),
					title: '团队',
					text: '超过十年运营经验团队<br/>超全方案，实操带领'
				},
				{
					image: require('@/assets/img/home/advantage-3.png'),
					title: '服务',
					text: '专业服务，提供满意方案<br/>售后完善，全程跟进'
				},
				{
					image: require('@/assets/img/home/advantage-4.png'),
					title: '专业',
					text: '成功服务众多互联网项目<br/>拥有成熟的经验积累'
				}
			]
		},
		core: {
			title: '核心业务',
			intro: [
				{
					image: require('@/assets/img/home/yewu-1.png'),
					title: '聚合支付',
					text: '为商家企业创建便捷的收付方式，满足各场景试用需求，全程指导使用，资金管理更便捷。',
					path: '/payment'
				},
				{
					image: require('@/assets/img/home/yewu-2.png'),
					title: '独立站建设',
					text: '依托成熟建站技术，帮助企业搭建信息门户平台，跨境独立站平台，包含界面设计、品牌营销等。',
					path: '/independence'
				},
				{
					image: require('@/assets/img/home/yewu-3.png'),
					title: 'APP开发',
					text: '根据客户需求开发第三方应用程序，包括需求分析、竞品分析和原型设计等。',
					path: '/appdev'
				},
				{
					image: require('@/assets/img/home/yewu-4.png'),
					title: '小程序开发',
					text: '为不同行业提供一站式小程序开发服务，降低企业开发压力，获得更多流量。',
					path: '/applet'
				}
			]
		},
		success: {
			title: '成功案例',
			navs: [
				{
					id: 30,
					name: '聚合支付'
				},
				{
					id: 31,
					name: '独立站建设'
				},
				{
					id: 32,
					name: 'APP开发'
				},
				{
					id: 33,
					name: '小程序开发'
				}
			]
		}
		/* banner: {
			image:require('@/assets/img/home/index-banner.png'),
			btn: {
				text: '点击立即咨询',
				image: ''
			},
			btnstyle: 'homebtn'
		},
		describe: [
			{
				image: require('@/assets/img/home/guarantee-icon-1.png'),
				title: '全面',
				content: '一站式跨境服务方案。立足支付，聚焦品牌。'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-2.png'),
				title: '专业',
				content: '超过十年跨境经验团队。超全方案，实操带领。'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-3.png'),
				title: '服务',
				content: '专业服务，提供满意方案。境内客服，贴合商家习惯。'
			},
			{
				image: require('@/assets/img/home/guarantee-icon-4.png'),
				title: '零风险',
				content: '模式成熟，零风险创业。安全保障，轻松当老板。'
			}
		],
		power: {
			otitle: '赋能跨境品牌销售增长',
			stitle: '连接企业与全球消费者',
			result: [
				{
					image: require('@/assets/img/home/service-icon-1.png'),
					title: '独立站智能建设',
					content: '提供多种模板，定制化独立站轻松快速搭建'
				},
				{
					image: require('@/assets/img/home/service-icon-2.png'),
					title: '营销自动化体系',
					content: '丰富的营销板块，优化投放策略，精准营销触达'
				},
				{
					image: require('@/assets/img/home/service-icon-3.png'),
					title: '消费者洞察方案',
					content: '可视化数据图表，洞察客户画像，提升网站流量'
				},
				{
					image: require('@/assets/img/home/service-icon-4.png'),
					title: '全球支付解决方案',
					content: '自研聚合支付系统，一个账号连同全球商机'
				}
			]
		},
		service: {
			otitle: '夸父核心服务',
			stitle: '为跨境卖家提供高效管理方法和解决方案',
			describe: {
				summarize: [
					'策略',
					'专业',
					'技术'
				],
				advert: {
					top: '100%',
					bottom: '满意度'
				}
			},
			result: {
				title: '快速搭建风格化独立站',
				content: [
					{
						image: require('@/assets/img/home/build-icon-1.png'),
						content: 'DIY可视化操作，轻松、快速搭建'
					},
					{
						image: require('@/assets/img/home/build-icon-2.png'),
						content: '各行各业免费模板，一键套用'
					},
					{
						image: require('@/assets/img/home/build-icon-3.png'),
						content: '无限量自定义页面，增添更多营销场景'
					},
					{
						image: require('@/assets/img/home/build-icon-4.png'),
						content: '操作体验人性化，使用舒适顺畅'
					}
				]
			},
			btn: {
				text: '快速体验建站',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		payment: {
			title: '完善的跨境支付体系',
			content: [
				{
					bg: require('@/assets/img/home/payment-function-1.png'),
					text: '支持全球收款，收单、换汇、付款等'
				},
				{
					bg: require('@/assets/img/home/payment-function-2.png'),
					text: '支持11个主流币种'
				},
				{
					bg: require('@/assets/img/home/payment-function-3.png'),
					text: '支持独立站综合解决方案'
				}
			],
			btn: {
				text: '快速体验建站',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		onestep: {
			otitle: '一站式代理运营服务',
			stitle: '提供独立站代运营服务，帮助企业货通全球',
			resultstyle: 'stepstyle',
			result: [
				{
					image: require('@/assets/img/home/advantage-icon-1.png'),
					title: '人才优势',
					text: '运营人员经验丰富,3-8年运营推广操盘经验,大项目管理经验'
				},
				{
					image: require('@/assets/img/home/advantage-icon-2.png'),
					title: '技术优势',
					text: '独立站建设全程操作，选品推广，精准营销，跨境支付方案'
				},
				{
					image: require('@/assets/img/home/advantage-icon-3.png'),
					title: '资源优势',
					text: '丰富的平台资源、推广资源、专业跨境团队资源'
				}
			],
			btn: {
				text: '了解品牌托管',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		} */
	},
	product: {
		banner: {
			image: require('@/assets/img/product/kuafuqinian-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		matrix: {
			otitle: '夸父全场景产品矩阵',
			stitle: '为企业提供个性化定制服务',
			result: [
				{
					image: require('@/assets/img/product/changjing-1.png'),
					title: '聚合支付',
					text: '告别繁琐，满足各交易场景，接通主流支付渠道'
				},
				{
					image: require('@/assets/img/product/changjing-2.png'),
					title: '网站建设',
					text: '打破传统繁琐高成本建站，高度自由操作'
				},
				{
					image: require('@/assets/img/product/changjing-3.png'),
					title: 'App开发',
					text: '针对企业个性化需求，定制专属APP软件功能'
				},
				{
					image: require('@/assets/img/product/changjing-4.png'),
					title: '小程序开发',
					text: '定制原生小程序，无需下载，多端营销'
				}
			]
		},
		require: {
			otitle: '服务多行业、多场景的使用需求',
			stitle: '按需定制，丰富的服务经验，助力客户成功',
			image: require('@/assets/img/product/duohangye.png')
		},
		all: {
			otitle: '全方位优势整合',
			stitle: '为众多企业提供强大而全面的互联网产品解决方案',
			result: [
				{
					image: require('@/assets/img/product/youshi-1.png'),
					title: '国家高新技术企业'
				},
				{
					image: require('@/assets/img/product/youshi-2.png'),
					title: '1000+服务行业'
				},
				{
					image: require('@/assets/img/product/youshi-3.png'),
					title: '个性化定制服务'
				},
				{
					image: require('@/assets/img/product/youshi-4.png'),
					title: '多端同步PC/App/小程序'
				},
				{
					image: require('@/assets/img/product/youshi-5.png'),
					title: '安全服务数据保密'
				},
				{
					image: require('@/assets/img/product/youshi-6.png'),
					title: '服务支持全程护航'
				}
			]
		}
	},
	// 中文版没有case，中文版叫聚合支付。
	payment: {
		banner: {
			image: require('@/assets/img/payment/banner.png'),
			btn: {
				text: '立即咨询',
				image: ''
			},
			btnstyle: 'zhcasebtn'
		},
		process: {
			otitle: '告别繁琐，一点轻松接入',
			stitle: '',
			resultstyle: 'prostyle',
			result: [
				{
					image: require('@/assets/img/case/intro-icon-1.png'),
					title: '5分钟',
					text: '完成联调测试'
				},
				{
					image: require('@/assets/img/case/intro-icon-2.png'),
					title: '4个步骤',
					text: '完成对接上线'
				},
				{
					image: require('@/assets/img/case/intro-icon-3.png'),
					title: '7行代码',
					text: '接入成功'
				}
			],
			btn: {
				text: '',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		trust: {
			otitle: '客户信赖',
			stitle: '引领支付新格局',
			result: [
				{
					image: require('@/assets/img/payment/xinlai-1.png'),
					title: '整合全渠道支付收款',
					text: '支持多平台资金统一管理，高效率、低成本完成平台资金回收。'
				},
				{
					image: require('@/assets/img/payment/xinlai-2.png'),
					title: '支付接入简单明了',
					text: '不论是微信、支付宝、小程序、网站，一点轻松接入所有渠道。'
				},
				{
					image: require('@/assets/img/payment/xinlai-3.png'),
					title: '清晰的管理后台',
					text: '系统稳定性高,搭建账户体系，B2C&B2B都能高效进行财务管理。'
				},
				{
					image: require('@/assets/img/payment/xinlai-4.png'),
					title: '资金管理安全合规',
					text: '夸父团队有丰富的合规管理经验，提供安全、专业、合规的服务。'
				}
			],
			btn: {
				text: '联系我们',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		paycase: {
			otitle: '多场景支付方案',
			stitle: '覆盖跨境支付业务',
			result: [
				{
					image: require('@/assets/img/payment/changjing-1.png'),
					text: '商城平台'
				},
				{
					image: require('@/assets/img/payment/changjing-2.png'),
					text: '小程序/H5'
				},
				{
					image: require('@/assets/img/payment/changjing-3.png'),
					text: '零售商户'
				},
				{
					image: require('@/assets/img/payment/changjing-4.png'),
					text: '手机APP'
				},
				{
					image: require('@/assets/img/payment/changjing-5.png'),
					text: 'PC网页'
				},
				{
					image: require('@/assets/img/payment/changjing-6.png'),
					text: '线下扫码'
				}
			]
		},
		solution: {
			stitle: '定制化解决方案',
			result: [
				{
					title: '全场景适用',
					text: '支持不同业务场景的交易方式'
				},
				{
					title: '个性化定制',
					text: '支持个性化定制和私有化部署'
				},
				{
					title: '专业团队',
					text: '一对一梳理需求，提出接入建议、定制支付解决方案'
				},
				{
					title: '收费透明',
					text: '精准服务，收费信息透明'
				}
			],
			btn: {
				text: '点击咨询',
				image: require('@/assets/img/home/more-arrow-icon.png')
			}
		},
		option: {
			stitle: '选择夸父聚合，更快更安全',
			result: [
				{
					image: require('@/assets/img/case/choose-icon-1.png'),
					title: '安全保障',
					text: '交易数据加密，是可信赖、 靠谱的选择'
				},
				{
					image: require('@/assets/img/case/choose-icon-2.png'),
					title: '快速接入方式',
					text: '接口简单、安全，专人协助快速接入'
				},
				{
					image: require('@/assets/img/case/choose-icon-3.png'),
					title: '一站式资金管理',
					text: '多平台资金统一管理，避免高额的综合手续费'
				},
				{
					image: require('@/assets/img/case/choose-icon-4.png'),
					title: '技术支持',
					text: '专业的综合服务团队，实时维护解决问题'
				},
				{
					image: require('@/assets/img/case/choose-icon-5.png'),
					title: '无需门槛',
					text: 'B2C、B2B 渠道场景全覆盖，无资质要求，价格合理'
				},
				{
					image: require('@/assets/img/case/choose-icon-6.png'),
					title: '贴心客服',
					text: '实时为您解决问题，专业指导效率高'
				}
			]
		}
	},
	independence: {
		banner: {
			image:require('@/assets/img/independence/site-banner-zh.png'),
			btn: {
				text: '联系我们',
				image: require('@/assets/img/home/more-arrow-icon.png')
			},
			btnstyle: 'independencebtn'
		},
		service: {
			title: '稳定和安全的跨境电商服务',
			result: [
				{
					image: require('@/assets/img/independence/stable-image-1.png'),
					title: '建站服务',
					content: [
						'丰富的精美主题模板选择。',
						'卡片式操作，无技术和设计要求。',
						'操作界面简洁流畅，使用感舒适'
					]
				},
				{
					image: require('@/assets/img/independence/stable-image-2.png'),
					title: '营销工具',
					content: [
						'智能化运营，成熟完善的服务生态。',
						'拥有选品、引流、物流、管理等多种工具。',
						'丰富的营销工具，促进销量提升。'
					]
				},
				{
					image: require('@/assets/img/independence/zh-stable-image-3.png'),
					title: '应用场景',
					content: [
						'Facebook、Google、Ins等多渠道销售链接您的全球客户。',
						'汇聚优秀开发者，运营者，助力生意广阔前景。'
					]
				},
				{
					image: require('@/assets/img/independence/stable-image-4.png'),
					title: '专业服务',
					content: [
						'本地化经营理念，贴合国外用户喜好。',
						'依托专业经验，提供出海指导。',
						'提供整店托管，一站式服务。'
					]
				}
			],
			specify: [
				{
					image: require('@/assets/img/independence/advantage-icon-1.png'),
					text: '自定义'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-2.png'),
					text: '高效率'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-3.png'),
					text: '精准营销'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-4.png'),
					text: '安全可靠'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-5.png'),
					text: '一站式服务'
				},
				{
					image: require('@/assets/img/independence/advantage-icon-6.png'),
					text: '夸父聚合帮助'
				}
			]
		},
		brand: require('@/assets/img/independence/store-image.png'),
		website: {
			title: '四个简单的步骤创建自己的网站',
			image: require('@/assets/img/independence/register-image.png'),
			result: [
				{
					title: '我们已完成',
					content: [
						{
							title: '精美模板：',
							text: '资深UI完成界面设计'
						},
						{
							title: '前端开发：',
							text: '打造最具吸引力的页面'
						},
						{
							title: '后端维护：',
							text: '优秀的稳定性和安全性'
						},
						{
							title: '运营工具：',
							text: '专业营销工具进行推广'
						},
						{
							title: '支付渠道：',
							text: '自研系统为收付保驾护航'
						}
					]
				},
				{
					title: '您只需要',
					content: [
						{
							title: '选择模板：',
							text: '超多精美模板，定制你的商店'
						},
						{
							title: '添加产品：',
							text: '上传吸引人的图片/价格/描述'
						},
						{
							title: '开始销售：',
							text: '设置支付网关/物流平台,开始销售'
						},
					]
				}
			],
			more: {
				otitle: {
					big: '50+',
					small: '行业样式模板'
				},
				stitle: '提供多款精美，可定制的主题模板，快速建立专属网站，实现品牌出海',
				result: [
					{
						nav: '所有',
						content: []
					},
					{
						nav: '多页面',
						content: []
					},
					{
						nav: '单页面',
						content: []
					},
					{
						nav: '商务',
						content: []
					},
					{
						nav: '店铺',
						content: []
					},
					{
						nav: '展览',
						content: []
					}
				],
				btn: '更多模板'
			}
		},
		onestep: {
			title: '一站式服务解决跨境国际贸易问题',
			image: require('@/assets/img/independence/service-left-image.png'),
			result: [
				{
					title: '品牌注册',
					text: '稳定的平台和全面的服务，可在200多个国家/地区办理国际品牌注册'
				},
				{
					title: '快速构建商店网站',
					text: '从设计、施工、营销到支付的一站式服务，帮助您加速在国际市场的增长'
				},
				{
					title: '精准营销',
					text: '专业的营销工具、直观的数据洞察力、低成本的客户获取和销售增长'
				},
				{
					title: '门店管理',
					text: '无论您身在何处，都可以轻松管理、轻松处理订单、发货和付款'
				},
				{
					title: '无忧支付',
					text: '自主开发的夸父聚合系统，可快速连接独立站点商户，提高资金安全性'
				}
			]
		},
		pictures: [
			{
				image: require('@/assets/img/independence/templates-image-1.png'),
				text: '视觉大片'
			},
			{
				image: require('@/assets/img/independence/templates-image-2.png'),
				text: '品牌商铺'
			},
			{
				image: require('@/assets/img/independence/templates-image-3.png'),
				text: '计算机技术'
			},
			{
				image: require('@/assets/img/independence/templates-image-4.png'),
				text: '健康医疗'
			},
			{
				image: require('@/assets/img/independence/templates-image-5.png'),
				text: '时尚服饰'
			},
			{
				image: require('@/assets/img/independence/templates-image-6.png'),
				text: '自然原木'
			},
			{
				image: require('@/assets/img/independence/templates-image-7.png'),
				text: '车队之家'
			},
			{
				image: require('@/assets/img/independence/templates-image-8.png'),
				text: '饮品酒水'
			}
		],
		team: {
			otitle: '专业团队为品牌保驾护航',
			resultstyle: 'teamstyle',
			result: [
				{
					image: require('@/assets/img/independence/professional-icon-1.png'),
					title: '全方位服务',
					text: '从建站、运营、营销，选品、到物流、支付，提供全流程专业服务。'
				},
				{
					image: require('@/assets/img/independence/professional-icon-2.png'),
					title: '技术保障',
					text: '强大的技术团队，持续进行产品升级，每月推出海量功能更新，提供稳定安全的技术保障。'
				},
				{
					image: require('@/assets/img/independence/professional-icon-3.png'),
					title: '资深团队',
					text: '3-8年独立站运营团队,深耕跨境行业，拥有丰富的行业经验与数据积累。'
				}
			],
			btn: {
				text: '',
				image: ''
			}
		},
		about: {
			title: {
				big: 'ABOUT',
				small: '夸父科技'
			},
			content: {
				title: '打造您的国际品牌',
				text: '夸父坚持全面的自我研究和创新，以软件开发和平台运营为核心业务。依托完整的支付系统和网站建设服务，为全球品牌卖家提供综合跨境电商运营和管理解决方案，包括开店、流量引流、支付、物流等服务。帮助好产品找到更多买家，提升品牌的国际影响力。如果您还想在全球扩大销售，请加入我们，与各地的消费者建立联系。'
			}
		}
	},
	appdev: {
		banner: {
			image: require('@/assets/img/appdev/banner (1).png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		blocks: [
			{
				image: require('@/assets/img/appdev/youshi-1.png'),
				text: '需求评估'
			},
			{
				image: require('@/assets/img/appdev/youshi-2.png'),
				text: '专属功能定制'
			},
			{
				image: require('@/assets/img/appdev/youshi-3.png'),
				text: '定价透明合理'
			},
			{
				image: require('@/assets/img/appdev/youshi-4.png'),
				text: '后期维护'
			}
		],
		swipers: {
			otitle: '研究主流行业，按需定制',
			stitle: '提供全行业APP开发解决方案'
		},
		charge: {
			intro: '————项目介绍',
			title: '叮咚充值',
			text: '叮咚充值是一个充值和商城平台，提供包含产品销售和优惠充值的服务，中英两个版本自由切换，为国内外用户提供了便捷充值和购物的渠道。'
		},
		desribe: [
			{
				image: require('@/assets/img/appdev/litu1.png'),
				title: '充值中心',
				text: '移动、电信、联通三大运营商均享充值优惠。不用出门,不用排队，输入手机号码,即可享受最优惠的话费充值服务。',
				flag: 0
			},
			{
				image: require('@/assets/img/appdev/WEB-LITU-1.png'),
				title: '产品商城',
				text: '精挑细选的莫桑钻产品，包含戒指、项链、耳钉等类目，款式时尚经典，品质上乘，与你共享璀璨。',
				flag: 1
			}
		]
	},
	applet: {
		banner: {
			image: require('@/assets/img/applet/banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		blocks: [
			{
				title: '即扫即用',
				text: '用户扫一扫即可使用，无需下载、无需注册，不占手机内存。',
				image: require('@/assets/img/applet/youshi-1.png')
			},
			{
				title: '流量红利',
				text: '微信为小程序开放18个流量入口，享超10亿的微信用户流量。',
				image: require('@/assets/img/applet/youshi-2.png')
			},
			{
				title: '低成本低风险',
				text: '开发周期较短，开发成本实惠，降低企业成本，减少创业者风险。',
				image: require('@/assets/img/applet/youshi-3.png')
			}
		],
		cover: {
			otitle: '覆盖全行业，打通线上线下',
			stitle: '集展示宣传、互动娱乐、交易售后为一体，个性化定制，抢占市场',
			btn: {
				text: '点击定制',
				image: require('@/assets/img/home/more-arrow-icon.png')
			},
			blocks: [
				{
					image: require('@/assets/img/applet/tu-1.png'),
					title: '小程序功能',
					text: '官网展示、多平台小程序、流量增长、用户活跃、营销变现、提升销量……'
				},
				{
					image: require('@/assets/img/applet/tu-2.png'),
					title: '花式流量入口',
					text: '附近的小程序、二维码、好友分享、消息群发、文章推送、商家关联、群分享、消息通知……'
				},
				{
					image: require('@/assets/img/applet/tu-3.png'),
					title: '适用行业',
					text: '教育培训、社区公益、服饰箱包、金融保险、外卖餐饮、电子数码、美容医疗、商城超市……'
				}
			]
		},
		describe: [
			{
				image: require('@/assets/img/applet/jieshao-1.png'),
				title: '连江旅游小程序',
				text: '提供超实惠的连江景点、门票、美食、车票等预定服务，开启一站式便捷服务。'
			},
			{
				image: require('@/assets/img/applet/jieshao-2.png'),
				title: '酒店住宿',
				text: '优惠入住星级酒店、特色民宿，海量酒店房型真实照片，入住有保障。'
			},
			{
				image: require('@/assets/img/applet/jieshao-3.png'),
				title: '地图精准搜索',
				text: '新一代人工智能精准定位小程序，提供路线规划与导航，避免拥堵，为客户提供更准确、更丰富、更便捷的服务。'
			},
			{
				image: require('@/assets/img/applet/jieshao-4.png'),
				title: '旅游路线规划',
				text: '为您提供全面细致的旅行攻略、购物指南，助您实现说走就走的旅行梦想。'
			}
		],
		service: {
			otitle: '我们的服务',
			stitle: '夸父提供定制化+管家式服务，保证项目顺利运营',
			result: [
				{
					image: require('@/assets/img/applet/fuwu-1.png'),
					text: '合同签订'
				},
				{
					image: require('@/assets/img/applet/fuwu-2.png'),
					text: '内容梳理'
				},
				{
					image: require('@/assets/img/applet/fuwu-3.png'),
					text: '原型设计'
				},
				{
					image: require('@/assets/img/applet/fuwu-4.png'),
					text: '视觉设计'
				},
				{
					image: require('@/assets/img/applet/fuwu-5.png'),
					text: '客户体验'
				},
				{
					image: require('@/assets/img/applet/fuwu-6.png'),
					text: '小程序研发'
				},
				{
					image: require('@/assets/img/applet/fuwu-7.png'),
					text: '小程序测试'
				},
				{
					image: require('@/assets/img/applet/fuwu-8.png'),
					text: '上线运营'
				}
			]
		}
	},
	brand: {
		banner: {
			otitle: '为跨境支付保驾护航',
			stitle: '推进全球贸易简单化',
			image: require('@/assets/img/brand/brand-profile-line.png')
		},
		tech: {
			otitle: '坚持自主创新、持续创新',
			stitle: '助推跨境支付的格局和运行方式',
			result: [
				{
					title: '2015',
					text: '建立时间'
				},
				{
					title: '50+',
					text: '精英团队'
				},
				{
					title: '30+',
					text: '专利和案例'
				},
				{
					title: '100K+',
					text: '服务过的跨境企业'
				},
				{
					title: '￥30M+',
					text: '企业营收'
				},
			],
			content: '2015年，我们紧跟趋势，进入跨境收款新时代，为众多中小企业提供一个集跨境支付（含外贸收款）、全球收单、金融服务、定制开发等服务为一体的一站式跨境服务平台，累计服务超过10万多家跨境企业，交易额达3千万元。'
		},
		world: {
			title: '立足本土，服务全球',
			content: '自成立以来，夸父不断丰富业务模式，为企业信息化布局提供集规划设计、运营管理为一体的智慧化服务，加快企业工作流程，降低信息化建设成本，提供科学精准、人性化的专业IT技术服务，帮助企业拓宽线上渠道，布局全球，共赢未来。'
		},
		break: {
			otitle: '从不止步眼前的成绩永远追求创新和突破',
			stitle: '这是夸父不变的信念。夸父建立多产品销售平台，拓展产品销售渠道，建立独立的跨境电子商务网站，探索全球商机，帮助您打造国际品牌。',
			image: require('@/assets/img/brand/data-increase-bg.png')
		}
	},
	contact: {
		title: '联系我们',
		result: [
			{
				image: require('@/assets/img/contact/telephone-icon.png'),
				title: '服务热线',
				text: 'hahessmoissanite@gmail.com'
			},
			{
				image: require('@/assets/img/contact/email-icon.png'),
				title: '公司电子邮件',
				text: 'hahessmoissanite@gmail.com'
			},
			{
				image: require('@/assets/img/contact/address-icon.png'),
				title: '公司地址',
				text: '福州市闽侯县上街镇乌龙江南大道30号福州清华紫光科技园-海峡科技研发区1-2#研发楼4层405研发'
			}
		],
		image: require('@/assets/img/contact/map-image.png')
	},
	talent: {
		banner: {
			otitle: '现在加入，一起创造未来',
			stitle: 'Join Kuafu Family',
			image: require('@/assets/img/talent/park-zone-bg.png')
		},
		profile: {
			title: '关于夸父',
			content: '夸父信息科技（平潭）有限公司成立于2015年，总部位于福建平潭综合试验区，目前在福州高新区设有分公司。公司坚持全面自研创新，拥有多项专利成果，秉持着“成为推动行业发展的头部跨境电商服务商”的发展理念，致力于以技术为驱动力，以用户需求为导向，专注服务跨境自建站商户。',
			points: [
				'平潭综合实验区高新技术企业',
				'平潭综合实验区重点企业',
				'福建省高新技术企业',
				'国家高新技术企业'
			],
			imgText: [
				{
					order: require('@/assets/img/talent/order-1.png'),
					otitle: '办公环境',
					stitle: '宽敞明亮',
					content: '工作地点位于福州市高新区清华紫光科技园区内，拥有便利的路网交通，完善的园区配套设施。办公环境干净整洁，开放的沟通渠道，另配置茶水间，健身练习点，为您提供施展拳脚的空间，发挥自身价值的舞台！',
					image: require('@/assets/img/talent/image-1.png')
				},
				{
					order: require('@/assets/img/talent/order-2.png'),
					otitle: '工作氛围',
					stitle: '平等愉悦',
					content: '温馨和谐的工作氛围，平等高效的沟通方式，年轻化的团队构成，让你在自由无束缚的环境中，发挥无限创意与潜能。',
					image: require('@/assets/img/talent/image-2.png')
				},
				{
					order: require('@/assets/img/talent/order-3.png'),
					otitle: '福利待遇',
					stitle: '全面完善',
					content: '>五险一金<br/>>交通补贴<br/>>带薪年假<br/>>法定假期<br/>>年终奖金<br/>>项目奖金<br/>>年度调薪<br/>>节日福利<br/>>内部职业培训<p style="margin-top: 8%">福利不断升级，让公司成为你前进路上的坚实后盾</p>',
					image: require('@/assets/img/talent/image-3.png')
				},
				{
					order: require('@/assets/img/talent/order-4.png'),
					otitle: '上班时间',
					stitle: '6.5小时*5天',
					content: '周一至周五：上午9:00-12:00；下午14： 00-17:30，周末双休，不鼓励加班。',
					image: require('@/assets/img/talent/image-4.png')
				}
			]
		},
		cards: [
			{
				title: '社会招聘',
				content: '面向社会，积极吸引国内外各界优秀人才。',
				btn: '查看职位',
				path: '/society'
			},
			{
				title: '校园招聘',
				content: '我们提供定制的3年培训计划，面向所有当前学生。',
				btn: '查看职位',
				path: '/campus'
			}
		]
	},
	society: {
		banner: {
			image: require('@/assets/img/society/zh-social-recruitment-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		sift: {
			fun: {
				filt: '筛选',
				clear: '清除'
			},
			job: {
				title: '职位类别'
			},
			city: {
				title: '工作地点',
				list: [
					{
						id: 0,
						name: '福州'
					},
					{
						id: 1,
						name: '平潭'
					},
					{
						id: 2,
						name: '沈阳'
					}
				]
			}
		},
		choose: {
			title: '选择一个职位，开始你的职业生涯！',
			job: '职位描述：',
			require: '工作要求：',
			btn: '提交简历'
		}
	},
	campus: {
		banner: {
			image: require('@/assets/img/campus/zh-Campus-Recruiting-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		project: {
			otitle: '校招项目',
			stitle: '夸父面向在校生/应届毕业生的招聘项目',
			result: [
				{
					image: require('@/assets/img/campus/progranm-image-1.png'),
					title: '2022&2023届应届生',
					text: '诚邀以目标为导向、拼搏进取、持之以恒的应届毕业生加入团队，通过量身定做的3年培养计划，快速成长为公司中坚力量。'
				},
				{
					image: require('@/assets/img/campus/progranm-image-2.png'),
					title: '2023&2024届实习生',
					text: '针对大三/研二同学，提供优质暑期/长期实习项目，表现优异者还可直接获得校招offer。'
				}
			]
		},
		features: {
			otitle: '项目特色',
			stitle: '全面人才成长计划',
			result: [
				{
					image: require('@/assets/img/campus/feature-icon-1.png'),
					title: '导师带教计划',
					text: '一对一辅导带你快速融入和成长'
				},
				{
					image: require('@/assets/img/campus/feature-icon-2.png'),
					title: '定制化培养方案',
					text: '针对个人能力和兴趣专门指定培养计划'
				},
				{
					image: require('@/assets/img/campus/feature-icon-3.png'),
					title: '轮岗体验式学习',
					text: '短期轮岗体验式学习全方位接触公司业务'
				},
				{
					image: require('@/assets/img/campus/feature-icon-4.png'),
					title: '广阔发展平台',
					text: '核心项目学习机会给予才华施展平台'
				},
				{
					image: require('@/assets/img/campus/feature-icon-5.png'),
					title: '完善晋升机制',
					text: '公开透明的晋升机制成长道路畅通无阻力'
				},
				{
					image: require('@/assets/img/campus/feature-icon-6.png'),
					title: '薪酬福利',
					text: '具有竞争力的薪酬待遇完善的福利保障'
				},
				{
					image: require('@/assets/img/campus/feature-icon-7.png'),
					title: '政策福利',
					text: '享受高新区人才公寓落地补贴等人才政策'
				}
			]
		},
		support: {
			otitle: '内部创业扶持',
			stitle: '打造高成长的“黄金赛道”',
			content: '公司将为有想法、有创意的员工团队，提供资金、资源等创业支持，成为公司内部创业孵化项目，在岗位工作中成就自我，实现梦想。',
			image: require('@/assets/img/campus/support-image.png')
		}
	},
	backstage: {
		banner: {
			image: require('@/assets/img/backstage/zh-backstage-banner.png'),
			btn: {
				text: '',
				image: ''
			},
			btnstyle: ''
		},
		card: [
			{
				label: '个人信息',
				content: {
					avatar: {
						btn: '头像上传',
						tip: '请上传不小于100*100像素的图像，该图像支持JPG、JPEG、PNG、GIF等格式。'
					},
					list: [
						{
							title: '姓名',
							placeholder: '请输入...',
							type: 'text',
							value: 'name'
						},
						{
							title: '国籍，城市',
							loadingholder: '加载中...',
							placeholder: '请选择...',
							type: 'cascader',
							value: 'region'
						},
						{
							title: '性别',
							placeholder: '请输入...',
							type: 'select',
							value: 'sex',
							options: [
								{
									value: 1,
									label: '男'
								},
								{
									value: 0,
									label: '女'
								}
							]
						},
						{
							title: '生日',
							placeholder: '请输入...',
							type: 'date',
							value: 'birth'
						},
						{
							title: '职业',
							placeholder: '请输入...',
							type: 'text',
							value: 'job'
						},
						{
							title: '个人描述',
							placeholder: '请输入...',
							type: 'textarea',
							value: 'desc'
						}
					],
					btn: '保存'
				}
			},
			{
				label: '账户设置',
				content: {
					list: [
						{
							title: '当前邮箱',
							placeholder: '请输入...',
							type: 'text',
							value: 'email',
							flag: ''
						},
						{
							title: '当前号码',
							placeholder: '请输入...',
							type: 'number',
							value: 'phone',
							flag: ''
						},
						{
							title: '',
							placeholder: '输入验证码...',
							type: 'number',
							value: 'code'
						}
					],
					btn: {
						code: {
							progress: {
								left: '',
								right: 's后重新发送'
							},
							success: '获取验证码'
						},
						isbind: {
							unbind: '解绑',
							replace: '替换'
						},
						ok: '确认',
						state: {
							bind: '绑定',
							unbind: '解绑'
						}
					},
					third: {
						otitle: '绑定第三方账户',
						stitle: '可以通过绑定第三方账号直接登录，也可以同步绑定的手机号和邮箱账号的信息。',
						content: {
							image: require('@/assets/img/backstage/grey-wechat.png'),
							id: ''
						}
					}
				}
			},
			{
				label: '修改密码',
				content: {
					list: [
						{
							title: '当前登录账户',
							placeholder: '账号',
							type: 'text',
							value: 'account'
						},
						{
							title: '',
							placeholder: '验证码...',
							type: 'number',
							value: 'code'
						},
						{
							title: '',
							placeholder: '新密码...',
							type: 'password',
							value: 'password'
						},
						{
							title: '',
							placeholder: '确认密码...',
							type: 'password',
							value: 'repassword'
						}
					],
					btn: {
						code: {
							progress: {
								left: '',
								right: 's后重新发送'
							},
							success: '获取验证码'
						},
						ok: '确认'
					}
				}
			},
			{
				label: '简历管理',
				content: {
					tip: '提交于：',
					success: '你的简历已经提交成功',
					list: [
						{
							otitle: '福州闽侯后端软件开发工程师',
							stitle: '提交时间：2022年10月9日16:30:28',
							success: '您的简历已成功提交。',
							image: require('@/assets/img/backstage/success-tag.png')
						}
					]
				},
				space: '还没有投递简历呦~'
			}
		]
	},
	upload: {
		info: {
			title: '个人信息',
			textbox: [
				{
					label: '姓名',
					name: 'name',
					placeholder: '姓名'
				},
				{
					label: '电话号码',
					name: 'phone',
					placeholder: '电话号码'
				},
				{
					label: '电子邮箱',
					name: 'email',
					placeholder: '电子邮箱'
				}
			]
		},
		files: {
			title: '文件上传',
			textbox: [
				{
					label: '简历上传',
					placeholder: '点击上传简历',
					tip: '支持pdf、doc、ppt、docx、pptx、wps、jpg、jpeg、png、txt等简历格式。',
					require: true
				},
				{
					label: '附件上传',
					placeholder: '点击上传附件',
					tip: '支持文档、图片、压缩包、视频、音频、设计文件和其他格式，所有附件的总大小不超过50MB。',
					require: false
				}
			]
		},
		btn: '确认提交'
	},
	consult: {
		formcard: {
			otitle: '咨询开始',
			stitle: '请在下面输入您的联系信息和问题。',
			placeholder: [
				{
					text: '您的姓名',
					value: 'name',
					isarea: false
				},
				{
					text: '您的电话',
					value: 'phone',
					isarea: false
				},
				{
					text: '您的邮箱',
					value: 'email',
					isarea: false
				},
				{
					text: '输入留言',
					value: 'content',
					isarea: true
				}
			],
			btn: '提交'
		}
	},
	login: {
		type: [
			{
				title: '密码登录',
				name: 'first',
				placeholder: [
					{
						text: '电话号码/电子邮箱',
						maxlength: 20,
						type: 'text'
					},
					{
						text: '密码',
						maxlength: 20,
						type: 'password'
					}
				]
			},
			{
				title: '短信登录',
				name: 'second',
				placeholder: [
					{
						text: '电话号码',
						maxlength: 20,
						type: 'number'
					},
					{
						text: '短信验证码',
						code: true,
						maxlength: 10,
						type: 'number'
					}
				]
			}
		],
		btn: {
			back: {
				icon: require('@/assets/img/icon/Back-icon.png'),
				text: '返回首页',
				tohome: true
			},
			code: {
				progress: {
					left: '',
					right: 's后重新发送'
				},
				success: '获取短信验证码'
			},
			ok: '登录',
			to: [
				{
					text: '注册账户',
					path: '/register'
				},
				{
					text: '忘记密码',
					path: '/reset'
				}
			]
		},
		third: {
			title: '第三方登录',
			part: [
				{
					image: require('@/assets/img/login/WeChat-logo.png'),
					text: '微信登录'
				}
			]
		},
		agreement: {}
	},
	register: {
		type: [
			{
				title: '注册',
				run: {
					text: '已有帐户，',
					to: {
						text: '登录',
						path: '/login'
					}
				},
				placeholder: [
					{
						text: '电话号码/电子邮箱',
						maxlength: 20,
						type: 'text'
					},
					{
						text: '短信验证码',
						code: true,
						maxlength: 10,
						type: 'number'
					},
					{
						text: '设置密码',
						maxlength: 20,
						type: 'password'
					}
				]
			}
		],
		btn: {
			back: {},
			code: {
				progress: {
					left: '',
					right: 's后重新发送'
				},
				success: '获取短信验证码'
			},
			ok: '确认注册',
			to: []
		},
		third: {},
		agreement: {
			text: '注册并同意',
			to: {
				text: '《用户协议》',
				path: ''
			}
		}
	},
	reset: [
		{
			type: [
				{
					title: '找回密码',
					run: {},
					placeholder: [
						{
							text: '电话号码/电子邮箱',
							type: 'text'
						},
						{
							text: '验证码',
							code: true,
							type: 'number'
						}
					]
				}
			],
			btn: {
				back: {
					icon: require('@/assets/img/icon/Back-icon.png'),
					text: '返回',
					tohome: false
				},
				code: {
					progress: {
						left: '',
						right: 's后重新发送'
					},
					success: '获取验证码'
				},
				ok: {
					text: '下一步',
					type: true
				},
				to: []
			},
			third: {},
			agreement: {}
		},
		{
			type: [
				{
					title: '修改密码',
					run: {},
					placeholder: [
						{
							text: '设置密码',
							type: 'password'
						},
						{
							text: '确认密码',
							type: 'password'
						}
					]
				}
			],
			btn: {
				back: {
					icon: require('@/assets/img/icon/Back-icon.png'),
					text: '返回',
					tohome: false
				},
				code: {},
				ok: {
					text: '确认',
					type: true
				},
				to: []
			},
			third: {},
			agreement: {}
		},
		{
			type: [],
			show: {
				image: require('@/assets/img/success/Reset Succeeded-icon.png'),
				text: '重置成功'
			},
			btn: {
				back: {},
				code: {},
				ok: {
					text: '返回登录',
					type: false
				},
				to: []
			},
			third: {},
			agreement: {}
		}
	],
	agreement: {
		back: '返回注册'
	},
	test: '中文',
	countryImg: require('@/assets/img/country/zh.png'),
	tabBar: [
		{ text: '首页', path: '/home' },
		{ text: '跨境服务', path: '/payment' },
		{ text: '关于我们', path: '/brand' },
		{ text: '人才招聘', path: '/recruit' },
	],
	tabBarImg: {
		home: require('@/assets/img/tabbar/zh-home.png'),
		about: require('@/assets/img/tabbar/zh-about.png'),
		service: require('@/assets/img/tabbar/zh-service.png'),
		career: require('@/assets/img/tabbar/zh-career.png')
	}
}

export default lang

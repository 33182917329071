<template>
    <div>
        <div class="wrapper">
            <Banner :bannerinfo="alldata.backstage.banner"></Banner>
            <div class="card">
                <div class="nav">
                    <div class="item" v-for="(item, index) in alldata.backstage.card" :key="index"
                        @click="toggeleIndex(index)">
                        <div class="text" :class="{ textActive: index == currentIndex }" v-text="item.label"></div>
                    </div>
                </div>
                <div class="formcard">
                    <div class="title" v-text="alldata.backstage.card[currentIndex].label"></div>
                    <div class="content">
                        <!-- 后台-个人信息 -->
                        <div class="item i1" v-if="currentIndex == 0">
                            <div class="avatarbox">
                                <!-- 头像预览区域 -->
                                <div class="seat">
                                    <!-- 有图像或选择某图片时 -->
                                    <img :src="imageUrl" alt="avatar" v-if="imageUrl">
                                    <!-- 没有图片显示内容 -->
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </div>
                                <!-- 头像文件选择区域 -->
                                <div class="operation">
                                    <el-upload action="#" :show-file-list="false" :auto-upload="false"
                                        :on-change="uploadAvatar">
                                        <el-button class="btn" size="small" type="primary">{{
                                            alldata.backstage.card[currentIndex].content.avatar.btn
                                        }}</el-button>
                                    </el-upload>
                                    <div class="tip" v-text="alldata.backstage.card[currentIndex].content.avatar.tip">
                                    </div>
                                </div>
                            </div>
                            <!-- 个人信息收集区域 -->
                            <el-form ref="personalform" :model="personalform">
                                <el-form-item v-for="(item, index) in alldata.backstage.card[currentIndex].content.list"
                                    :key="index" :label="item.title">
                                    <!-- 输入框 -->
                                    <el-input v-model="personalform[item.value]" :placeholder="item.placeholder"
                                        v-if="item.type == 'text'"></el-input>
                                    <!-- 下拉框 -->
                                    <el-select v-model="personalform[item.value]" :placeholder="item.placeholder"
                                        v-if="item.type == 'select'">
                                        <el-option v-for="(aitem, aindex) in item.options" :key="aindex"
                                            :label="aitem.label" :value="aitem.value">
                                        </el-option>
                                    </el-select>
                                    <el-cascader style="width: 100%;height: 100%;" v-model="address" :options="options"
                                        :props="defaultprops" @change="handleChange"
                                        :placeholder="dataloading ? item.loadingholder : item.placeholder"
                                        icon="el-icon-search" separator="," :disabled="dataloading"
                                        v-if="item.type === 'cascader'" popper-class="train-tree"></el-cascader>
                                    <!-- 时间选择框 -->
                                    <el-date-picker v-model="personalform[item.value]" type="date"
                                        value-format="yyyy-MM-dd" :placeholder="item.placeholder"
                                        :picker-options="pickerOptions" v-if="item.type == 'date'"></el-date-picker>
                                    <!-- 文本域 -->
                                    <el-input type="textarea" rows="4" autosize v-model="personalform[item.value]"
                                        :placeholder="item.placeholder" v-if="item.type == 'textarea'"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit('personal')"
                                        v-text="alldata.backstage.card[currentIndex].content.btn"></el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="item i2" v-if="currentIndex == 1">
                            <div class="info">
                                <el-form ref="accountform" :model="accountform">
                                    <el-form-item
                                        v-for="(item, index) in alldata.backstage.card[currentIndex].content.list"
                                        :key="index" :label="item.title"
                                        v-if="index !== alldata.backstage.card[currentIndex].content.list.length - 1 || (!userinfo.email || !userinfo.mobile)">
                                        <!-- 输入框 -->
                                        <el-input v-model="accountform[item.value]" :placeholder="item.placeholder"
                                            :type="item.type"
                                            :disabled="accountform[item.value] !== '' && (accountform[item.value] === userinfo.email || accountform[item.value] === userinfo.mobile)">
                                        </el-input>
                                        <template
                                            v-if="index != alldata.backstage.card[currentIndex].content.list.length - 1">
                                            <template v-if="!userinfo.email.trim() || !userinfo.mobile.trim()">
                                                <button type="info" @click="bindSend(item.value)" v-if="!item.flag"
                                                    class="special">
                                                    {{ time === 60 ?
    alldata.backstage.card[currentIndex].content.btn.code.success :
    alldata.backstage.card[currentIndex].content.btn.code.progress.left
    + time +
    alldata.backstage.card[currentIndex].content.btn.code.progress.right
                                                    }}
                                                </button>
                                            </template>
                                            <template v-else>
                                                <el-button class="bind" type="info"
                                                    v-text="alldata.backstage.card[currentIndex].content.btn.isbind.unbind"
                                                    @click="isbind('unbind', item.value)"></el-button>
                                                <!-- <el-button class="bind" type="info"
                                                    v-text="alldata.backstage.card[currentIndex].content.btn.isbind.replace"
                                                    @click="isbind('replace')"></el-button> -->
                                            </template>
                                        </template>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onSubmit('account')"
                                            v-text="alldata.backstage.card[currentIndex].content.btn.ok"></el-button>
                                    </el-form-item>
                                </el-form>
                                <div class="third" style="display: none;">
                                    <div class="title"
                                        v-text="alldata.backstage.card[currentIndex].content.third.otitle">
                                    </div>
                                    <div class="subheading"
                                        v-text="alldata.backstage.card[currentIndex].content.third.stitle">
                                    </div>
                                    <div class="content">
                                        <img :src="alldata.backstage.card[currentIndex].content.third.content.image"
                                            alt="vx">
                                        <button
                                            v-text="alldata.backstage.card[currentIndex].content.btn.state.bind"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item i3" v-if="currentIndex == 2">
                            <el-form ref="passwordform" :model="passwordform">
                                <el-form-item v-for="(item, index) in alldata.backstage.card[currentIndex].content.list"
                                    :key="index" :label="item.title">
                                    <!-- 输入框 -->
                                    <el-input v-model="passwordform[item.value]" :placeholder="item.placeholder"
                                        :type="item.type" :disabled="index === 0">
                                    </el-input>
                                    <el-button type="info" class="special" @click="passSend"
                                        v-if="item.value == 'code'">
                                        {{ time === 60 ? alldata.backstage.card[currentIndex].content.btn.code.success :
    alldata.backstage.card[currentIndex].content.btn.code.progress.left
    + time + alldata.backstage.card[currentIndex].content.btn.code.progress.right
                                        }}
                                    </el-button>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit('password')"
                                        v-text="alldata.backstage.card[currentIndex].content.btn.ok"></el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="item i4" v-if="currentIndex == 3 && recordlist.length">
                            <div class="info">
                                <div class="item" v-for="(item, index) in recordlist" :key="index">
                                    <div class="title">
                                        <span v-text="item.official_job_name"></span>
                                        <img src="@/assets/img/backstage/success-tag.png" alt="sub">
                                    </div>
                                    <div class="time">{{ alldata.backstage.card[currentIndex].content.tip }}
                                        {{ item.createtime_txt }}</div>
                                    <div class="content">
                                        <div class="text" v-text="alldata.backstage.card[currentIndex].content.success">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pages">
                                <el-pagination small :hide-on-single-page="true" layout="prev, next" :page-size="10"
                                    :current-page="currentpage" :total="listnum"
                                    @prev-click="val => changpage(val, 'prev')"
                                    @next-click="val => changpage(val, 'next')">
                                </el-pagination>
                            </div>
                        </div>
                        <div class="item i4 space" v-if="currentIndex == 3 && !recordlist.length">
                            {{ alldata.backstage.card[currentIndex].space}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
export default {
    name: 'Backstage',
    data() {
        return {
            // 当前页面垂直导航菜单索引
            currentIndex: 0,
            // 用户信息
            userinfo: '',
            //个人信息表单绑定数据
            personalform: {
                name: '',
                region: '',
                sex: '',
                birth: '',
                job: '',
                desc: ''
            },
            //头像bas64文件
            imageUrl: '',
            //地址框绑定数据
            address: '',
            //地址数据是否处于加载中
            dataloading: true,
            //获取到的地址数据
            options: [],
            //地址显示键值定义
            defaultprops: {
                label: 'name',
                children: 'childrens',
                value: 'code'
            },
            // 账户设置表单绑定数据
            accountform: {
                email: '',
                phone: '',
                code: ''
            },
            //修改密码表单绑定数据
            passwordform: {
                account: '',
                code: '',
                password: '',
                repassword: ''
            },
            //简历管理列表数据
            recordlist: [],
            //当前显示列表页
            currentpage: 1,
            //列表分页数据
            listnum: 0,
            time: 60,
            timer: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            }
        }
    },
    components: {
        Banner
    },
    mounted() {
        //挂载时便触发获取页面数据
        this.getPreviousData();
        //初始化显示
        this.toggeleIndex(this.currentIndex);
    },
    methods: {
        changpage(value, state) {
            if (state === 'prev') {
                console.log('上一个页面', value);
                if (this.currentpage === 1) return;
                this.currentpage--;
            } else {
                console.log("下一个页面", value, Math.ceil(this.listnum / 10));
                if (this.currentpage === Math.ceil(this.listnum / 10)) return;
                this.currentpage++;
            }
            this.toggeleIndex(this.currentIndex);
        },
        // 获取显示数据
        getPreviousData() {
            this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
            this.$http.post('user/addressJson', {
                language: this.alldata.nav.current.id
            }).then(res => {
                const { data } = res;
                if (data.code != 1) return this.$message.error(data.msg);
                this.options = [];
                data.data.forEach((item, index) => {
                    this.options = this.options.concat(item.childrens);
                });
                this.dataloading = false;
            });
        },
        // 左侧导航切换使右侧显示内容不同
        toggeleIndex(index) {
            this.currentIndex = index;
            clearInterval(this.timer);
            this.time = 60;
            //通过切换为各个菜单显示页面内容赋值
            switch (this.currentIndex) {
                case 0:
                    this.imageUrl = this.userinfo.avatar;
                    this.personalform.name = this.userinfo.nickname;
                    this.personalform.region = this.userinfo.country + "," + this.userinfo.city;
                    this.personalform.sex = Number(this.userinfo.gender);
                    this.address = [this.userinfo.country_code, this.userinfo.province_code]
                    this.personalform.birth = this.userinfo.birthday;
                    this.personalform.job = this.userinfo.occupation;
                    this.personalform.desc = this.userinfo.personal_description;
                    break;
                case 1:
                    this.accountform.email = this.userinfo.email;
                    this.accountform.phone = this.userinfo.mobile;
                    if (this.userinfo.email.trim() || !this.userinfo.mobile.trim()) {
                        // 只有邮箱
                        this.alldata.backstage.card[this.currentIndex].content.list[0].flag = 1
                    } else if (!this.userinfo.email.trim() || this.userinfo.mobile.trim()) {
                        // 只有手机号码
                        this.alldata.backstage.card[this.currentIndex].content.list[1].flag = 1;
                    } else {
                        //都有或都没有
                    }
                    console.log(this.alldata.backstage.card[this.currentIndex].content.list);
                    break;
                case 2:
                    // 优先显示邮箱
                    this.passwordform.account = this.userinfo.email || this.userinfo.mobile;
                    break;
                case 3:
                    // 获取上传简历记录
                    this.$http.post('commons/job/applicationlist', {
                        language: this.alldata.nav.current.id,
                        user_id: this.userinfo.id,
                        page: this.currentpage
                    }).then(res => {
                        console.log(res);
                        const { data } = res;
                        if (data.code != 1) return this.$message.error(data.msg);
                        console.log(data.data);
                        this.recordlist = data.data.data.reverse();
                        this.listnum = data.data.total;
                        console.log(this.recordlist, this.listnum);
                    });
                    break;
                default:
                    break;
            }
        },
        // 个人信息选择图片
        async uploadAvatar(file, fileList) {
            let imgArr = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
            console.log(file);
            if (imgArr.includes(file.raw.type)) {
                let url = await this.blobFileTobase64(file.raw);
                const res = await this.getImgPx(url);
                if (res.width <= 100 || res.height <= 100) return this.$message("上传图片像素尺寸大小不可小于100x100");
                this.imageUrl = url;
                console.log(res);
            } else {
                this.$message(this.alldata.tips.upload.imgformat);
            }
        },
        // blob和File文件转base64格式文件
        blobFileTobase64(blobFile) {
            let reader = new FileReader()
            reader.readAsDataURL(blobFile);
            return new Promise((resolve, reject) => {
                reader.onload = function () {
                    resolve(this.result)
                }
                reader.onerror = (err) => {
                    reject(err);
                };
            })
        },
        getImgPx(img) {
            const image = new Image()
            image.src = img
            return new Promise((resolve) => {
                image.onload = () => {
                    const width = image.width
                    const height = image.height
                    resolve({ width, height })
                }
            })
        },
        //级联选择框选择地址触发
        handleChange() {
            console.log(this.address);
        },
        //账户设置发送验证码
        async bindSend(val) {
            console.log(val);
            let account;
            let event;
            if (val == 'email') {
                if (!this.accountform[val].trim()) {
                    return this.$message(this.alldata.tips.backstage.account.mailempty);
                }else if(!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.accountform[val].trim())){
                    return this.$message(this.alldata.tips.backstage.account.truemail);
                }
                account = this.accountform[val];
                event = 'changeemail';
            } else if (val == 'phone') {
                if (!this.accountform[val].trim()) {
                    return this.$message(this.alldata.tips.backstage.account.phoneempty);
                }
                account = this.accountform[val];
                event = 'changemobile';
            }
            // if()
            const { data: result } = await this.sendCode(account, event);
            if (result.code !== 1) return this.$message.error(result.msg);
            this.$message.success(this.alldata.tips.code.success);
            this.time--;
            this.timer = setInterval(() => {
                this.time--;
                if (this.time < 0) {
                    //重新初始化倒计时事件
                    this.time = 60;
                    //清除定时器
                    clearInterval(this.timer);
                };
            }, 1000);
        },
        //账户设置解绑账号
        isbind(state, val) {
            console.log(val);
            if (this.alldata.nav.current.id === 2) {
                var test = val === 'email' ? '电子邮箱' : '电话号码';
            } else {
                var test = val;
            }
            if (state == 'unbind') {
                //注意：需要添加询问框
                console.log(state);
                this.$confirm(`${this.alldata.tips.backstage.account.delete.left} ${test} ${this.alldata.tips.backstage.account.delete.right}`, '', {
                    confirmButtonText: this.alldata.tips.backstage.account.btn.ok,
                    cancelButtonText: this.alldata.tips.backstage.account.btn.no,
                    type: 'warning'
                }).then(() => {
                    //确认
                    if (val === 'email') {
                        //解绑电子邮箱
                        this.$http.post('user/unbindemail', {
                            language: this.alldata.nav.current.id,
                            email: this.accountform.email
                        }, {
                            headers: {
                                "token": this.token(),
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then(res => {
                            const { data } = res;
                            if (data.code !== 1) return this.$message.error(data.msg);
                            console.log(data);
                            sessionStorage.setItem('userinfo', JSON.stringify(data.data.userinfo));
                            this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
                            this.alldata.backstage.card[this.currentIndex].content.list[0].flag = 0;
                            this.alldata.backstage.card[this.currentIndex].content.list[1].flag = 0;
                            this.toggeleIndex(this.currentIndex);
                            this.$message.success(this.alldata.tips.backstage.account.unbindsuccess);
                        });
                    } else if (val === 'phone') {
                        console.log("解绑电话号码");
                        this.$http.post('user/unbindmobile', {
                            language: this.alldata.nav.current.id,
                            mobile: this.accountform.phone
                        }, {
                            headers: {
                                "token": this.token(),
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }).then(res => {
                            const { data } = res;
                            if (data.code !== 1) return this.$message.error(data.msg);
                            console.log(data);
                            sessionStorage.setItem('userinfo', JSON.stringify(data.data.userinfo));
                            this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
                            this.alldata.backstage.card[this.currentIndex].content.list[0].flag = 0;
                            this.alldata.backstage.card[this.currentIndex].content.list[1].flag = 0;
                            this.toggeleIndex(this.currentIndex);
                            this.$message.success(this.alldata.tips.backstage.account.unbindsuccess);
                        });
                    }
                }).catch(() => {
                    //取消
                    // this.$message(this.alldata.tips.backstage.account.cancel);
                });
            } else if (state == 'replace') {
                console.log(state);
            }
        },
        //修改密码发送验证码
        async passSend() {
            console.log("发送修改密码验证码");
            let event = 'resetpwd';
            /* this.time--;
            this.timer = setInterval(() => {
                this.time--;
                if (this.time < 0) {
                    this.time = 60;
                    console.log("倒计时结束");
                    clearInterval(this.timer);
                }
            }, 1000); */
            const {data:result} = await this.sendCode(this.passwordform.account, event);
            if(result.code !== 1) return this.$message.error(result.msg);
                this.$message.success(this.alldata.tips.code.success);
                this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                }, 1000);
        },
        //各菜单项提交数据
        async onSubmit(state) {
            console.log(state);
            if (state == 'personal') {
                console.log("提交修改个人信息数据");
                if (this.dataloading) return this.$message(this.alldata.tips.backstage.personal.loading);
                const { data: res } = await this.$http.post('user/profile', {
                    language: this.alldata.nav.current.id,
                    avatar: this.imageUrl,
                    nickname: this.personalform.name,
                    gender: this.personalform.sex,
                    country_code: this.address[0],
                    province_code: this.address[1],
                    birthday: this.personalform.birth,
                    occupation: this.personalform.job,
                    personal_description: this.personalform.desc
                }, {
                    headers: {
                        "token": this.token(),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
                console.log(res);
                if (res.code != 1) return this.$message.error(res.msg);
                //重新存储修改后的用户信息
                sessionStorage.setItem('userinfo', JSON.stringify(res.data.userinfo));
                //重新获取当前用户信息并赋值
                this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
                this.alldata.backstage.card[this.currentIndex].content.list[0].flag = 0;
                this.alldata.backstage.card[this.currentIndex].content.list[1].flag = 0;
                //刷新当前页面数据
                this.toggeleIndex(this.currentIndex);
                this.$message.success(this.alldata.tips.backstage.personal.success);
            } else if (state == 'account') {
                console.log("绑定各账户");
                if (this.accountform.email.trim() == '') return this.$message(this.alldata.tips.backstage.account.mailempty);;
                if (this.accountform.phone.trim() == '') return this.$message(this.alldata.tips.backstage.account.phoneempty);
                if (this.accountform.code.trim() == '') return this.$message(this.alldata.tips.backstage.account.codempty);
                if (!this.alldata.backstage.card[this.currentIndex].content.list[0].flag) {
                    //绑定邮箱
                    const { data: res } = await this.$http.post('user/changeemail', {
                        language: this.alldata.nav.current.id,
                        email: this.accountform.email,
                        captcha: this.accountform.code
                    }, {
                        headers: {
                            "token": this.token(),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });
                    console.log(res);
                    if (res.code != 1) return this.$message.error(res.msg);
                    //重新存储修改后的用户信息
                    sessionStorage.setItem('userinfo', JSON.stringify(res.data.userinfo));
                    //重新获取当前用户信息并赋值
                    this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
                    //刷新当前页面数据
                    console.log("刷新当前页面之前");
                    this.toggeleIndex(this.currentIndex);
                    console.log("刷新当前页面之后");
                    this.$message.success(this.alldata.tips.backstage.account.success);
                } else if (!this.alldata.backstage.card[this.currentIndex].content.list[1].flag) {
                    //绑定电话号码
                    const { data: res } = await this.$http.post('user/changemobile', {
                        language: this.alldata.nav.current.id,
                        mobile: this.accountform.phone,
                        captcha: this.accountform.code
                    }, {
                        headers: {
                            "token": this.token(),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });
                    console.log(res);
                    if (res.code != 1) return this.$message.error(res.msg);
                    //重新存储修改后的用户信息
                    sessionStorage.setItem('userinfo', JSON.stringify(res.data.userinfo));
                    //重新获取当前用户信息并赋值
                    this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'));
                    this.alldata.backstage.card[this.currentIndex].content.list[0].flag = 0;
                    this.alldata.backstage.card[this.currentIndex].content.list[1].flag = 0;
                    //刷新当前页面数据
                    this.toggeleIndex(this.currentIndex);
                    this.$message.success(this.alldata.tips.backstage.account.success);
                }
            } else if (state == 'password') {
                console.log("修改登录密码");
                if (!this.passwordform.code.trim()) return this.$message(this.alldata.tips.backstage.password.code);
                if (!this.passwordform.password.trim()) return this.$message(this.alldata.tips.backstage.password.password);
                if (!this.passwordform.repassword.trim()) return this.$message(this.alldata.tips.backstage.password.repassword);
                if (this.passwordform.password.trim() != this.passwordform.repassword.trim()) return this.$message(this.alldata.tips.backstage.password.passdiff);
                let type = /@/.test(this.passwordform.account) ? 'email' : 'mobile';
                console.log(type);
                if (type == 'email') {
                    const { data: res } = await this.$http.post('user/resetpwd', {
                        language: this.alldata.nav.current.id,
                        type: type,
                        email: this.passwordform.account,
                        newpassword: this.passwordform.password,
                        captcha: this.passwordform.code
                    }, {
                        headers: {
                            "token": this.token(),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });
                    console.log(res);
                    if (res.code !== 1) return this.$message.error(res.msg);
                    this.passwordform.code = '';
                    this.passwordform.password = '';
                    this.passwordform.repassword = '';
                    this.$message.success(this.alldata.tips.backstage.password.success);
                } else if (type == 'mobile') {
                    const { data: res } = await this.$http.post('user/resetpwd', {
                        language: this.alldata.nav.current.id,
                        type: type,
                        mobile: this.passwordform.account,
                        newpassword: this.passwordform.password,
                        captcha: this.passwordform.code
                    }, {
                        headers: {
                            "token": this.token(),
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });
                    console.log(res);
                    if (res.code !== 1) return this.$message.error(res.msg);
                    this.passwordform.code = '';
                    this.passwordform.password = '';
                    this.passwordform.repassword = '';
                    this.$message.success(this.alldata.tips.backstage.password.success);
                }

            }
        }
    }
}
</script>

<style lang="scss">
/* .train-tree {
    height: 10vw !important;
 } */
</style>
<style lang="scss" scoped>
.i1 {
    ::v-deep .el-form {
        margin-top: 3.125vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .el-form-item {
            width: 48%;
            display: flex;
            flex-direction: column;
            margin-bottom: 2vw;

            &:nth-last-child(2),
            &:last-child {
                width: 100%;
            }

            &:last-child {

                .el-button {
                    width: 8.3vw;
                    height: 3.125vw;
                    padding: 0;
                    background-color: var(--bg-color-origin);
                    color: var(--text-color-base);
                    font-size: var(--font-size-ens);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }

            .el-form-item__label {
                margin-bottom: 0.2vw;
                padding-right: 0;
                line-height: var(--font-size-sm);
                text-align: left;
                font-size: var(--font-size-sm);
                color: var(--color-disabled);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .el-input__inner,
            .el-textarea__inner {
                padding: 0 1vw;
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);

                &::placeholder {
                    color: var(--color-disabled);
                }
            }

            .el-input__inner {
                height: 3.125vw;
            }

            .el-textarea__inner {
                overflow: hidden;
                padding: 0.8vw 1vw;
                min-height: 6.25vw !important;
            }

            .el-select {
                width: 100%;
            }

            .el-input {
                width: 100%;
            }

            &:nth-child(4) {

                .el-input__inner {
                    padding: 0 30px;
                }
            }
        }
    }
}

.i2,
.i3 {
    .el-form {

        .el-form-item {
            display: flex;
            flex-direction: column;

            &:last-child {
                .el-button {
                    width: 8.2vw;
                    height: 3.125vw;
                    padding: 0;
                    background-color: var(--bg-color-origin);
                    color: var(--text-color-base);
                    font-size: var(--font-size-ens);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }

            ::v-deep .el-form-item__label {
                margin-bottom: 0.2vw;
                padding-right: 0;
                line-height: var(--font-size-sm);
                text-align: left;
                font-size: var(--font-size-sm);
                color: var(--color-disabled);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .el-input {
                width: 50%;
                margin-right: 1vw;

                ::v-deep .el-input__inner {
                    height: 3.125vw;
                    padding: 0 1vw;
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }

            .bind {
                width: 6vw;
                height: 3.125vw;
                padding: 0;
                background-color: var(--bg-color-lighter);
                font-size: var(--font-size-sm);
                color: var(--text-color);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .special {
                width: 10vw;
                height: 3.125vw;
                padding: 0;
                background-color: var(--bg-color-lighter);
                font-size: var(--font-size-sm);
                color: var(--text-color);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }
}

.wrapper {
    background-color: #f1f1f1;
    overflow: hidden;

    .card {
        background-color: var(--bg-color-base);
        margin: 2.5vw 18.75vw 8.2vw;
        display: flex;
        border-radius: 1.04vw;

        .nav {
            padding: 2vw 0;
            // background-color: skyblue;
            border-radius: 1.04vw 0px 0px 1.04vw;

            .item {
                // background-color: pink;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .text {
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    height: 4vw;
                    color: var(--color-disabled);
                    font-size: var(--font-size-mid);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                    padding-right: 8vw;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 4px;
                        height: 0%;
                        margin-right: 2vw;
                        background-color: var(--color-success);
                        transition: height 0.5s;
                        // background-color: var(--bg-color-base);
                    }
                }

                .textActive {
                    background-color: #f1f1f1;
                    color: var(--text-color);

                    &::before {
                        height: 100%;
                    }
                }
            }
        }

        .formcard {
            width: 55%;
            padding: 2vw;
            box-shadow: -10px 0px 10px #f1f1f1;

            .title {
                margin-bottom: 2vw;
                color: var(--text-color);
                font-size: var(--font-size-hug);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            .content {

                .i1 {

                    .avatarbox {
                        width: 100%;
                        display: flex;

                        .seat {
                            width: 5.2vw;
                            height: 5.2vw;
                            margin-right: 0.9375vw;
                            border: 1px dashed #d9d9d9;
                            border-radius: 6px;
                            position: relative;
                            overflow: hidden;

                            &>img {
                                width: 100%;
                            }

                            .avatar-uploader-icon {
                                font-size: 1.5vw;
                                color: #8c939d;
                                width: 5.2vw;
                                height: 5.2vw;
                                line-height: 5.2vw;
                                text-align: center;
                            }
                        }

                        .operation {
                            width: 17vw;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .el-button {
                                background-color: var(--bg-color-lighter);
                                color: var(--text-color);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }

                            .tip {
                                color: var(--color-disabled);
                                font-size: var(--font-size-ens);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }
                        }
                    }

                    .info {
                        margin-top: 3.2vw;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .item {
                            width: 48%;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 2vw;

                            &:last-child {
                                width: 100%;
                                background-color: yellowgreen;
                            }

                            .samlltitle {
                                color: var(--color-disabled);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }
                        }
                    }

                    .submitbtn {

                        &>button {
                            width: 8.3vw;
                            height: 3.125vw;
                            background-color: var(--bg-color-origin);
                            border-radius: 0.52vw;
                            color: var(--text-color-base);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }

                .i2 {

                    .info {

                        .hidden {
                            display: none;
                        }

                        .item {
                            margin-bottom: 2vw;

                            .samlltitle {
                                color: var(--color-disabled);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }

                            .box {
                                // width: 60%;
                                display: flex;

                                .in {
                                    width: 60%;
                                }

                                &>button {
                                    flex: 1;
                                    margin-left: 1vw;
                                    background-color: var(--bg-color-lighter);
                                    border-radius: 0.5vw;
                                    color: var(--text-color);
                                    font-size: var(--font-size-sm);
                                    font-family: var(--font-family);
                                    font-weight: var(--font-weight-s);
                                }
                            }
                        }
                    }

                    .submitbtn {

                        &>button {
                            width: 8.3vw;
                            height: 3.125vw;
                            background-color: var(--bg-color-origin);
                            border-radius: 0.5vw;
                            color: var(--text-color-base);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }

                    .third {
                        margin-top: 3.125vw;

                        .title {
                            margin-bottom: 0.71825vw;
                            color: var(--text-color);
                            font-size: var(--font-size-lg);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .subheading {
                            margin-bottom: 1.40625vw;
                            color: var(--color-disabled);
                            font-size: var(--font-size-ens);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .content {
                            display: flex;
                            align-items: center;

                            &>img {
                                width: 3.125vw;
                                margin-right: 1vw;
                            }

                            &>button {
                                width: 8.3vw;
                                height: 3.125vw;
                                background-color: var(--bg-color-lighter);
                                border-radius: 0.5vw;
                                color: var(--text-color);
                                font-size: var(--font-size-mid);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }
                        }
                    }
                }

                .i3 {

                    .info {

                        .item {
                            margin-bottom: 2vw;
                            display: flex;
                            flex-direction: column;

                            .samlltitle {
                                color: var(--color-disabled);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }

                            .in {
                                width: 60%;
                            }
                        }
                    }

                    .submitbtn {

                        &>button {
                            width: 8.3vw;
                            height: 3.125vw;
                            background-color: var(--bg-color-origin);
                            border-radius: 0.5vw;
                            color: var(--text-color-base);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }

                .i4 {
                    // background-color: pink;

                    .info {

                        .item {
                            border: 0.1vw solid var(--bg-color-lighter);
                            padding: 1.4vw 2vw;

                            &:not(:last-child) {
                                margin-bottom: 1.5625vw;
                            }

                            .title {
                                margin-bottom: 0.5vw;
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;

                                &>span {
                                    color: var(--text-color);
                                    font-size: var(--font-size-mid);
                                    font-family: var(--font-family);
                                    font-weight: var(--font-weight-s);
                                }

                                &>img {
                                    width: 5vw;
                                    margin-right: -2.5vw;
                                }
                            }

                            .time {
                                margin-bottom: 0.5vw;
                                color: var(--color-disabled);
                                font-size: var(--font-size-sm);
                                font-family: var(--font-family);
                                font-weight: var(--font-weight-s);
                            }

                            .content {
                                background: url('@/assets/img/backstage/tip-frame-image.png') no-repeat scroll left top/100% auto;
                                padding: 1vw 1vw 0.8vw;

                                /* .trigle{
                                    width: 10px;
                                    height: 10px;
                                    background-color: yellow;
                                } */

                                .text {
                                    color: var(--text-color-inverse);
                                    font-size: var(--font-size-sm);
                                    font-family: var(--font-family);
                                    font-weight: var(--font-weight-s);
                                }
                            }
                        }
                    }

                    .pages {
                        float: right;
                    }
                }

                .space {
                    color: var(--color-disabled);
                    font-size: var(--font-size-mid);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                    padding-right: 8vw;
                }
            }
        }
    }
}
</style>
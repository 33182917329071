<template>
    <div>
        <div class="load_box">
            <div class="loadForm">
                <div class="loadInfo">
                    <div class="title">
                        <div class="block"></div>
                        <div class="text" v-text="alldata.upload.info.title"></div>
                    </div>
                    <el-form :model="infos" :rules="rules" ref="infoForm" class="demo-ruleForm">
                        <el-form-item v-for="(item, index) in alldata.upload.info.textbox" :key="index"
                            :label="item.label" :prop="item.name">
                            <el-input v-model="infos[item.name]"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="loadfile">
                    <div class="title">
                        <div class="block"></div>
                        <div class="text" v-text="alldata.upload.files.title"></div>
                    </div>
                    <div class="customform">
                        <div class="item" v-for="(item, index) in alldata.upload.files.textbox" :key="index">
                            <div class="title">
                                <span v-text="item.label"></span>
                                <span class="requirement" v-if="item.require">*</span>
                            </div>
                            <div class="btn">
                                <el-upload :action="$http.defaults.baseURL + 'common/upload/'" :headers="header"
                                    :show-file-list="false" :auto-upload="true"
                                    :before-upload="beforeAvatarUpload"
                                    :on-progress="(file, fileList) => { allFile(file, fileList, item.require) }"
                                    :on-success="(res, file, fileList) => { filesuccess(res, file, fileList, item.require) }">
                                    <button @click="operatebtn">
                                        <i class="el-icon-loading"
                                            v-if="currentbtn == item.placeholder && fileuploading"></i>
                                        <span v-text="item.placeholder" v-else></span>
                                    </button>
                                </el-upload>
                                <div class="filename" v-if="item.require" v-text="resume.name"></div>
                                <div class="filename" v-if="!item.require" v-text="attachment.name"></div>
                            </div>
                            <div class="tip" v-text="item.tip"></div>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <button v-text="alldata.upload.btn" @click="submitForm"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Upload',
    data() {
        return {
            infos: {
                name: '',
                phone: '',
                email: ''
            },
            resume: '',
            attachment: '',
            resumeFile: '',
            attachmentFile: '',
            // 文件是否在上传过程中
            fileuploading: false,
            // 当前上传文件的按钮
            currentbtn: ''
        }
    },
    computed: {
        rules() {
            return {
                name: [
                    { required: true, message: this.alldata.tips.upload.name, trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: this.alldata.tips.upload.phone, trigger: 'blur' }
                ],
                email: [
                    { required: true, message: this.alldata.tips.upload.email, trigger: 'blur' }
                ]
            }
        },
        header() {
            return {
                token: this.token()
            }
        }
    },
    methods: {
        operatebtn(e) {
            this.currentbtn = e.target.innerText;
            console.log(this.currentbtn, e.target.innerText);
        },
        beforeAvatarUpload(file) {
            console.log(file);
        },
        allFile(file, fileList, type) {
            // 文件在上传过程中
            this.fileuploading = true;
            console.log("文件上传中", this.fileuploading);
            console.log(file, fileList, type);
            /* if(type) return this.resume = file;
            this.attachment = file; */
        },
        filesuccess(res, file, fileList, type) {
            console.log("文件上传成功钩子", res, res.data, res.data.fullurl, this.resume, this.attachment);
            if (res.code != 1) return this.$message.error(res.msg);
            // 文件已经上传成功
            this.fileuploading = false;
            console.log("文件上传成功", this.fileuploading);
            if (type) {
                this.resumeFile = res.data.url;
                this.resume = file;
            } else {
                this.attachmentFile = res.data.url;
                this.attachment = file;
            }
            console.log(this.resumeFile, this.attachmentFile);
        },
        submitForm() {
            if (!this.fileuploading && this.resume == '') return this.$message(this.alldata.tips.upload.resume);
            if (this.fileuploading) return this.$message(this.alldata.tips.upload.fileuploading);
            this.$refs.infoForm.validate((valid) => {
                if (valid) {
                    //表单验证通过
                    let userId = JSON.parse(sessionStorage.getItem('userinfo')).id;
                    this.$http.post('commons/job/application', {
                        language: this.alldata.nav.current.id,
                        official_list_table_id: this.$route.query.type,
                        user_id: userId,
                        name: this.infos.name,
                        phone: this.infos.phone,
                        email: this.infos.email,
                        resume: this.resumeFile,
                        attachment: this.attachmentFile
                    }).then(res => {
                        const { data } = res;
                        console.log(data);
                        if (data.code == 1) {
                            this.$message.success(this.alldata.tips.upload.success);
                            this.goBack(false);
                        } else {
                            this.$message.error(data.msg);
                        }
                    })
                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.load_box {
    background-color: var(--bg-color-lighter);
    padding: 5vw 18.75vw;

    .loadForm {
        background-color: var(--bg-color-base);
        padding: 2.5vw;

        .loadInfo {

            .title {
                display: flex;
                align-items: center;
                padding-bottom: 1vw;
                border-bottom: 1px solid #E8E8E8;
                margin-bottom: 1.5625vw;

                .block {
                    width: 0.625vw;
                    height: 1vw;
                    background-color: var(--bg-color-origin);
                    margin-right: 0.3vw;
                }

                .text {
                    color: var(--text-color);
                    font-size: var(--font-size-lg);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }
        }

        .loadfile {
            margin-top: 3.125vw;

            &>.title {
                display: flex;
                align-items: center;
                padding-bottom: 1vw;
                border-bottom: 1px solid #E8E8E8;
                margin-bottom: 1.5625vw;

                .block {
                    width: 0.625vw;
                    height: 1vw;
                    background-color: var(--bg-color-origin);
                    margin-right: 0.3vw;
                }

                .text {
                    color: var(--text-color);
                    font-size: var(--font-size-lg);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);
                }
            }

            .customform {

                .item {
                    &:not(:last-child) {
                        margin-bottom: 1.8vw;
                    }

                    .title {
                        margin-bottom: 0.5vw;
                        color: var(--color-disabled);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);

                        .requirement {
                            margin-left: 0.3vw;
                            color: #E53C3C;
                        }
                    }

                    .btn {
                        margin-bottom: 0.5vw;
                        display: flex;
                        align-items: center;


                        button {
                            width: 17.1875vw;
                            height: 2.5vw;
                            background-color: #F0F0F0;
                            color: var(--color-disabled);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .filename {
                            margin-left: 0.5vw;
                            color: var(--text-color);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }

                    .tip {
                        color: var(--color-disabled);
                        font-size: var(--font-size-ens);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);
                    }
                }
            }
        }

        &>.btn {
            margin-top: 2.5vw;

            &>button {
                width: 10.5vw;
                height: 2.5vw;
                border-radius: 1.25vw;
                background-color: var(--color-success);
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }
}

.demo-ruleForm {
    display: flex;
    justify-content: space-between;

    .el-form-item {
        width: 17.1875vw;
        // height: 2.5vw;
        display: flex;
        flex-direction: column;
        margin-bottom: 1px;
    }
}

::v-deep .el-form-item__label {
    text-align: left;
    padding-right: 0;
    color: var(--color-disabled);
    font-size: var(--font-size-sm);
    font-family: var(--font-family);
    font-weight: var(--font-weight-s);
}

::v-deep .el-input__inner {
    // color: var(--color-disabled);
    font-size: var(--font-size-sm);
    font-family: var(--font-family);
    font-weight: var(--font-weight-s);
}
</style>
<template>
    <div>
        <Banner :bannerinfo="alldata.home.banner" @consult="gotopage('/consult')"></Banner>
		<div class="describe">
			<div class="item" v-for="(item, index) in alldata.home.describe" :key="index">
				<div class="figure">
					<img :src="item.image" alt="figure">
				</div>
				<div class="explain">
					<div class="title" v-text="item.title"></div>
					<div class="content" v-text="item.content"></div>
				</div>
			</div>
		</div>
		<div class="power">
			<div class="official" v-text="alldata.home.power.otitle"></div>
			<div class="subheading" v-text="alldata.home.power.stitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.home.power.result" :key="index">
					<div class="figure">
						<img :src="item.image" alt="figure">
					</div>
					<div class="explain">
						<div class="title" v-text="item.title"></div>
						<div class="content" v-text="item.content"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="service">
			<div class="official" v-text="alldata.home.service.otitle"></div>
			<div class="subheading" v-text="alldata.home.service.stitle"></div>
			<div class="addverse">
				<div class="summarize">
					<div class="item" v-for="(item, index) in alldata.home.service.describe.summarize" :key="index">
						{{ item }}
					</div>
				</div>
				<div class="advert">
					<div class="top" v-text="alldata.home.service.describe.advert.top"></div>
					<div class="bottom" v-text="alldata.home.service.describe.advert.bottom"></div>
				</div>
			</div>
			<div class="result">
				<div class="title" v-text="alldata.home.service.result.title"></div>
				<Waterfall :info="arrage"></Waterfall>
				<div class="btn">
					<button @click="gotopage('/consult')" style="background-color: #F8A506">
						<span>{{ alldata.home.service.btn.text }}</span>
						<img :src="alldata.home.service.btn.image" alt="pointer">
					</button>
				</div>
			</div>
		</div>
		<div class="payment">
			<div class="addverse">
				<div class="title" v-text="alldata.home.payment.title"></div>
				<div class="result">
					<div class="item" v-for="(item, index) in alldata.home.payment.content" :key="index"
						:style="{ 'background-image': 'url(' + item.bg + ')' }">
						<span v-text="item.text"></span>
					</div>
				</div>
				<div class="btn">
					<button @click="gotopage('/consult')" style="background-color: #F8A506">
						<span>{{ alldata.home.payment.btn.text }}</span>
						<img :src="alldata.home.payment.btn.image" alt="pointer">
					</button>
				</div>
			</div>
		</div>
		<Addvertisement :addverse="alldata.home.onestep" @learn="learning"></Addvertisement>
    </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
import Waterfall from '@/components/Common/Waterfall.vue'
import Addvertisement from '@/components/Common/Addvertisement.vue'
export default {
    name: 'Enhome',
    data() {
		return {}
	},
	computed: {
		arrage() {
			let info = this.alldata.home.service.result.content;
			let child;
			let result = [];
			if (info) {
				for (let i = 0; i < 2; i++) {
					child = [];
					for (let j = 0; j < info.length; j++) {
						if (j % 2 == i) {
							child.push(info[j])
						}
					}
					result.push(child);
				}
				console.log(result);
				return result;
			}
		}
	},
	components: {
		Banner,
		Waterfall,
		Addvertisement
	},
	methods: {
		learning(){
			this.gotopage('/consult');
		}
	}
}
</script>

<style lang="scss" scoped>
    .describe {
		// background-color: yellow;
		display: flex;

		.item {
			flex: 1;
			display: flex;
			padding: 2vw 1vw 1.5vw;

			&:nth-child(2n) {
				background-color: var(--bg-color-reverse);
			}

			&:nth-child(2n+1) {
				background-color: var(--bg-color-origin);
			}

			.figure {
				display: flex;
				align-items: center;

				&>img {
					width: 6.4vw;
				}
			}

			.explain {

				.title {
					margin-bottom: 1vw;
					color: var(--text-color-base);
					font-size: var(--font-size-lg);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}

				.content {
					color: var(--text-color-base);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}

	.power {
		overflow: auto;

		.official {
			margin: 4.8vw 0 1vw;
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-hug);
			font-family: var(--font-family);
			font-weight: var(--font-weight-m);
		}

		.subheading {
			margin-bottom: 4vw;
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-sm);
			font-family: var(--font-family);
			font-weight: var(--font-weight-s);
		}

		.result {
			margin: 0 18.4375vw;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.item {
				width: 48%;
				display: flex;

				.figure {
					display: flex;
					align-items: center;

					&>img {
						width: 7.4vw;
					}

				}

				.explain {

					.title {
						margin-bottom: 1vw;
						color: var(--text-color);
						font-size: var(--font-size-lg);
						font-family: var(--font-family);
						font-weight: var(--font-weight-m);
					}

					.content {
						color: var(--text-color-grey);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}
				}
			}
		}
	}

	.service {
		overflow: auto;
		background: url('@/assets/img/home/website-build-icon.png') no-repeat scroll right 96%/auto 50%;

		.official {
			margin: 7vw 0 1vw;
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-hug);
			font-family: var(--font-family);
			font-weight: var(--font-weight-m);
		}

		.subheading {
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-sm);
			font-family: var(--font-family);
			font-weight: var(--font-weight-s);
		}

		.addverse {
			overflow: auto;
			height: 40vw;
			background: url('@/assets/img/home/BLUE-BG-1.png') no-repeat scroll left/auto 100%,
				url('@/assets/img/home/round-BG.png') no-repeat scroll center center/70%;

			.summarize {
				// background-color: pink;
				overflow: auto;
				margin: 0 18.4375vw;
				display: flex;
				justify-content: center;
				margin-top: 5%;

				.item {
					width: 6vw;
					height: 6vw;
					border-radius: 3vw;
					line-height: 6vw;
					text-align: center;
					color: var(--text-color-base);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);

					&:first-child {
						background-color: var(--color-pass);
					}

					&:nth-child(2) {
						margin: 0 15%;
						background-color: var(--color-success);
					}

					&:last-child {
						background-color: var(--color-way);
					}

					&:not(:nth-child(2)) {
						margin-top: 6%;
					}
				}
			}

			.advert {
				// background-color: skyblue;
				margin: 0 18.4375vw;
				padding: 1.875vw 0;
				display: flex;
				flex-direction: column;
				align-items: center;

				.top {
					color: var(--text-color-inverse);
					font-size: var(--font-size-sup);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}

				.bottom {
					color: var(--text-color);
					font-size: var(--font-size-enlg);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}
			}
		}

		.result {
			margin: 0 18.4375vw;
			width: 30.7vw;
			// background-color: pink;

			.title {
				// background-color: yellow;
				width: 85%;
				margin-bottom: 2vw;
				color: var(--text-color);
				font-size: var(--font-size-hug);
				font-family: var(--font-family);
				font-weight: var(--font-weight-m);
			}

			.btn {
				margin-top: 3.5vw;

				&>button {
					padding: 1vw 2vw;
					border-radius: 30px;
					background-color: var(--bg-color-btn);

					&>span {
						margin-right: 1.25vw;
						color: var(--text-color-base);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}

					& >img{
						width: 3vw;
					}
				}
			}
		}
	}

	.payment {
		margin-top: 0.8vw;
		background: url('@/assets/img/home/payment-function-icon.png') no-repeat scroll left top/auto 100%;
		display: flex;
		justify-content: flex-end;

		.addverse {
			width: 35.625vw;
			margin: 15.625vw 18.4375vw 0;

			.title {
				width: 60%;
				margin-left: 2.5vw;
				margin-bottom: 1.5625vw;
				color: var(--text-color);
				font-size: var(--font-size-hug);
				font-family: var(--font-family);
				font-weight: var(--font-weight-m);
			}

			.result {
				// background-color: yellowgreen;

				.item {
					height: 9vw;
					background: no-repeat scroll;
					background-size: 100%;
					// padding: 0 10%;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					&>span {
						display: inline-block;
						width: 50%;
						// background-color: skyblue;
						padding: 0 8%;
						color: var(--text-color-base);
						font-size: var(--font-size-mid);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}
				}
			}

			.btn {
				margin-left: 2.5vw;
				margin-top: 1vw;
				margin-bottom: 3vw;

				&>button {
					padding: 1vw 2vw;
					border-radius: 30px;
					background-color: var(--bg-color-btn);

					&>span {
						margin-right: 1.25vw;
						color: var(--text-color-base);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}

					& >img{
						width: 3vw;
					}
				}
			}
		}
	}

	.onestep {
		// background-color: pink;
		margin: 6.25vw 18.4375vw 0;

		.official {
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-hug);
			font-family: var(--font-family);
			font-weight: var(--font-weight-m);
		}

		.subheading {
			margin: 1vw 0 3.125vw;
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-sm);
			font-family: var(--font-family);
			font-weight: var(--font-weight-s);
		}

		.result {
			display: flex;
			justify-content: space-between;

			.item {
				flex: 1;
				background-color: #fff;
				border-radius: 1.5625vw;
				box-shadow: 0px 32px 66px 0px rgba(0, 0, 0, 0.1);
				padding: 3.125vw 2vw;

				&:not(:last-child) {
					margin-right: 1vw;
				}

				.figure {
					// background-color: yellow;
					display: flex;
					justify-content: center;

					&>img {
						width: 7.8125vw;
					}
				}

				.explain {
					// background-color: skyblue;

					.title {
						margin: 1vw 0;
						text-align: center;
						color: var(--text-color);
						font-size: var(--font-size-lg);
						font-family: var(--font-family);
						font-weight: var(--font-weight-m);
					}

					.content {
						text-align: center;
						color: var(--text-color-add);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}
				}
			}
		}

		.btn {
				margin-top: 4vw;
				display: flex;
				justify-content: center;

				&>button {
					padding: 1vw 2vw;
					border-radius: 30px;
					background-color: var(--bg-color-btn);

					&>span {
						margin-right: 1.25vw;
						color: var(--text-color-base);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}

					& >img{
						width: 3vw;
					}
				}
			}
	}
</style>
<template>
	<div class="header">
		<div class="nav">
			<div class="logo">
				<img :src="alldata.nav.logo" alt="logo">
			</div>
			<div class="menu">
				<div class="item" v-for="(item, index) in alldata.nav.menu" :key="index">
					<div v-if="item.isOutside" @click="openOut(item)" class="title">
						<span>{{ item.text }}</span>
					</div>
					<router-link v-else :to="item.path">
						<div class="title" :style="{color: item.path===$route.path || item.children.some(aitem => aitem.path ===$route.path)?'var(--text-color-inverse)':' var(--text-color)'}">
							<span>{{ item.text }}</span>
							<span class="iconfont" v-show="item.children.length">&#xe632;</span>
						</div>
						<Session v-show="item.children.length" class="session" :list="item.children"></Session>
					</router-link>
					
				</div>
			</div>
			<div class="info">
				<div class="login" :class="{ operation: token() != null }" @click="goLogin(alldata.nav.login.path)">
					<img :src="alldata.nav.login.image" alt="login">
					<Session class="session" :list="alldata.nav.login.children" @choice="operate"></Session>
				</div>
				<div class="lang">
					<div class="current">
						<img :src="alldata.nav.current.image" alt="flag">
						<span class="text">{{ alldata.nav.current.text }}</span>
						<span class="iconfont">&#xe632;</span>
					</div>
					<Session class="session" :list="alldata.nav.locales" @choice="toggleLang"></Session>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Session from '@/components/Common/Session.vue'
export default {
	name: 'Header',
	data() {
		return {}
	},
	components: {
		Session
	},
	methods: {
		openOut(item){
			console.log(item)
			window.open('https://dingdong.nsd2020.com/index.html#/cardquery?source=kuafu')
		},
		toggleLang(lang) {
			/* console.log(lang);
			this.$i18n.locale = lang; */
			if (this.$i18n.locale == lang) {
				return console.log("无需发生变化");
			} else {
				console.log("语言发生变化");
				//中英文版本页面不对称
				let paths = ['/case','/payment','/appdev','/applet','/news']
				if (paths.includes(this.$route.path)) {
					this.$router.push('/home')
				};
				
				this.$nextTick(() => {
					this.$i18n.locale = lang;
					localStorage.setItem('locale', lang);
				});
			}
			//重启页面
			// if(this.$route.path!=='/home') return this.$router.push('/home');
			window.location.reload();
		},
		goLogin(path) {
			// let token = localStorage.getItem('TOKEN');
			if (this.token()) {
				console.log("用户已登录，点击此处无用。");
			} else {
				this.gotopage(path);
			}
		},
		operate(path) {
			console.log("尝试尝试", path);
			if (path) {
				this.gotopage(path)
			} else {
				console.log("无法跳转");
				this.logout();
			}
		},
		async logout() {
			let token = sessionStorage.getItem('TOKEN');
			const { data: res } = await this.$http.post('user/logout', {
				language: this.alldata.nav.current.id
			}, {
				headers: {
					"token": token,
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			});
			console.log(res);
			if (res.code != 1) return this.$message.error(res.msg);
			//删除本地缓存token
			sessionStorage.removeItem('TOKEN');
			sessionStorage.removeItem('userinfo');
			// 刷新路由，重载页面
			this.$router.go(0);
			//删除vuex存储的用户信息
			// this.$store.state.userinfo = '已清空用户数据';
			this.$message.success(this.alldata.tips.login.exitsuccess);
		}
	}
}
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	// background-color: pink;

	.nav {
		// background-color: skyblue;
		width: 62.5vw;
		margin: 0 auto;
		padding: 1vw 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.logo {
			// background-color:aqua;
			visibility: hidden;

			&>img {
				width: 11.5vw;
			}
		}

		.menu {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			margin-left: 5vw;

			.item {
				margin-right: 2.5vw;
				position: relative;

				.title {
					color: var(--text-color);
					font-size: var(--font-size-base);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);

					.iconfont {
						margin-left: 0.2vw;
						font-size: var(--font-size-ens);
						font-weight: bold;
					}
				}

				&:hover .session {
					display: block;
				}

				.session {
					position: absolute;
					z-index: 3;
					/* top: 180%;
					left: -45%; */
					display: none;
				}

				& span {
					white-space: nowrap;
				}
			}
		}

		.info {
			// background-color:aquamarine;
			display: flex;
			align-items: center;

			.login {
				display: flex;
				// flex-direction: column;
				align-items: center;
				// justify-content: center;
				padding-right: 1.5vw;
				border-right: 2px solid #2E2E2E;
				position: relative;

				&>img {
					width: 1.3vw;
				}

				.session {
					position: absolute;
					z-index: 1;
					top: 100%;
					left: -45%;
					display: none;
				}
			}

			.operation {
				&:hover>.session {
					display: block;
				}
			}


			.lang {
				padding-left: 1vw;
				position: relative;

				.current {
					display: flex;
					align-items: center;
					color: var(--text-color);
					font-size: var(--font-size-base);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);

					&>img {
						margin-right: 0.2vw;
						width: 1.25vw;
					}

					.iconfont {
						margin-left: 0.2vw;
						font-size: var(--font-size-ens);
						font-weight: bold;
					}
				}

				.session {
					position: absolute;
					z-index: 1;
					/* top: 180%;
					left: -30%; */
					display: none;
				}

				&:hover>.session {
					display: block;
				}

			}
		}
	}
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		userinfo: 'test'
	},
	getters: {
	},
	mutations: {
		changeuserinfo(state,newval){
			state.userinfo = newval;
		}
	},
	actions: {
	},
	modules: {
	}
})

<template>
  <div>
    <div class="news_container">
      <div class="news_recommend">
        <div class="box">
          <div class="carousel_wrapper">
            <el-carousel
              :autoplay="false"
              trigger="click"
              height="29vw"
              @change="
                (index) => {
                  handleChange(index, 'card')
                }
              "
            >
              <el-carousel-item
                v-for="(item, index) in selected[0].content"
                :key="index"
              >
                <div class="img">
                  <!-- <img src="../../assets/img/test.png"> -->
                  <img
                    :src="item.image"
                    alt="carsoul"
                    style="width: 100%"
                  />
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="content">
              <div class="page-box">
                <div class="page">
                  <span v-text="cardIndex"></span>
                  <span>/{{ selected[0].content.length }}</span>
                </div>
                <div
                  class="title"
                  v-text="cardText"
                ></div>
              </div>
              <div class="more">
                <button @click="gotopage(`/news?category=35&id=${id}`)">
                  更多>>
                </button>
              </div>
            </div>
          </div>
          <div class="new_list">
            <div
              class="list_item"
              v-for="(item, index) in selected[1].content"
              :key="index"
              @click="
                gotopage(
                  `/news?category=${item.official_category_id}&id=${item.id}`
                )
              "
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="news_classify">
        <div
          class="classify_item"
          v-for="(item, index) in news"
          :key="index"
        >
          <div class="classify_title">
            <div
              class="text"
              v-text="item.name"
            ></div>
            <div class="line"></div>
            <div
              class="link"
              @click="gotopage({ path: '/article', query: { id: item.id } })"
            >
              更多>>
            </div>
          </div>
          <div class="list">
            <ul>
              <li
                class="list_item"
                v-for="(aitem, aindex) in item.content"
                :key="aindex"
                @click="
                  gotopage(
                    `/news?category=${aitem.official_category_id}&id=${aitem.id}`
                  )
                "
              >
                <span class="disc"></span>
                <span>{{ aitem.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="describe_container">
      <div class="about_wrapper">
        <div class="title">
          <img
            src="@/assets/img/home/biaotilan1.png"
            alt="title"
          />
        </div>
        <div class="introduce">
          <div class="company_img">
            <img
              src="@/assets/img/home/company1.png"
              alt="company"
            />
          </div>
          <div class="company_info">
            <div class="info">
              <div class="company_title">
                <span v-text="alldata.home.about.content.firsttitle"></span>
                <span class="dot"></span>
                <span v-text="alldata.home.about.content.lasttitle"></span>
              </div>
              <div
                class="company_text"
                v-text="alldata.home.about.content.content"
              ></div>
            </div>
            <div class="figure">
              <img
                src="@/assets/img/home/company2.png"
                alt="figure1"
              />
              <img
                src="@/assets/img/home/company3.png"
                alt="figure2"
              />
            </div>
          </div>
        </div>
        <div class="specific">
          <div
            class="specific_item"
            v-for="(item, index) in alldata.home.about.features"
            :key="index"
          >
            <div class="keynote">
              <div class="figure">
                <img
                  :src="item.image"
                  alt="figure"
                />
              </div>
              <div
                class="key_title"
                v-text="item.title"
              ></div>
              <div
                class="key_text"
                v-html="item.text"
              ></div>
              <div class="line"></div>
            </div>
            <div class="points">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="core_wrapper">
        <div class="title">
          <img
            src="@/assets/img/home/biaotilan2.png"
            alt="title"
          />
        </div>
        <div class="bus">
          <div
            class="bus_item"
            v-for="(item, index) in alldata.home.core.intro"
            :key="index"
            @click="gotopage(item.path)"
          >
            <div class="figure">
              <img
                :src="item.image"
                alt="figure"
              />
            </div>
            <div class="info">
              <div
                class="title"
                v-text="item.title"
              ></div>
              <div
                class="content"
                v-text="item.text"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="case_wrapper">
        <div class="title">
          <img
            src="@/assets/img/home/biaotilan3.png"
            alt="title"
          />
        </div>
        <div class="content">
          <div class="case_nav">
            <div
              class="nav_item"
              @click="togglecar(index, item.id)"
              :class="{ navActive: carsIndex === index }"
              v-for="(item, index) in alldata.home.success.navs"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="cars">
            <Swipers
              :cateId="cateId"
              :swiperList="banner"
            ></Swipers>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swipers from '@/components/Common/Swipers.vue'
export default {
  name: 'Zhhome',
  data() {
    return {
      cardIndex: 1,
      cardText: '',
      selected: [
        {
          name: '轮播新闻',
          content: []
        },
        {
          name: '精选新闻',
          content: []
        }
      ],
      news: [
        {
          name: '科技先锋',
          content: [],
          id: 8
        },
        {
          name: '国内资讯',
          content: [],
          id: 7
        },
        {
          name: '国际观察',
          content: [],
          id: 19
        }
      ],
      carsIndex: 0,
      carsouls: [],
      id: '',
      cateId: 30,
      banner: []
    }
  },
  components: {
    Swipers
  },
  mounted() {
    this.getPreviousData()
  },
  methods: {
    async getPreviousData() {
      //头条新闻
      this.$http
        .post('commons/Journalism/index', {
          language: this.alldata.nav.current.id,
          category: 35,
          orderby: 'createtime'
        })
        .then((res) => {
          const { data } = res
          if (data.code !== 1) return this.$message.error(data.msg)
          this.selected[0].content = data.data.data.slice(0, 7)
          this.cardText = this.selected[0].content[0].title
          this.id = this.selected[0].content[0].id
        })

      // 精选
      this.$http
        .post('commons/Journalism/jxindex', {
          language: this.alldata.nav.current.id
        })
        .then((res) => {
          const { data } = res

          if (data.code !== 1) return this.$message.error(data.msg)
          const specialList = data.data.special
          const commonList = data.data.ordinary
          this.selected[1].content = [
            specialList[0],
            ...commonList.slice(0, 3),
            specialList[1],
            ...commonList.slice(3)
          ]
        })
      //新闻分类目录
      let categories = [8, 7, 19]
      categories.forEach((item, index) => {
        this.$http
          .post('commons/Journalism/index', {
            language: this.alldata.nav.current.id,
            orderby: 'createtime',

            order: 'desc',
            category: item
          })
          .then((res) => {
            const { data } = res
            if (data.code !== 1) return this.$message.error(data.msg)
            this.news[index].content = data.data.data.slice(0, 4)
          })
      })
    },

    handleChange(index, type) {
      if (type === 'card') {
        this.cardIndex = index + 1
        this.cardText = this.selected[0].content[index].title
        this.id = this.selected[0].content[index].id
      }
    },
    async togglecar(index, id) {
      this.carsouls = []
      this.carsIndex = index
      if (this.cateId !== id) {
        this.cateId = id
        // this.getCar(this.cateId);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.16) !important;
}

::v-deep .el-carousel__indicators--horizontal {
  display: none;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.news_container {
  background: url('@/assets/img/home/haibao.png') no-repeat scroll top
    center/100% auto;
  padding-top: 27.5vw;

  .news_recommend {
    // background-color: red;
    display: flex;
    justify-content: center;
    margin-bottom: 3.4vw;

    .box {
      background-color: var(--bg-color-base);
      box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: center;

      .carousel_wrapper {
        width: 43vw;
        position: relative;

        .img {
          & > img {
            width: 100%;
            //解决图片与父元素间的间距
            vertical-align: bottom;
          }
        }

        .content {
          box-sizing: border-box;
          padding: 1vw 1.5vw;
          background: rgba(0, 0, 0, 0.4);
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          position: absolute;
          bottom: 0;
          z-index: 10;

          color: var(--text-color-base);
          font-size: var(--font-size-lg);
          font-family: var(--font-family-zh);
          font-weight: var(--font-weight-s);

          .page-box {
            flex: 1;
            display: flex;
            overflow: hidden;
            align-items: center;
            padding-right: 12px;

            .title {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .page {
            & > span:first-child {
              font-size: var(--font-size-h);
              font-weight: var(--font-weight-m);
            }

            margin-right: 1vw;
          }

          .title {
            position: relative;
            top: 0.2vw;
          }

          .more {
            flex-shrink: 0;

            & > button {
              background-color: transparent;
              padding: 0.2vw 0.5vw;
              border: 1px solid var(--bg-color-base);
              color: var(--text-color-base);
              font-size: var(--font-size-base);
              font-family: var(--font-family-zh);
              font-weight: var(--font-weight-s);
            }
          }
        }
      }

      .new_list {
        width: 26vw;
        padding: 1.3vw 1.6vw;

        .list_item {
          cursor: pointer;
          display: inline-block;
          white-space: nowrap;
          //使文字在一行显示，多余省略号
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--text-color-news);
          font-size: var(--font-size-base);
          font-family: var(--font-family-zh);
          font-weight: var(--font-weight-s);

          &:hover {
            text-decoration: underline;
          }

          &:not(:last-child) {
            margin-bottom: 1vw;
          }

          &:first-child {
            // margin-bottom: 1.5vw;
            color: var(--text-color);
            font-size: var(--font-size-l);
            font-weight: var(--font-weight-sm);
          }

          &:nth-of-type(5) {
            box-sizing: border-box;
            background: url('@/assets/img/home/zhongdianxinwen.png') no-repeat
              scroll center top/100% auto;
            padding: 1vw 3vw;
            margin: 1vw 0;
            // margin-bottom: 1.5625vw;
            text-align: center;
            color: gold;
            font-size: var(--font-size-mid);
            font-weight: 600;
            //字体倾斜
            font-style: oblique;
          }
        }
      }
    }
  }

  .news_classify {
    width: 73vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .classify_item {
      // flex: 1;
      width: 33.33%;

      &:not(:last-child) {
        margin-right: 2vw;
      }

      .classify_title {
        display: flex;
        align-items: center;
        margin-bottom: 1.875vw;

        .text {
          // margin-right: 0.5vw;
          color: var(--text-color);
          font-size: var(--font-size-lls);
          font-family: var(--font-family-zh);
          font-weight: var(--font-weight-sm);
        }

        .line {
          width: 8.7vw;
          height: 0.1vw;
          margin: 0 1vw;
          background-color: var(--bg-color-red);
        }

        .link {
          font-size: 14px;
          font-family: MiSans;
          font-weight: 500;
          color: #999999;
          line-height: 32px;
          //   margin-left: .9vw;
          cursor: pointer;
        }
      }

      .list {
        & > ul {
          .list_item {
            cursor: pointer;
            // display: flex;
            // align-items: center;
            white-space: nowrap;
            //使文字在一行显示，多余省略号
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--text-color-news);
            font-size: var(--font-size-sm);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-s);

            &:not(:last-child) {
              margin-bottom: 0.9vw;
            }

            &:hover {
              text-decoration: underline;
            }

            .disc {
              display: inline-block;
              width: 0.3125vw;
              height: 0.3125vw;
              background-color: var(--bg-color-disc);
              border-radius: 50%;
              margin-right: 0.5vw;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.describe_container {
  // background-color: pink;
  width: 73vw;
  margin: 4.6875vw auto 0;

  .about_wrapper {
    .title {
      margin-bottom: 1vw;

      & > img {
        width: 100%;
      }
    }

    .introduce {
      display: flex;

      .company_img {
        width: 50%;
        margin-right: 1.5625vw;

        & > img {
          width: 100%;
          vertical-align: top;
        }
      }

      .company_info {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info {
          width: 100%;
          text-align: center;

          .company_title {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: var(--font-size-x);
            color: var(--text-color-inverse);
            font-size: var(--font-size-hug);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);

            .dot {
              display: inline-block;
              width: 0.3125vw;
              height: 0.3125vw;
              background-color: var(--bg-color-origin);
              border-radius: 50%;
              margin: 0 1.5vw;
            }
          }

          .company_text {
            line-height: var(--font-size-hug);
            color: var(--text-color-news);
            font-size: var(--font-size-sm);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }
        }

        .figure {
          display: flex;
          justify-content: space-between;

          & > img {
            width: 48%;
          }
        }
      }
    }

    .specific {
      margin-top: 2.96875vw;
      display: flex;
      justify-content: space-between;

      .specific_item {
        width: 21.5%;
        background-color: var(--bg-color-origin);
        padding: 2vw 1.2vw;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .keynote {
          .figure {
            width: 32%;
            margin-bottom: 0.78125vw;

            & > img {
              width: 100%;
            }
          }

          .key_title {
            color: var(--text-color-base);
            font-size: var(--font-size-hug);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }

          .key_text {
            margin: 1.5625vw 0;
            line-height: var(--font-size-hug);
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }

          .line {
            width: 1.25vw;
            height: 0.1vw;
            background-color: var(--bg-color-base);
          }
        }

        .points {
          height: 1.3vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > span {
            display: inline-block;
            width: 0.25vw;
            height: 0.25vw;
            // background-color: var(--bg-color-origin);
            background-color: #fff;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .core_wrapper {
    margin-top: 3.4375vw;

    .title {
      margin-bottom: 1vw;

      & > img {
        width: 100%;
      }
    }

    .bus {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .bus_item {
        width: 48%;
        cursor: pointer;

        .figure {
          & > img {
            width: 100%;
            vertical-align: top;
          }
        }

        .info {
          margin: 1.5625vw 0 3.125vw;
          display: flex;

          .title {
            flex: 1;
            margin: 0;
            color: var(--text-color-inverse);
            font-size: var(--font-size-hug);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-sm);
          }

          .content {
            flex: 2;
            color: var(--text-color-news);
            font-size: var(--font-size-sm);
            font-family: var(--font-family-zh);
            font-weight: var(--font-weight-s);
          }
        }
      }
    }
  }

  .case_wrapper {
    margin-bottom: 3.5vw;

    .title {
      margin-bottom: 1vw;

      & > img {
        width: 100%;
      }
    }

    .content {
      .case_nav {
        display: flex;
        justify-content: space-between;
        margin: 0 8.3vw;

        .nav_item {
          cursor: pointer;
          background-color: var(--bg-color-big);
          padding: 0.625vw 2.4vw;
          color: var(--text-color);
          font-size: var(--font-size-lls);
          font-family: var(--font-family-zh);
          font-weight: var(--font-weight-sm);
        }

        .navActive {
          background-color: var(--bg-color-origin);
          color: var(--text-color-base);
        }
      }

      .cars {
        margin: 3.125vw 0 0;
        // background-color: pink;

        .carousel {
          display: flex;
          justify-content: space-between;

          .carousel_item {
            width: 10%;
            // background-color: skyblue;
            border-right: 1px solid;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>

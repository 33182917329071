<template>
    <div class="box">
        <div class="wrapper">
            <div class="triggle"></div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" @click.stop="choice(item.path)">
                <div class="common" v-if="item.image">
                    <img :src="item.image" alt="flag">
                    <span>{{ item.text }}</span>
                </div>
                <div class="special" v-else>
                    <router-link :to="item.path">
                        <span :style="{color: item.path===$route.path?'var(--text-color-inverse)':' var(--text-color)'}">{{ item.text}}</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Session',
    data() {
        return {}
    },
    props: ['list'],
    methods: {
        choice(path) {
            this.$emit('choice', path);
        }
    }
}
</script>

<style lang="scss" scoped> 
.box {
    .wrapper {
        height: 5vh;
        overflow: auto;
        display: flex;

        .triggle {
            margin-left: 1.5vw;
            display: inline-block;
            border: 0.4vw solid skyblue;
            border-top: none;
            border-color: transparent transparent var(--bg-color-base);
        }
    }

    .list{
        background-color: var(--bg-color-base);
        box-sizing: border-box;
        padding: 0 1.5vw;
        overflow: auto;
        box-shadow: 0px 0px 2px rgb(232,232,232);

        .item{
            margin: 0.8vw 0;

            .common{
                cursor: pointer;
                display: flex;
                align-items: center;
                white-space: nowrap;

                & > img{
                    width: 1vw;
                }

                & > span{
                    white-space: nowrap;
                    color: var(--text-color);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
                }
            }

            .special{

                & span{
                    white-space: nowrap;
                    color: var(--text-color);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
                }
            }
        }
    }
}
</style>
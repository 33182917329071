<template>
    <div class="box">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
                    <img :src="item.image" alt="image">
                </div>
            </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
    name: 'Payment',
    data() {
        return {
            banner: [],
            myswiper: ''
        }
    },
    props: ['cateId'],
    created() {
        this.getCar(this.cateId);
    },
    updated() {
        this.myswiper = new Swiper('.swiper-container', {
            autoplay: true,
            loop: true, // 循环模式选项
            slidesPerView: 5,
            centeredSlides: true,
            // centeredSlidesBounds: true,
            spaceBetween: 20,
            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    },
    methods: {
        getCar(id) {
            this.banner = [];
            this.$http.post('commons/caseshow/index', {
                language: this.alldata.nav.current.id,
                category: id
            }).then(res => {
                const { data } = res;
                this.banner = data.data.data;
                console.log(id, this.banner);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .swiper-button-next:after,
.swiper-button-prev:after {
    color: var(--color-disabled);
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-m);
}

.box {
    position: relative;

    .swiper-container {
        width: 90%;

        .swiper-wrapper {

            .swiper-slide {
                width: 30%;

                img {
                    width: 100%;
                    vertical-align: bottom;
                }
            }
        }
    }
}
</style>
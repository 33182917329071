<template>
    <div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in banner" :key="index">
                    <div class="top">
                        <img :src="item.image" />
                    </div>
                    <div class="bottom">
                        <div class="title">{{ item.title }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </div>
            </div>

            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev">
                <img src="@/assets/img/appdev/zuojiantou.png" />
            </div>
            <div class="swiper-button-next">
                <img src="@/assets/img/appdev/youjiantou.png" />
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
export default {
    name: 'Onebyone',
    data() {
        return {
            myswiper
        }
    },
    props: {
        banner: {
            type: Array,
            default: () => { }
        }
    },
    mounted() {
        this.myswiper = new Swiper('.swiper-container', {
            autoplay: true,
            loop: true, // 循环模式选项
            slidesPerView: 3,
            centeredSlides: true,
            // spaceBetween: 30,

            // 如果需要前进后退按钮
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        })
    },
    updated() {

    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.swiper-container {
    width: 71vw;
    height: 36.82vw;
    padding: 0 3.6vw;
    // background-color: pink;

    .swiper-wrapper{

        .swiper-slide{
            // width: 30%;
            // background-color: yellow;

            .top{
                text-align: center;

                &>img{
                    width: 16vw;
                }
            }

            .bottom{
                text-align: center;

                .title{
                    margin: 1vw 0 0.7vw;
                    color: var(--text-color);
                    font-size: var(--font-size-lg);
                    font-weight: var(--font-weight-sm);
                    font-family: var(--font-family-zh);
                }

                .text{
                    color: var(--color-disabled);
                    font-size: var(--font-size-ens);
                    font-weight: var(--font-weight-sm);
                    font-family: var(--font-family-zh);
                }
            }
        }
    }

    .swiper-button-prev,.swiper-button-next{

        &::after{
            display: none;
        }

        &>img{
            width: 2.7vw;
        }
    }
    .swiper-button-prev{
        left: 0.65vw;
    }
    .swiper-button-next{
        right: 0.65vw;
    }
}
</style>
<template>
    <div>
        <div class="swiper s1" v-if="cateId === 30">
            <Payment :cateId="cateId"></Payment>
        </div>
        <div class="swiper s2" v-if="cateId === 31">
            <Independence :cateId="cateId"></Independence>
        </div>
        <div class="swiper s3" v-if="cateId === 32">
            <Appdev :cateId="cateId"></Appdev>
        </div>
        <div class="swiper s4" v-if="cateId === 33">
            <Applet :cateId="cateId"></Applet>
        </div>
    </div>
</template>

<script>
import Payment from '@/components/Swipers/Payment.vue';
import Independence from '@/components/Swipers/Independence.vue'
import Appdev from '@/components/Swipers/Appdev.vue'
import Applet from '@/components/Swipers/Applet.vue'
export default {
    name: 'Swipers',
    data() {
        return {}
    },
    props: ['cateId'],
    components: {
        Payment,
        Independence,
        Appdev,
        Applet
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
<template>
	<div id="root" :class="{root: color===1}">
		<Header v-if="$route.meta.showheader"></Header>
		<!-- <HeaderLogin v-show="!$route.meta.show"></HeaderLogin> -->
		<!-- <keep-alive>
			<router-view v-if="$route.meta.keepAlive"/>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive"/> -->
		<router-view />
		<Footer v-show="$route.meta.showfooter"></Footer>
		<FooterRecruit v-show="$route.meta.showheader && !$route.meta.showfooter"></FooterRecruit>
	</div>
</template>

<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import HeaderLogin from '@/components/HeaderLogin'
import FooterRecruit from './components/Footer/FooterRecruit.vue'

export default {
	name: 'Layout',
	data() {
		return {
			color: 2
		}
	},
	components: {
		Header,
		Footer,
		HeaderLogin,
		FooterRecruit
	},
	created() {
		this.getPreviousData();
	},
	methods: {
		async getPreviousData(){
			const {data: res} = await this.$http.post('index/siteMainColor',{
				language: this.alldata.nav.current.id
			});
			console.log(res);
			this.color = res.data.site_main_color;
			//1为黑白色，2为彩色
		}
	}
}
</script>

<style scoped>
@import url('@/style/iconfont.css');
@import url('@/style/common.css');

/* 前端滤镜添加 */
.root{
	filter:grayscale(100%);
}

::v-deep html,
body {
	color: red;
}

::v-deep button {
	cursor: pointer;
	border: none;
	outline: none;
	background-color: #fff;
}

::v-deep input {
	/* border: none; */
	outline: none;
}

::v-deep textarea {
	/* border: none; */
	outline: none;
	resize: none;
}

/* number输入框隐藏右边加减数字按钮 */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

::v-deep input[type="number"] {
	appearance: textfield;
}
</style>

<template>
	<div>
		<div class="banner">
			<div class="banner_container">
				<div class="box">
					<div class="official" :class="{otitle: alldata.nav.current.id===2}">{{alldata.brand.banner.otitle}}</div>
					<div class="figure">
						<img src="@/assets/img/brand/brand-profile-line.png" />
					</div>
					<div class="subheading" v-text="alldata.brand.banner.stitle"></div>
				</div>
			</div>
		</div>
		<div class="tech">
			<div class="official" v-text="alldata.brand.tech.otitle"></div>
			<div class="subheading" v-text="alldata.brand.tech.stitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.brand.tech.result">
					<div class="title" v-text="item.title"></div>
					<div class="text" v-text="item.text"></div>
				</div>
			</div>
			<div class="content" v-text="alldata.brand.tech.content"></div>
		</div>
		<div class="world">
			<div class="title" v-text="alldata.brand.world.title"></div>
			<div class="content" v-text="alldata.brand.world.content"></div>
		</div>
		<div class="break">
			<div class="title" :class="{offic: alldata.nav.current.id===2}" v-text="alldata.brand.break.otitle"></div>
			<div class="content" v-text="alldata.brand.break.stitle"></div>
			<div class="figure">
				<img :src="alldata.brand.break.image" alt="figure">
			</div>
		</div>
		<!-- <img :src="$t('tabBarImg').about" /> -->
	</div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
export default {
	name: 'Brand',
	components: {
		Banner
	}
}
</script>

<style scoped lang="scss">
.banner {
	height: 45.83vw;
	background: url('@/assets/img/brand/brand-profile-bg.png') no-repeat scroll center top/100% auto;
	// padding: 10.5vw 11.5vw;

	.banner_container {
		width: 50%;
		height: 100%;
		// background-color: yellow;
		display: flex;
		justify-content: center;
		align-items: center;

		.box {
			// background-color: pink;

			.official {
				width: 35vw;
				line-height: var(--font-size-sl);
				color: var(--text-color-base);
				font-size: var(--font-size-sl);
				font-family: var(--font-family);
				font-weight: var(--font-weight-m);
			}

			.otitle{
				width: 20vw;
			}

			.figure {
				margin: 2.5vw 0 2vw;

				&>img {
					width: 15vw;
				}
			}

			.subheading {
				width: 15vw;
				color: var(--text-color-base);
				font-size: var(--font-size-lg);
				font-family: var(--font-family);
				font-weight: var(--font-weight-m);
			}
		}
	}
}

.tech {
	margin-top: 3.125vw;

	.official {
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.subheading {
		margin: 1.5vw 0 3.5vw;
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-sm);
		font-family: var(--font-family);
		font-weight: var(--font-weight-s);
	}

	.result {
		margin: 0 18.4375vw;
		display: flex;
		justify-content: center;

		.item {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;

			&:not(:last-child) {
				margin-right: 0.5vw;
			}

			.title {
				margin-bottom: 1.25vw;
				color: var(--text-color-inverse);
				font-size: var(--font-size-xxl);
				font-family: var(--font-family);
				font-weight: var(--font-weight-m);
			}

			.text {
				text-align: center;
				color: var(--text-color);
				font-size: var(--font-size-sm);
				font-family: var(--font-family);
				font-weight: var(--font-weight-s);
			}
		}
	}

	.content {
		margin: 3.5vw 20vw 0;
		text-align: center;
		color: var(--color-disabled);
		font-size: var(--font-size-ens);
		font-family: var(--font-family);
		font-weight: var(--font-weight-s);
	}
}

.world {
	height: 60.9375vw;
	background: url('@/assets/img/brand/map-market-bg.png') no-repeat scroll center center/100% auto;
	box-sizing: border-box;
	padding-top: 19vw;

	.title {
		margin-bottom: 1.5vw;
		text-align: center;
		color: var(--text-color-base);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.content {
		width: 46vw;
		margin: 0 auto;
		text-align: center;
		color: var(--text-color-base);
		font-size: var(--font-size-sm);
		font-family: var(--font-family);
		font-weight: var(--font-weight-s);
	}
}

.break {
	margin-top: 5.625vw;

	.title {
		width: 50vw;
		margin: 0 auto;
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.offic{
		width: 18vw;
	}

	.content {
		width: 53vw;
		margin: 1.5vw auto 4vw;
		line-height: var(--font-size-xl);
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-sm);
		font-family: var(--font-family);
		font-weight: var(--font-weight-s);
	}

	.figure {
		text-align: center;

		&>img {
			width: 44vw;
		}
	}
}
</style>

<template>
    <div>
        <div class="wrapper">
            <div class="main">
                <div class="center">
                    <div class="logo">
                        <img :src="alldata.nav.logo" alt="logo">
                    </div>
                    <div class="card">
                        <!-- <div class="back" @click="goBack(alldata.login.btn.back.tohome)">
                            <img :src="alldata.login.btn.back.icon" alt="icon">
                            <span v-text="alldata.login.btn.back.text"></span>
                        </div> -->
                        <!-- <div class="navtab">
                            <div class="item" v-for="(item, index) in alldata.login.type" :key="index">
                                <div class="text" v-text="item.title" @click="toggleform(index)"></div>
                                <div class="line" :class="{ lineActive: index == currentform }"></div>
                            </div>
                        </div> -->
                        <div class="title">
                            <div class="item" v-for="(item, index) in alldata.register.type" :key="index">
                                <div class="left" v-text="item.title"></div>
                                <div class="right">
                                    <span class="text" v-text="item.run.text"></span>
                                    <span class="fun" v-text="item.run.to.text"
                                        @click="gotopage(item.run.to.path)"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form">
                            <div class="item" v-for="(item, index) in alldata.register.type[0].placeholder"
                                :key="index">
                                <input :type="item.type" :maxlength="item.maxlength" :placeholder="item.text"
                                    v-model="inputlist[index].text" />
                                <button v-show="item.code" @click="checksend" :disabled="time !== 60">{{ time === 60 ?
                                    alldata.login.btn.code.success : alldata.login.btn.code.progress.left
                                    + time + alldata.login.btn.code.progress.right
                                }}</button>
                                <!-- <el-button v-show="item.code" :plain="true" @click="checksend">{{alldata.login.btn.code.success}}</el-button> -->
                            </div>
                        </div>
                        <div class="submitbtn">
                            <button v-text="alldata.register.btn.ok" @click="submituser"></button>
                        </div>
                        <div class="agreement" @click="negate">
                            <div class="check">
                                <img src="../../assets/img/icon/checked-icon.png" alt="check" v-show="checked">
                            </div>
                            <div class="text">{{ alldata.register.agreement.text }}
                                "<span @click.stop="gotopage('/agreement')">{{
                                alldata.register.agreement.to.text}}</span>"</div>
                        </div>
                        <!-- <div class="other">
                            <div class="item" v-for="(item, index) in alldata.login.btn.to" :key="index" v-text="item.text" @click="gotopage(item.path)"></div>
                        </div>
                        <div class="third">
                            <div class="title">
                                <div class="line"></div>
                                <div class="text" v-text="alldata.login.third.title"></div>
                                <div class="line"></div>
                            </div>
                            <div class="content">
                                <div class="item" v-for="(item, index) in alldata.login.third.part" :key="index">
                                    <div class="figure">
                                        <img :src="item.image" alt="figure">
                                    </div>
                                    <div class="text" v-text="item.text"></div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Form from '@/components/Form/Form.vue'
export default {
    name: 'Register',
    data() {
        return {
            inputlist: [
                {
                    text: ''
                },
                {
                    text: ''
                },
                {
                    text: ''
                }
            ],
            checked: false,
            event: 'register',
            time: 60,
            timer: ''
        }
    },
    components: {
        // Form
    },
    methods: {
        negate() {
            this.checked = !this.checked;
        },
        async checksend() {
            this.inputlist[0].text = this.inputlist[0].text.trim();
            if (this.inputlist[0].text.trim() == '') {
                this.$message(this.alldata.tips.register.accountempty);
            } else {
                const {data:result} = await this.sendCode(this.inputlist[0].text, this.event);
                console.log(result);
                if(result.code !== 1) return this.$message.error(result.msg);
                this.$message.success(this.alldata.tips.code.success);
                this.time--;
                this.timer = setInterval(() => {
                    this.time--;
                    if (this.time < 0) {
                        //重新初始化倒计时事件
                        this.time = 60;
                        //清除定时器
                        clearInterval(this.timer);
                    };
                }, 1000);
            }
        },
        submituser() {
            if (this.inputlist[0].text.trim() == '') {
                this.$message(this.alldata.tips.register.accountempty);
                this.inputlist[0].text = this.inputlist[0].text.trim();
            } else if (this.inputlist[1].text.trim() == '') {
                this.$message(this.alldata.tips.register.codeempty);
                this.inputlist[1].text = this.inputlist[1].text.trim();
            } else if (this.inputlist[2].text.trim() == '') {
                this.$message(this.alldata.tips.register.passempty);
                this.inputlist[2].text = this.inputlist[2].text.trim();
            } else if (!this.checked) {
                this.$message(this.alldata.tips.register.uncheck);
            } else {
                //各输入框内容合法时，调用注册接口
                console.log(this.sendCode(this.inputlist[0].text));
                let mobile = /@/.test(this.inputlist[0].text) ? '' : this.inputlist[0].text.trim();
                let email = /@/.test(this.inputlist[0].text) ? this.inputlist[0].text.trim() : '';
                let code = this.inputlist[1].text.trim();
                let password = this.inputlist[2].text.trim();
                this.$http.post('user/register', {
                    language: this.alldata.nav.current.id,
                    mobile: mobile,
                    email: email,
                    code: code,
                    password: password
                }).then(res => {
                    console.log(res.data);
                    const { data } = res;
                    if (data.code == 1) {
                        this.$message.success(this.alldata.tips.register.success);
                        setTimeout(() => {
                            this.goBack();
                        }, 1000)
                    } else {
                        this.$message.error(data.msg);
                    }
                }).catch(error => { });
            }
        }
    }
}
</script>

<style scoped lang="scss">
input {
    border: none;
}

.wrapper {
    width: 100%;
    height: 100vh;
    background: url('@/assets/img/login/right-image.png') no-repeat right top/auto 100%;

    .main {
        width: 42.1875%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .center {

            .logo {
                flex: 1;
                margin: 0 0 3.5vw 1.25vw;

                &>img {
                    width: 10.95vw;
                }
            }

            .card {
                // border: 1px solid black;
                padding: 0 2vw 0.5vw;

                .title {

                    .item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 1.5625vw;
                        padding: 1vw 0;
                        border-bottom: 1px solid var(--bg-color-grey);

                        .left {
                            color: var(--text-color);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .right {
                            color: var(--color-disabled);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);

                            .fun {
                                cursor: pointer;
                                color: var(--color-success);
                            }
                        }
                    }
                }

                .form {
                    width: 18.75vw;

                    .item {
                        margin-top: 1.5625vw;
                        position: relative;
                        border-bottom: 1px solid var(--bg-color-grey);
                        // background-color: pink;

                        &>input {
                            width: 100%;
                            padding: 1vw 0;
                            color: var(--text-color);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        &>button {
                            position: absolute;
                            right: 0;
                            top: 1vw;
                            color: var(--color-success);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }

                .submitbtn {
                    margin-top: 1.5625vw;

                    &>button {
                        width: 100%;
                        padding: 1vw 0;
                        background-color: var(--bg-color-origin);
                        color: var(--text-color-base);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-m);
                    }
                }

                .agreement {
                    margin-top: 0.8vw;
                    display: flex;
                    align-items: center;

                    .check {
                        width: 0.7vw;
                        height: 0.7vw;
                        margin-right: 1px;
                        border: 1px solid var(--color-success);
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &>img {
                            width: 0.8vw;
                        }
                    }

                    .text {
                        cursor: pointer;
                        color: var(--color-disabled);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family);
                        font-weight: var(--font-weight-s);

                        &>span {
                            color: var(--color-success);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .wrapper {
        background: none;

        .main {
            width: 100%;
            justify-content: center;
        }
    }
}
</style>
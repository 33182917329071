<template>
	<div class="home">
		<Enhome v-if="alldata.nav.current.text==='English'"></Enhome>
		<Zhhome v-else></Zhhome>
	</div>
</template>

<script>
import Enhome from '@/components/Specified/Enhome.vue'
import Zhhome from '@/components/Specified/Zhhome.vue'
export default {
	name: 'Home',
	components:{
		Enhome,
		Zhhome
	}
}
</script>

<style scoped lang="scss">
.home {
	width: 100%;
	margin: 0 auto;
}
</style>

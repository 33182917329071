<template>
    <div>
        <div class="block">
            <el-carousel class="wrapper" indicator-position="none" arrow="never">
                <el-carousel-item class="item" v-for="(item, index) in banner" :key="index">
                    <img :src="item.image" />
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Computed',
    data() {
        return {
            banner: [
                {
                    image: require('@/assets/img/appdev/web-tu1.png')
                },
                {
                    image: require('@/assets/img/appdev/web-tu2.png')
                },
                {
                    image: require('@/assets/img/appdev/web-tu3.png')
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container{
    height: auto;
}
.block{
    width: 38.69vw;
    height: 23.4375vw;
    background: url('@/assets/img/appdev/bijiben-yangji.png') no-repeat scroll left top/100%;
    position: absolute;
    left: -6.2%;
    top: 15%;

    .wrapper{
        width: 29.53125vw;
        height: 18.4895vw;
        position: absolute;
        left: 11.7%;
        top: 7.5%;

        .item{
            width: 100%;
            height: 18.4895vw;

            & img{
                width: 29.53125vw;
                height: 18.4895vw;
            }
        }
    }
}
/* .block {
    position: absolute;
    top: 23%;
    left: 7%;
    width: 29.53125vw;
    // height: 18.4895vw;
    // height: 18.4vw;
    background-color: pink;

    .wrapper {
        height: 100%;

        .item {
            height: 100%;

            & img {
                width: 100%;
                // height: 18.4895vw;
            }
        }
    }
} */
</style>
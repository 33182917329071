<script setup>
import { ref } from 'vue'

defineProps(['banner'])

const leftSwiper = ref()
const rightSwiper = ref()

const changeSwiper = (position) => {
  if (position === 'prev') {
    leftSwiper.value.prev()
    rightSwiper.value.prev()
    return
  }

  if (position === 'next') {
    leftSwiper.value.next()
    rightSwiper.value.next()
    return
  }

  leftSwiper.value.setActiveItem(position)
  rightSwiper.value.setActiveItem(position)
}

const activeIndex = ref(0)
const changeIndex = (index) => {
  activeIndex.value = index
}
</script>

<template>
  <div class="contain">
    <div class="left">
      <el-carousel
        :autoplay="false"
        indicator-position="none"
        arrow="never"
        ref="leftSwiper"
      >
        <el-carousel-item
          v-for="item in banner"
          :key="item.id"
        >
          <div class="wrap">
            <div
              class="title"
              v-html="item.title"
            />
            <div
              class="content"
              v-html="item.content"
            />
          </div>
        </el-carousel-item>
      </el-carousel>

      <div class="btns">
        <div class="btn prev">
          <img
            src="@/assets/img/independence/zuojiantou.png"
            @click="changeSwiper('prev')"
          />
        </div>
        <div class="btn next">
          <img
            src="@/assets/img/independence/youjiantou.png"
            @click="changeSwiper('next')"
          />
        </div>
      </div>
    </div>

    <div class="right">
      <div class="inner">
        <el-carousel
          ref="rightSwiper"
          :autoplay="false"
          indicator-position="none"
          arrow="never"
          height="30vw"
          @change="changeIndex"
        >
          <el-carousel-item
            v-for="item in banner"
            :key="item.id"
          >
            <img
              :src="item.image"
              :alt="item.category_name"
            />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="btns">
        <div
          class="btn"
          v-for="(item, index) in banner"
          :key="item.id"
          :class="{ active: activeIndex === index }"
          @click="changeSwiper(index)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contain {
  display: flex;
  .left {
    width: 20%;
    margin-right: 20px;
  }

  .right {
    flex: 1;
  }
}

.left {
  position: relative;

  .wrap {
    text-align: left;
    color: #2e2e2e;
    font-family: var(--font-family-zh);
    .title {
      margin-bottom: 0.35vw;
      font-size: 1.75vw;
      font-weight: bold;
    }

    .content {
      font-size: 0.9vw;
      color: #999;
    }
  }
  .btns {
    position: absolute;
    bottom: 4.8vw;
    display: flex;
    z-index: 2;

    img {
      margin-right: 1.1vw;
      width: 2.6vw;
      cursor: pointer;
    }
  }
}

.right {
  position: relative;
  background: url('@/assets/img/independence/bijiben-moxing.png') no-repeat
    scroll center top/100%;

  .inner {
    margin: 0 auto;
    position: relative;
    left: 0.07%;
    top: 5%;
    width: 75.8%;
    img {
      width: 100%;
    }
  }

  .btns {
    position: relative;
    left: -0.4vw;
    z-index: 2;
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 1.1vw;
      width: 0.9375vw;
      height: 0.9375vw;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;

      &.active {
        background-color: #007aff;
      }
    }
  }
}
</style>

<template>
    <div>
        <div class="banner" :class="{zh_banner: alldata.nav.current.id===2}">
            <div class="formcard">
                <div class="left">
                    <div class="title" v-text="alldata.contact.title"></div>
                    <div class="line"></div>
                    <div class="result">
                        <div class="item" v-for="(item, index) in info" :key="index">
                            <div class="figure">
                                <img :src="infoimg[item.id]" alt="figure">
                            </div>
                            <div class="content">
                                <div class="title" v-text="item.title"></div>
                                <div class="text" v-text="item.value"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img :src="alldata.contact.image" alt="map">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    data(){
        return {
            info: [],
            infoimg: {
                '16': require('@/assets/img/contact/telephone-icon.png'),
                '18': require('@/assets/img/contact/email-icon.png'),
                '20': require('@/assets/img/contact/address-icon.png'),
                '17': require('@/assets/img/contact/telephone-icon.png'),
                '19': require('@/assets/img/contact/email-icon.png'),
                '21': require('@/assets/img/contact/address-icon.png')
            }
        }
    },
    mounted(){
        this.getPreviousData();
    },
    methods: {
        getPreviousData(){
            this.info=[];
            this.$http.post('commons/menus/footer',{
                language: this.alldata.nav.current.id
            }).then(res => {
                const {data} = res;
                if(data.code != 1) return this.$message.error(data.msg);
                for(let item of data.data){
                    if(item.id==17||item.id==19||item.id==21||item.id==16||item.id==18||item.id==20){
                        this.info.push(item);
                    }
                }
                console.log(this.info);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.banner {
    padding: 30vw 18.75vw 1vw;
    background: var(--bg-color-diff) url('@/assets/img/contact/contact-us-bg.png') no-repeat scroll center top/100% auto;

    .formcard {
        display: flex;

        .left {
            background-color: var(--bg-color-base);
            flex: 1;
            box-sizing: border-box;
            padding: 1.5vw;

            .title {
                color: var(--text-color);
                font-size: var(--font-size-xl);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .line {
                margin: 1vw 0 2vw;
                width: 1.7vw;
                height: 2px;
                background-color: var(--bg-color-origin);
            }

            .result {

                .item {
                    display: flex;
                    &:not(:last-child){
                        margin-bottom: 2.5vw;
                    }

                    .figure {

                        &>img {
                            width: 2.5vw;
                        }
                    }

                    .content {

                        .title {
                            color: var(--text-color-grey);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }

                        .text {
                            color: var(--text-color);
                            font-size: var(--font-size-mid);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }
            }
        }

        .right {
            flex: 1;
            display: flex;

            &>img {
                width: 100%;
            }
        }
    }
}
.zh_banner{
    background: var(--bg-color-diff) url('@/assets/img/contact/zh-contact-us-bg.png') no-repeat scroll center top/100% auto;
}
</style>
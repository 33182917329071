<template>
    <div>
        <Banner :bannerinfo="alldata.applet.banner"></Banner>
        <div class="block_container">
            <div class="item" v-for="(item, index) in alldata.applet.blocks" :key="index">
                <div class="bg">
                    <img :src="item.image" />
                </div>
                <div class="content">
                    <div class="title" v-text="item.title"></div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
        <div class="cover_container">
            <div class="heading" v-text="alldata.applet.cover.otitle"></div>
            <div class="stitle" v-text="alldata.applet.cover.stitle"></div>
            <div class="btn">
                <button style="background-color: #F8A506" @click="gotopage('/consult')">
                    <span v-text="alldata.applet.cover.btn.text"></span>
                    <img :src="alldata.applet.cover.btn.image" alt="pointer">
                </button>
            </div>
            <div class="blocks">
                <div class="item" v-for="(item, index) in alldata.applet.cover.blocks" :key="index">
                    <div class="figure">
                        <img :src="item.image" />
                    </div>
                    <div class="title" v-text="item.title"></div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
        <div class="describe">
            <div class="item" v-for="(item, index) in alldata.applet.describe" :key="index">
                <div class="figure">
                    <img :src="item.image" />
                </div>
                <div class="content">
                    <div class="total" v-if="index === 0">
                        ———— 项目介绍
                    </div>
                    <div class="title">
                        <span class="box" v-if="index !== 0"></span>
                        <span class="wraper" v-text="item.title"></span>
                    </div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
        <div class="service_container">
            <div class="heading" v-text="alldata.applet.service.otitle"></div>
            <div class="stitle" v-text="alldata.applet.service.stitle"></div>
            <div class="service">
                <div class="item" v-for="(item, index) in alldata.applet.service.result" :key="index">
                    <div class="order" v-text="'0' + (index + 1)"></div>
                    <div class="figure">
                        <img :src="item.image" />
                    </div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Banner from '@/components/Banner/Banner.vue'
export default {
    name: 'Applet',
    components: {
        Banner
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>
  
<style scoped lang="scss">
.block_container {
    padding-top: 2.86vw;
    display: flex;
    justify-content: center;

    .item {
        position: relative;

        &:not(:last-child) {
            margin-right: 2.34375vw;
        }

        .bg {
            display: flex;
            justify-content: center;
            // background-color: pink;

            &>img {
                width: 19.32vw;
                height: 16.71875vw;
            }
        }

        .content {
            padding: 3.22vw 7vw 0 2.34375vw;
            position: absolute;
            top: 0;

            .title {
                margin-bottom: 1vw;
                color: var(--text-color-base);
                font-size: var(--font-size-xl);
                font-family: var(--font-family-zh);
                font-weight: var(--font-weight-sm);
            }

            .text {
                color: var(--text-color-base);
                font-size: var(--font-size-ens);
                font-family: var(--font-family-zh);
                font-weight: var(--font-weight-sm);
            }
        }
    }
}

.cover_container {
    height: 45.5vw;
    margin-top: 0.5vw;
    padding: 12.6vw 0 2.08vw;
    background: url('@/assets/img/applet/hangye-bg.png') no-repeat scroll center top/112% auto;

    .heading {
        margin-bottom: 0.78125vw;
        text-align: center;
        color: var(--text-color-base);
        font-size: var(--font-size-h);
        font-weight: var(--font-weight-m);
        font-family: var(--font-family-zh);
    }

    .stitle {
        text-align: center;
        color: var(--text-color-base);
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-sm);
        font-family: var(--font-family-zh);
    }

    .btn {
        margin-top: 1.5625vw;
        display: flex;
        justify-content: center;

        &>button {
            padding: 1vw 2vw;
            border-radius: 30px;
            background-color: var(--bg-color-btn);

            &>span {
                margin-right: 1.25vw;
                color: var(--text-color-base);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }

            &>img {
                width: 3vw;
            }
        }
    }

    .blocks {
        display: flex;
        justify-content: center;
        margin-top: 3.7vw;

        .item {
            width: 19.79vw;
            // height: 28vw;
            background-color: var(--bg-color-base);
            border-radius: 1.5625vw;
            box-shadow: 0px 32px 56px 0px rgba(0, 0, 0, 0.12);

            &:not(:last-child) {
                margin-right: 1.5625vw;
            }

            .figure {

                &>img {
                    width: 18.22vw;
                    margin: 0 auto;
                }
            }

            .title {
                margin: 0.5vw 0 1.5625vw;
                text-align: center;
                color: var(--text-color-inverse);
                font-size: var(--font-size-hug);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }

            .text {
                margin: 0 2.6vw 3vw;
                text-align: center;
                color: var(--text-color);
                font-size: var(--font-size-ens);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }
        }
    }
}

.describe {
    padding-bottom: 10.52vw;
    background-image: url('@/assets/img/applet/yuanxing-bg.png'),
        url('@/assets/img/applet/yuandian1.png'),
        url('@/assets/img/applet/yuandian2.png'),
        url('@/assets/img/applet/yuandian3.png'),
        url('@/assets/img/applet/yuandian2.png');
    background-repeat: no-repeat;
    background-position: 30% 3%, 90% 15%, left 38%, right 70%, left bottom;
    background-size: 31.25vw auto, 9.21875vw auto, 16.19vw auto, 22.08vw auto, 16.19vw auto;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: 2.6vw;
        }

        .figure {
            margin-right: 7.03125vw;

            &>img {
                width: 24.63vw;
            }
        }

        .content {
            width: 20vw;

            .total {
                margin-bottom: 1vw;
                color: var(--text-color);
                font-size: var(--font-size-sm);
                font-family: var(--font-family-zh);
                font-weight: var(--font-weight-m);
            }

            .title {
                margin-bottom: 1.66vw;
                display: flex;
                align-items: center;

                .box {
                    margin-right: 0.78125vw;
                    display: inline-block;
                    width: 0.625vw;
                    height: 1.875vw;
                    border-radius: 0.3125vw;
                    background-color: var(--text-color-inverse);
                }

                .wraper {
                    color: var(--text-color-inverse);
                    font-size: var(--font-size-hug);
                    font-family: var(--font-family-zh);
                    font-weight: var(--font-weight-m);
                }
            }

            .text {
                color: var(--text-color);
                font-size: var(--font-size-ens);
                font-family: var(--font-family-zh);
                font-weight: var(--font-weight-sm);
            }
        }

        &:nth-of-type(2n) {
            flex-direction: row-reverse;

            .figure {
                margin-right: 0;
                margin-left: 7.03125vw;
            }
        }
    }
}

.service_container {

    .heading {
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-h);
        font-weight: var(--font-weight-m);
        font-family: var(--font-family-zh);
    }

    .stitle {
        margin: 1vw 0 4.375vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-sm);
        font-family: var(--font-family-zh);
    }

    .service {
        display: flex;
        justify-content: center;
        padding: 0vw 0 10.9375vw;
        // background-color:antiquewhite;

        .item {
            width: 6.875vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
            padding: 2.2vw 0vw 1.875vw;
            position: relative;
            border-bottom: 0.1vw solid var(--bg-color-origin);

            &:not(:last-of-type) {
                margin-right: 1vw;
            }

            .order {
                position: absolute;
                right: 0;
                top: 0;
                width: 1.5625vw;
                height: 1.5625vw;
                background-color: var(--bg-color-origin);
                border-radius: 0 0 0 100%;
                display: flex;
                justify-content: flex-end;
                // align-items: center;
                box-sizing: border-box;
                padding: 0.2vw 0.2vw 0 0;
                color: var(--text-color-base);
                font-size: var(--font-size-xs);
                font-weight: var(--font-weight-m);
                font-family: var(--font-family-zh);
            }

            .figure {
                margin-bottom: 1vw;

                &>img {
                    width: 2.29vw;
                }
            }

            .text {
                color: var(--text-color);
                font-size: var(--font-size-base);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }
        }
    }
}
</style>
  
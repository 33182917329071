import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home/home'
import Login from '@/views/Login/login'
import Register from '@/views/Register/register'
import Agreement from '@/views/Register/agreement'
import Reset from '@/views/Reset/reset'
import Product from '@/views/Service/product'
import Payment from '@/views/Service/payment'
import Independence from '@/views/Service/independence'
import Appdev from '@/views/Service/appdev'
import Applet from '@/views/Service/applet'
import Case from '@/views/Service/case'
import Brand from '@/views/About/brand'
import Contact from '@/views/About/contact'
import Talent from '@/views/Recruit/talent'
import Society from '@/views/Recruit/society'
import Campus from '@/views/Recruit/campus'
import Backstage from '@/views/Backstage/backstage'
import Upload from '@/views/Backstage/upload'
import Consult from '@/views/Backstage/consult'
import News from '@/views/Details/news'
import ArticleList from '@/components/article/ArticleList'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '首页',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    meta: {
      title: '产品中心',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      title: '产品中心-聚合支付',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/independence',
    name: 'independence',
    component: Independence,
    meta: {
      title: '产品中心-独立站建设',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/appdev',
    name: 'appdev',
    component: Appdev,
    meta: {
      title: '产品中心-APP开发',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/applet',
    name: 'applet',
    component: Applet,
    meta: {
      title: '产品中心-小程序',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/case',
    name: 'case',
    component: Case,
    meta: {
      title: '产品中心-案例',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/brand',
    name: 'brand',
    component: Brand,
    meta: {
      title: '关于我们-品牌介绍',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: '关于我们-联系我们',
      showheader: true,
      showfooter: true,
      keepAlive: true
    }
  },
  {
    path: '/talent',
    name: 'talent',
    component: Talent,
    meta: {
      title: '人才招聘',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/society',
    name: 'society',
    component: Society,
    meta: {
      title: '人才招聘-社会招聘',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/campus',
    name: 'campus',
    component: Campus,
    meta: {
      title: '人才招聘-校园招聘',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/article',
    name: 'Article',
    component: ArticleList,
    meta: {
      title: '新闻列表',
      showheader: true,
      showfooter: true,
      keepAlive: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
      showheader: false,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      title: '注册',
      showheader: false,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: Agreement,
    meta: {
      title: '用户协议',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      title: '忘记密码',
      showheader: false,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: Backstage,
    meta: {
      title: '后台管理',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload,
    meta: {
      title: '简历提交',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/consult',
    name: 'consult',
    component: Consult,
    meta: {
      title: '咨询',
      showheader: true,
      showfooter: false,
      keepAlive: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {
      title: '新闻详情',
      showheader: true,
      showfooter: false,
      keepAlive: false
    }
  },

  //重定向，当url访问/时，页面可能为空，此时需要将/自动跳转到/Home，即默认访问首页。
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 全局前置路由守卫，防止通过url地址非法访问
// 凡是涉及到next方法，则必须要方形才能执行下一个钩子，否则走不通。
router.beforeEach((to, from, next) => {
  if (to.path != '/backstage' && to.path != '/upload') {
    next()
  } else {
    let token = sessionStorage.getItem('TOKEN')
    if (token) {
      next()
    } else {
      next('/login')
    }
  }
  to.meta.title && (document.title = "夸父-" + to.meta.title)
})

export default router

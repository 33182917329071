<template>
    <div>
        <div class="block">
            <el-carousel class="wrapper" indicator-position="none" arrow="never">
                <el-carousel-item class="item" v-for="(item,index) in banner" :key="index">
                    <img :src="item.image" />
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Phone',
    data() {
        return {
            banner: [
                {
                    image: require('@/assets/img/appdev/APP-tu1.png')
                },
                {
                    image: require('@/assets/img/appdev/APP-tu2.png')
                },
                {
                    image: require('@/assets/img/appdev/APP-tu3.png')
                },
                {
                    image: require('@/assets/img/appdev/APP-tu4.png')
                }
            ],
        }
    }
}
</script>

<style lang="scss" scoped>
.block{
    margin-top: -10%;
    width: 24.635vw;
    height: 41.66vw;

    .wrapper{
        height: 100%;

        .item{
            height: 41.66vw;

            & img{
                width: 100%;
            }
        }
    }
}
</style>
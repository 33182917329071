import { render, staticRenderFns } from "./society.vue?vue&type=template&id=9d8f13f6&scoped=true"
import script from "./society.vue?vue&type=script&lang=js"
export * from "./society.vue?vue&type=script&lang=js"
import style0 from "./society.vue?vue&type=style&index=0&id=9d8f13f6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8f13f6",
  null
  
)

export default component.exports
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './langs/zh'
import en from './langs/en'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(VueI18n);
const messages = {
	zh: {
		...zh,
		...zhLocale
	},
	en: {
		...en,
		...enLocale
	}
}

const i18n = new VueI18n({
	locale: localStorage.getItem('locale') || 'en',
	messages
})

export default i18n

<template>
    <div class="card">
        <div class="official" v-text="info.otitle" :style="{marginBottom: inlinestyle.otitleBottom}"></div>
        <div class="subheading" v-text="info.stitle"></div>
        <div class="content">
            <div class="gather" v-for="(item,index) in info.result" :key="index" :style="{width: inlinestyle.itemWidth,padding: inlinestyle.gatherPad}">
                <Item :vertical="info.style.vertical" :item="item"></Item>
            </div>
        </div>
        <div class="btn" v-if="info.btn.text">
            <button @click="learning">
                <span>{{ addverse.btn.text }}</span>
                <img :src="addverse.btn.image" alt="pointer">
            </button>
        </div>
    </div>
</template>

<script>
import Item from '@/components/Card/Item.vue'
export default {
    name: 'Card',
    props: ['inlinestyle','info'],
    components: {
        Item
    }
}
</script>

<style lang="scss" scoped>
.card{
    

    .official{
        text-align: center;
        color: var();
        font-size: var();
        font-family: var();
        font-weight: var();
    }
    .color-white{
        color: var();
    }

    .subheading{}

    .content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .gather{
            background-color: pink;
            display: flex;
            // padding: 10px;
            box-sizing: border-box;
        }
    }
}
</style>
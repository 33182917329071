const mixin ={
    data(){
        return {
            try: "请显示一下"
        }
    },
    computed:{
        alldata(){
            return this.$i18n.messages[this.$i18n.locale];
        }
    },
    mounted(){
        // window.scrollTo(0,0);
    },
    activated() {},
    methods: {
        token(){
            let token = sessionStorage.getItem('TOKEN');
            return token;
        },
        goBack(type){
            type ? this.$router.push('/home'): this.$router.go(-1);
        },
        gotopage(path){
            this.$router.push(path)
        },
        async sendCode(account,event){
            account = account.trim();
            let result = /@/.test(account);
            console.log("发送验证码",result);
            let test;
            if(event){
                if(result){
                    //电子邮箱验证码
                    test = await this.$http.post('ems/send',{
                        language: this.alldata.nav.current.id,
                        email: account,
                        event: event
                    })/* .then(res=> {
                        const {data} = res;
                        console.log(data);
                        if(data.code == 1){
                            this.$message.success(this.alldata.tips.code.emailsuccess);
                            console.log(countdown);
                        }else{
                            this.$message.error(data.msg);
                        }
                    }).catch(error => {}); */
                }else{
                    //短信验证码
                    test = await this.$http.post('sms/send',{
                        language: this.alldata.nav.current.id,
                        mobile: account,
                        event: event
                    })/* .then(res => {
                        const {data} = res;
                        console.log(data);
                        if(data.code == 1){
                            this.$message.success(this.alldata.tips.code.phonesuccess);
                        }else{
                            this.$message.error(data.msg);
                        }
                    }).catch(error => {}) */
                }
                return test
            }else{
                return result;
            }
        }
    }
}
export default mixin
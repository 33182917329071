<template>
    <div>
        <Banner :bannerinfo="alldata.appdev.banner"></Banner>
        <div class="mix">
            <div class="blocks">
                <div class="item" v-for="(item, index) in alldata.appdev.blocks" :key="index">
                    <div class="figure">
                        <img :src="item.image" />
                    </div>
                    <div class="text" v-text="item.text"></div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="swiper_container">
                <div class="heading" v-text="alldata.appdev.swipers.otitle"></div>
                <div class="stitle" v-text="alldata.appdev.swipers.stitle"></div>
                <!-- <Applet :cateId="32"></Applet> -->
                <Onebyone :banner="banner"></Onebyone>
            </div>
        </div>
        <div class="charge_container">
            <div class="wrapper">
                <div class="total" v-text="alldata.appdev.charge.intro"></div>
                <div class="title" v-text="alldata.appdev.charge.title"></div>
                <div class="text" v-text="alldata.appdev.charge.text"></div>
            </div>
        </div>
        <div class="describe">
            <div class="item" v-for="(item, index) in alldata.appdev.desribe" :key="index">
                <div class="figure" :class="{ special: item.flag }">
                    <!-- <img src="@/assets/img/appdev/bijiben-yangji.png" class="wrapper" v-if="item.flag" /> -->
                    <!-- <img :src="item.image" /> -->
                    <Phone v-if="!item.flag" />
                    <Computed v-if="item.flag" />
                </div>
                <div class="content">
                    <div class="order">
                        <span>{{ index + 1 <= 9 ? '0' + (index + 1) : index + 1 }}</span>
                                <span>/{{
                                    alldata.appdev.desribe.length <= 9 ? '0' + alldata.appdev.desribe.length :
                                        alldata.appdev.desribe.length
                                }}</span>
                                        <span class="line"></span>
                    </div>
                    <div class="title" v-text="item.title"></div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
        <div class="service_container">
            <div class="heading" v-text="alldata.applet.service.otitle"></div>
            <div class="stitle" v-text="alldata.applet.service.stitle"></div>
            <div class="service">
                <div class="item" v-for="(item, index) in alldata.applet.service.result" :key="index">
                    <div class="order" v-text="'0' + (index + 1)"></div>
                    <div class="figure">
                        <img :src="item.image" />
                    </div>
                    <div class="text" v-text="item.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue';
import Applet from '@/components/Swipers/Appdev.vue';
import Onebyone from '@/components/Carousel/Onebyone.vue';
import Phone from '@/components/Carousel/Phone.vue'
import Computed from '@/components/Carousel/Computed.vue'

export default {
    name: 'Appdev',
    components: {
        Banner,
        Applet,
        Onebyone,
        Phone,
        Computed
    },
    data() {
        return {
            banner: [
                {
                    image: require('@/assets/img/appdev/hangye1.png'),
                    title: '教育培训',
                    text: '在线交流、课程推广、预约上课、岗位招聘等'
                },
                {
                    image: require('@/assets/img/appdev/hangye2.png'),
                    title: '电商行业',
                    text: '宣传营销、支付交易、产品管理、物流管理等'
                },
                {
                    image: require('@/assets/img/appdev/hangye3.png'),
                    title: '餐饮美食',
                    text: '餐厅展示、定位导航、预约牌号、呼叫服务等'
                },
                {
                    image: require('@/assets/img/appdev/anli-chuxing.png'),
                    title: '教育培训',
                    text: '在线交流、课程推广、预约上课、岗位招聘等'
                },
                {
                    image: require('@/assets/img/appdev/anli-jinrong.png'),
                    title: '电商行业',
                    text: '宣传营销、支付交易、产品管理、物流管理等'
                },
                {
                    image: require('@/assets/img/appdev/anli-lvyou.png'),
                    title: '餐饮美食',
                    text: '餐厅展示、定位导航、预约牌号、呼叫服务等'
                },
                {
                    image: require('@/assets/img/appdev/anli-shejiao.png'),
                    title: '教育培训',
                    text: '在线交流、课程推广、预约上课、岗位招聘等'
                },
                {
                    image: require('@/assets/img/appdev/anli-shenghuo.png'),
                    title: '电商行业',
                    text: '宣传营销、支付交易、产品管理、物流管理等'
                },
                {
                    image: require('@/assets/img/appdev/anli-yiliao.png'),
                    title: '餐饮美食',
                    text: '餐厅展示、定位导航、预约牌号、呼叫服务等'
                }
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
    }
}
</script>

<style scoped lang="scss">

.mix {
    background: url('@/assets/img/appdev/A-bodian.png') no-repeat scroll right top/15.83vw auto;

    .blocks {
        display: flex;
        justify-content: center;
        padding: 5.52vw 0 6.35vw;

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:not(:last-of-type) {
                margin-right: 12.1875vw;
            }

            .figure {
                margin-bottom: 0.52vw;

                &>img {
                    width: 4.6875vw;
                }
            }

            .text {
                color: var(--text-color);
                font-size: var(--font-size-base);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }

            .line {
                margin-top: 1.5vw;
                width: 0.9375vw;
                height: 1px;
                background-color: var(--bg-color-origin);
            }
        }
    }

    .swiper_container {
        margin-bottom: 5vw;

        .heading {
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-h);
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .stitle {
            margin: 0.7vw 0 1.9vw;
            text-align: center;
            color: var(--text-color);
            font-size: var(--font-size-base);
            font-weight: var(--font-weight-sm);
            font-family: var(--font-family-zh);
        }


    }
}

.charge_container {
    width: 100%;
    height: 49.27vw;
    background: url('@/assets/img/appdev/anli.png') no-repeat scroll left top/100% auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .wrapper {
        width: 13.8vw;
        // background-color: skyblue;
        margin: 8vw 19vw 0 0;

        .total {
            color: var(--text-color-base);
            font-size: var(--font-size-sm);
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .title {
            margin: 1.25vw 0;
            color: var(--text-color-base);
            font-size: var(--font-size-h);
            font-weight: var(--font-weight-m);
            font-family: var(--font-family-zh);
        }

        .text {
            line-height: var(--font-size-l);
            color: var(--text-color-base);
            font-size: var(--font-size-ens);
            font-weight: var(--font-weight-sm);
            font-family: var(--font-family-zh);
        }

    }
}

.describe {
    // background-color: pink;
    overflow: visible;
    margin-top: 8.14vw;

    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        background: url('@/assets/img/appdev/B-bodian.png') no-repeat scroll right center;

        &:not(:last-of-type) {
            margin-bottom: 9.375vw;
        }

        .figure {
            width: 34.375vw;
            height: 34.375vw;
            background: url('@/assets/img/applet/yuanxing-bg.png') no-repeat center center/100% auto;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-right: 8vw;
            overflow: visible;

            /* &>img {
                width: 24.63vw;
                margin-top: -10%;
            } */
        }

        .special {
            position: relative;

            .wrapper {
                width: 38.69vw;
                position: static;
                margin-top: 18%;
                // margin-left: 7%;
            }

        }

        .content {
            width: 15vw;

            .order {
                display: flex;
                align-items: center;

                &>span {
                    color: var(--text-color-grey);
                    font-size: var(--font-size-base);
                    font-weight: var(--font-weight-m);
                    font-family: var(--font-family-zh);

                    &:first-child {
                        margin-top: -10%;
                        margin-right: 0.35vw;
                        color: var(--text-color);
                        font-size: var(--font-size-x);
                        font-weight: var(--font-weight-m);
                        font-family: var(--font-family-zh);
                    }
                }

                .line {
                    margin-left: 0.9375vw;
                    display: inline-block;
                    flex: 1;
                    height: 1px;
                    background-color: var(--text-color-grey);
                }
            }

            .title {
                margin: 1.875vw 0;
                color: var(--text-color-inverse);
                font-size: var(--font-size-h);
                font-weight: var(--font-weight-m);
                font-family: var(--font-family-zh);
            }

            .text {
                line-height: var(--font-size-l);
                color: var(--color-disabled);
                font-size: var(--font-size-ens);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }
        }

        &:nth-last-of-type(2n+1) {
            flex-direction: row-reverse;
            background: url('@/assets/img/appdev/C-bodian.png') no-repeat scroll left center;

            .figure {
                margin-left: 8vw;
                margin-right: 0;
            }
        }

        &:last-child {
            padding-bottom: 6.66vw;
            background-position: left bottom;
        }
    }
}

.service_container {

    .heading {
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-h);
        font-weight: var(--font-weight-m);
        font-family: var(--font-family-zh);
    }

    .stitle {
        margin: 1vw 0 4.375vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-base);
        font-weight: var(--font-weight-sm);
        font-family: var(--font-family-zh);
    }

    .service {
        display: flex;
        justify-content: center;
        padding: 0vw 0 10.9375vw;
        // background-color:antiquewhite;

        .item {
            width: 6.875vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.08);
            padding: 2.2vw 0vw 1.875vw;
            border-bottom: 0.1vw solid var(--bg-color-origin);
            position: relative;

            &:not(:last-of-type) {
                margin-right: 1vw;
            }

            .order {
                position: absolute;
                right: 0;
                top: 0;
                width: 1.5625vw;
                height: 1.5625vw;
                background-color: var(--bg-color-origin);
                border-radius: 0 0 0 100%;
                display: flex;
                justify-content: flex-end;
                // align-items: center;
                box-sizing: border-box;
                padding: 0.2vw 0.2vw 0 0;
                color: var(--text-color-base);
                font-size: var(--font-size-xs);
                font-weight: var(--font-weight-m);
                font-family: var(--font-family-zh);
            }

            .figure {
                margin-bottom: 1vw;

                &>img {
                    width: 2.29vw;
                }
            }

            .text {
                color: var(--text-color);
                font-size: var(--font-size-base);
                font-weight: var(--font-weight-sm);
                font-family: var(--font-family-zh);
            }
        }
    }
}
</style>

<template>
    <div>
        <div class="footer" style="margin-top: 0;" :class="{ bacg: $route.path === '/contact' }"
            v-if="alldata.nav.current.text==='English'">
            <div class="sign">
                <div class="left">
                    <span v-text="alldata.footer.signinfo.text"></span>
                </div>
                <div class="right">
                    <img :src="alldata.footer.signinfo.signbtn" alt="signbtn" @click="gotopage('/login')">
                </div>
            </div>
            <div class="contact">
                <div class="scan">
                    <div class="title" v-text="alldata.footer.scan.title"></div>
                    <div class="qr">
                        <div class="item" v-for="(item, index) in qr" :key="index">
                            <div class="figure">
                                <img :src="item.value" alt="qr_code">
                            </div>
                            <div class="result">
                                <span v-text="item.title"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="type">
                    <div class="title" v-text="alldata.footer.type.title"></div>
                    <div class="content">
                        <div class="item" v-for="(item, index) in info" :key="index">
                            <div class="th" v-text="item.title"></div>
                            <div class="td" v-text="item.value"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="copyright">
                <div class="item" v-for="(item, index) in copyright" :key="index">
                    {{ item.title }}: <a href="https://beian.miit.gov.cn">{{ item.value}}</a>
                </div>
            </div>
        </div>
        <!-- 页脚中文版 -->
        <div class="footer_container" v-else>
            <div class="info">
                <div class="follow">
                    <div class="title">
                        <div class="text" v-text="alldata.footer.scan.title"></div>
                        <div class="line"></div>
                    </div>
                    <div class="qr">
                        <div class="item" v-for="(item, index) in qr" :key="index">
                            <div class="figure">
                                <img :src="item.value" alt="qr_code">
                            </div>
                            <div class="result">
                                <span v-text="item.title"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contact">
                    <div class="title">
                        <div class="text" v-text="alldata.footer.type.title"></div>
                        <div class="line"></div>
                    </div>
                    <div class="content">
                        <div class="item" v-for="(item, index) in info" :key="index">
                            <div class="figure">
                                <img :src="figures[item.id]" alt="figure">
                            </div>
                            <div class="th" v-text="item.title"></div>
                            <div class="td" v-text="item.value"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="item" v-for="(item, index) in copyright" :key="index">
                    {{ item.title }}: <a href="https://beian.miit.gov.cn">{{ item.value}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            qr: [],
            info: [],
            copyright: [],
            figures: {
                '16': require('@/assets/img/home/shouji.png'),
                '18': require('@/assets/img/home/youxiang.png'),
                '20': require('@/assets/img/home/dizhi.png')
            }
        }
    },
    mounted() {
        this.getPreviousData();
    },
    methods: {
        getPreviousData() {
            this.qr = [];
            this.info = [];
            this.copyright = [];
            this.$http.post('commons/menus/footer', {
                language: this.alldata.nav.current.id
            }).then(res => {
                const { data } = res;
                if (data.code != 1) return this.$message.error(data.msg);
                for (let item of data.data) {
                    if (item.id == 23 || item.id == 32 || item.id == 22 || item.id == 31) {
                        this.qr.push(item)
                    } else if (item.id == 17 || item.id == 19 || item.id == 21 || item.id == 16 || item.id == 18 || item.id == 20) {
                        this.info.push(item);
                    } else if (item.id == 11 || item.id == 25 || item.id == 10 || item.id == 24) {
                        this.copyright.push(item);
                    }
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.footer {
    margin-top: 6vw;
    // background-color: pink;
    // padding-top: 15vw;
    background: url('@/assets/img/home/FOOT-BG.png') no-repeat scroll center bottom/100% auto;

    .sign {
        background: url('@/assets/img/home/register-BG.png') no-repeat scroll center/auto 100%;
        display: flex;
        justify-content: center;
        // align-items: center;
        visibility: hidden;
        // display: none;

        .left {
            width: 30%;
            // padding: 5vw 0 10vw;
            margin-right: 7.8125vw;
            padding: 4.5vw 0 9.5vw;

            &>span {
                color: var(--text-color-base);
                font-size: var(--font-size-l);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }

        .right {
            padding-top: 5vw;

            &>img {
                width: 14vw;
                cursor: pointer;
            }
        }
    }

    .contact {
        // background-color: skyblue;
        margin: 0 18.4375vw 3vw;
        display: flex;

        .scan {
            flex: 2;
            // background-color: yellowgreen;

            .title {
                margin-bottom: 1.5625vw;
                color: var(--bg-color-origin);
                font-size: var(--font-size-lg);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .qr {
                display: flex;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &:not(:last-child) {
                        margin-right: 1.5625vw;
                    }

                    .figure {
                        width: 10.5vw;
                        height: 10.5vw;
                        background-color: var(--bg-color-base);
                        border-radius: 1.5625vw;
                        margin-bottom: 1vw;

                        &>img {
                            width: 100%;
                        }
                    }

                    .result {

                        &>span {
                            color: var(--text-color-note);
                            font-size: var(--font-size-sm);
                            font-family: var(--font-family);
                            font-weight: var(--font-weight-s);
                        }
                    }
                }
            }
        }

        .type {
            flex: 1;
            // background-color: yellow;

            .title {
                margin-bottom: 1.5625vw;
                color: var(--bg-color-origin);
                font-size: var(--font-size-lg);
                font-family: var(--font-family);
                font-weight: var(--font-weight-m);
            }

            .content {

                .item {
                    color: var(--text-color-note);
                    font-size: var(--font-size-sm);
                    font-family: var(--font-family);
                    font-weight: var(--font-weight-s);

                    &:not(:first-child) {
                        margin-top: 0.5vw;
                    }

                    .th {
                        margin-bottom: 0.5vw;
                    }

                    .td {
                        line-height: var(--font-size-hug);
                    }
                }
            }
        }
    }

    .copyright {
        // background-color: #fff;
        margin: 0 18.4375vw;
        padding: 1.5vw 0;
        border-top: 1px solid #B3C0D1;
        display: flex;

        .item {
            color: var(--text-color-note);
            font-size: var(--font-size-xs);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);

            &:not(:last-child) {
                margin-right: 01vw;
            }
        }
    }
}

.bacg {
    background-color: var(--bg-color-diff)
}

.footer_container {
    background-color: var(--bg-color-big);
    padding: 2.1875vw 12.5vw;

    .info {
        display: flex;
        justify-content: space-between;

        .follow {
            margin-right: 3.4vw;

            .title {

                .text {
                    color: var(--text-color);
                    font-size: var(--font-size-l);
                    font-family: var(--font-family-zh);
                    font-weight: var(--font-weight-sm);
                }

                .line {
                    width: 1vw;
                    height: 0.1vw;
                    background-color: var(--text-color);
                    margin: 1.5vw 0;
                }
            }

            .qr {
                display: flex;

                .item {
                    &:not(:last-child) {
                        margin-right: 3.125vw;
                    }

                    .figure {

                        &>img {
                            width: 10.5vw;
                        }
                    }

                    .result {
                        margin-top: 0.5vw;
                        text-align: center;
                        color: var(--text-color);
                        font-size: var(--font-size-base);
                        font-family: var(--font-family-zh);
                        font-weight: var(--font-weight-sm);
                    }
                }
            }
        }

        .contact {

            .title {

                .text {
                    color: var(--text-color);
                    font-size: var(--font-size-l);
                    font-family: var(--font-family-zh);
                    font-weight: var(--font-weight-sm);
                }

                .line {
                    width: 1vw;
                    height: 0.1vw;
                    background-color: var(--text-color);
                    margin: 1.5vw 0;
                }
            }

            .content {
                display: flex;

                .item {
                    flex: 1;

                    .figure {

                        &>img {
                            width: 2.5vw;
                        }

                    }

                    .th,
                    .td {
                        color: var(--text-color);
                        font-size: var(--font-size-sm);
                        font-family: var(--font-family-zh);
                        font-weight: var(--font-weight-sm);
                    }
                }
            }
        }
    }

    .copyright {
        border-top: 0.1vw solid var(--text-color);
        margin-top: 1.6vw;
        padding-top: 1vw;
        text-align: center;
        color: var(--text-color);
        font-size: var(--font-size-base);
        font-family: var(--font-family-zh);
        font-weight: var(--font-weight-sm);
    }
}
</style>
<template>
    <div>
        <!-- <div @click="goBack(false)">返回注册</div>
        用户协议内容 -->
        <div class="agreement_container">
            <div class="back" @click="goBack(false)">
                <img :src="alldata.login.btn.back.icon" alt="icon">
                <span v-text="alldata.agreement.back"></span>
            </div>
            <div class="content" v-html="content"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Agreement',
    data() {
        return {
            content: ''
        }
    },
    mounted() {
        this.getPreviousData();
    },
    methods: {
        getPreviousData() {
            this.$http.post('index/agreement', {
                language: this.alldata.nav.current.id
            }).then(res => {
                const { data } = res;
                console.log(data);
                if (data.code !== 1) return this.$message.error(data.msg);
                this.content = data.data;
                console.log(this.content);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.agreement_container {
    margin: 0 18.75vw;

    .back {
        cursor: pointer;
        margin-bottom: 1vw;
        display: flex;
        align-items: center;

        &>img {
            width: 0.3125vw;
            margin-right: 0.3vw;
        }

        &>span {
            color: var(--color-disabled);
            font-size: var(--font-size-sm);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }
    }

    ::v-deep .content {
        color: var(--text-color);
        font-size: var(--font-size-sm) !important;
        font-family: var(--font-family);
        font-weight: var(--font-weight-s);

        font {
            color: var(--text-color);
            font-size: var(--font-size-sm) !important;
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }

        span {
            color: var(--text-color);
            font-size: var(--font-size-sm) !important;
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);
        }

    }
}
</style>
<template>
	<div>
		<Banner :bannerinfo="alldata.case.banner" @consult="waiting"></Banner>
		<Addvertisement :addverse="alldata.case.process" @learn="learning"></Addvertisement>
		<div class="trust">
			<div class="official" v-text="alldata.case.trust.otitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.case.trust.result" :key="index">
					<div class="figure">
						<img :src="item.image" alt="figure">
						<span v-text="item.title"></span>
					</div>
					<div class="explain">
						<div class="content" v-text="item.text"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="custom">
			<div class="official" v-text="alldata.case.custom.otitle"></div>
			<div class="subheading" v-text="alldata.case.custom.stitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.case.custom.result" :key="index">
					<div class="explain">
						<div class="title" v-text="item.title"></div>
						<div class="content" v-text="item.text"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="service">
			<div class="official" v-text="alldata.case.service.otitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.case.service.result" :key="index">
					<div class="figure">
						<img :src="item.image" alt="figure">
					</div>
					<div class="explain">
						<div class="title" v-text="item.title"></div>
						<div class="content" v-text="item.text"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="solution">
			<div class="official" v-text="alldata.case.solution.otitle"></div>
			<div class="result">
				<div class="item" v-for="(item, index) in alldata.case.solution.result" :key="index">
					<div class="figure">
						<img :src="item.image" alt="figure">
					</div>
					<div class="explain">
						<div class="title" v-text="item.title"></div>
						<div class="content" v-text="item.text"></div>
					</div>
				</div>
			</div>
			<div class="formcard">
				<div class="otitle" v-text="alldata.case.solution.formcard.otitle"></div>
				<div class="stitle" v-text="alldata.case.solution.formcard.stitle"></div>
				<div class="form">
					<div class="keyin">
						<div class="inputs" v-for="(item, index) in alldata.case.solution.formcard.placeholder"
							:key="index">
							<input class="textstyle" type="text" :placeholder="item.text" v-if="!item.isarea" />
							<textarea class="textstyle" :placeholder="item.text" resize="false" v-else></textarea>
						</div>
					</div>
					<div class="submitbtn">
						<button v-text="alldata.case.solution.formcard.btn"></button>
					</div>
				</div>
			</div>
		</div>
		<div class="mix">
			<div class="item" v-for="(item, index) in alldata.case.mix.result" :key="index">
				<div class="left">
					<div class="title">{{ item.title }}</div>
					<div style="display: flex;align-items: center;">
						<div class="order" v-if="index" style="margin-right: 0.146vw;">
							<img src="@/assets/img/case/order.png" style="width: 1.25vw" />
						</div>
						<div class="content">
							<div class="content_item" v-for="(aitem, aindex) in item.content" :key="aindex">
								<div class="step">
									<img src="@/assets/img/case/check-icon.png" v-if="!index" />
									<!-- <span class="order" v-else>{{ aindex+1 }}</span> -->
									<span>{{ aitem.text }}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="btn">
						<button @click="gotopage('/consult')" style="background-color: #F8A506">
							<span>{{ item.btn.text }}</span>
							<img :src="item.btn.image" />
						</button>
					</div>
				</div>
				<div class="right">
					<img :src="item.image" />
				</div>
			</div>
		</div>
		<div class="safe">
			<div class="left">
				<div class="stitle">{{ alldata.case.exhibition.top }}</div>
				<div class="official">{{ alldata.case.exhibition.bottom }}</div>
				<div class="operate">
					<div class="prev">
						<img src="@/assets/img/case/Compliance-Arrow-left.png" />
					</div>
					<div class="next">
						<img src="@/assets/img/case/Compliance-Arrow-right.png" />
					</div>
				</div>
			</div>
			<div class="right">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide">Slide 1</div>
						<div class="swiper-slide">Slide 2</div>
						<div class="swiper-slide">Slide 3</div>
					</div>
					<!-- 如果需要分页器 -->
					<div class="swiper-pagination"></div>

					<!-- 如果需要导航按钮 -->
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Banner from '@/components/Banner/Banner.vue'
import Addvertisement from '@/components/Common/Addvertisement.vue'
import Card from '@/components/Card/Card.vue'
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
	name: 'Case',
	data() {
		return {
			myswiper: ''
		}
	},
	components: {
		Banner,
		Addvertisement,
		Card
	},
	mounted() {
		this.myswiper = new Swiper('.swiper-container', {
			loop: true, // 循环模式选项

			// 如果需要分页器
			pagination: {
				el: '.swiper-pagination',
			},

			// 如果需要前进后退按钮
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			}
		})
	},
	methods: {
		waiting() {
			console.log("和首页跳转表单是否相同？");
			console.log(this.$store.state.userinfo);
			this.gotopage('/consult');
		},
		learning() {
			this.gotopage('/consult');
		}
	},
}
</script>

<style scoped lang="scss">
.trust {
	margin-top: 4vw;
	background: url('@/assets/img/case/choose-BG.png') no-repeat scroll center center;
	padding: 6.25vw 0 calc(4.5vw - 1.5625vw);

	.official {
		margin-bottom: 3vw;
		text-align: center;
		color: var(--text-color-base);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.result {
		margin: 0 18.4375vw;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.item {
			width: 31.8%;
			background-color: #fff;
			margin-bottom: 1.5625vw;
			box-sizing: border-box;
			padding: 2vw 1vw 3.6vw;
			border-radius: 1.5625vw;

			.figure {
				display: flex;
				align-items: center;
				margin-bottom: 1vw;

				&>img {
					width: 4.6875vw;
					margin-right: 0.5vw;
				}

				&>span {
					// display: inline-block;
					color: var(--text-color-inverse);
					font-size: var(--font-size-lg);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}
			}

			.explain {
				// background-color: aqua;

				.content {
					color: var(--text-color);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}
}

.custom {
	margin-top: 6.5vw;

	.official {
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.subheading {
		margin: 1.2vw 0 3.2vw;
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-sm);
		font-family: var(--font-family);
		font-weight: var(--font-weight-s);
	}

	.result {
		margin: 0 18.4375vw;
		display: flex;
		justify-content: space-between;

		.item {
			flex: 1;

			&:not(:last-child) {
				margin-right: 1vw;
			}

			.explain {

				.title {
					margin-bottom: 2.5vw;
					text-align: center;
					color: var(--text-color-inverse);
					font-size: var(--font-size-xxl);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}

				.content {
					width: 100%;
					text-align: center;
					color: var(--text-color);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}
}

.service {
	margin-top: 6vw;

	.official {
		margin-bottom: 4.2vw;
		text-align: center;
		color: var(--text-color);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.result {
		margin-bottom: calc(5.2vw - 3.75vw);
		margin: 0 18.4375vw;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.item {
			width: 24%;
			margin-bottom: 3.75vw;

			.figure {
				display: flex;
				justify-content: center;
				align-items: center;

				&>img {
					width: 7vw;
				}
			}

			.explain {

				.title {
					margin-bottom: 0.8vw;
					text-align: center;
					color: var(--text-color);
					font-size: var(--font-size-mid);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}

				.content {
					width: 100%;
					text-align: center;
					color: var(--text-color-grey);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}
}

.solution {
	height: 36.1vw;
	background: url('@/assets/img/case/solution-bg.png') no-repeat scroll center top/100% auto;
	padding-top: 4vw;
	box-sizing: border-box;
	margin-bottom: 16vw;

	.official {
		margin-bottom: 2.345vw;
		text-align: center;
		color: var(--text-color-base);
		font-size: var(--font-size-hug);
		font-family: var(--font-family);
		font-weight: var(--font-weight-m);
	}

	.result {
		margin: 0 18.4375vw 4vw;
		display: flex;

		.item {

			&:not(:last-child) {
				margin-right: 1vw;
			}

			.figure {
				display: flex;
				justify-content: center;

				&>img {
					width: 3.5vw;
				}
			}

			.explain {

				.title {
					margin: 1vw 0 0.5vw;
					// white-space: nowrap;
					text-align: center;
					color: var(--text-color-base);
					font-size: var(--font-size-mid);
					font-family: var(--font-family);
					font-weight: var(--font-weight-m);
				}

				.content {
					text-align: center;
					color: var(--text-color-base);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}

	.formcard {
		margin: 0 18.4375vw;
		padding: 2vw;
		background-color: #fff;
		box-shadow: 0px 42px 66px 0px rgba(0, 0, 0, 0.16);
		border-radius: 1.5625vw;

		.otitle {
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-lg);
			font-family: var(--font-family);
			font-weight: var(--font-weight-m);
		}

		.stitle {
			margin: 1vw 0 1.2vw;
			text-align: center;
			color: var(--text-color);
			font-size: var(--font-size-sm);
			font-family: var(--font-family);
			font-weight: var(--font-weight-s);
		}

		.form {

			.keyin {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;


				.inputs {
					width: 100%;
					height: 5.2vw;
					padding: 0.8vw 1vw;
					background-color: var(--bg-color-input);
					border-radius: 1.5625vw;
					margin-bottom: 1vw;

					&:not(:last-child) {
						width: 43%;
						height: auto;
						display: flex;
						align-items: center;
					}

					&>input {
						width: 100%;
						height: 100%;
						background-color: var(--bg-color-input);
						border: none;
					}

					&>textarea {
						width: 100%;
						height: 100%;
						background-color: var(--bg-color-input);
						border: none;
					}

					.textstyle {
						color: var(--text-color);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}

					::-webkit-input-placeholder {
						/* WebKit, Blink, Edge */
						color: var(--text-color-placeholder);
					}

					:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: var(--text-color-placeholder);
					}

					::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: var(--text-color-placeholder);
					}

					:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: var(--text-color-placeholder);
					}

				}
			}

			.submitbtn {

				&>button {
					background-color: var(--bg-color-btn);
					border-radius: 1.5625vw;
					padding: 1vw 3vw;
					color: var(--text-color-base);
					font-size: var(--font-size-sm);
					font-family: var(--font-family);
					font-weight: var(--font-weight-s);
				}
			}
		}
	}
}

.mix {

	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left {
			flex: 1;
			padding-left: 18.75vw;

			.title {
				margin-bottom: 2.8125vw;
				color: var(--text-color);
				font-size: var(--font-size-hug);
				font-weight: var(--font-weight-m);
				font-family: var(--font-family);
			}

			.content {

				.content_item {
					display: flex;
					align-items: center;

					&:not(:last-child) {
						margin-bottom: 1vw;
					}

					.step {
						&>img {
							width: 1vw;
							margin-right: 0.416vw;
						}

						&>span {
							color: var(--text-color);
							font-size: var(--font-size-sm);
							font-weight: var(--font-weight-m);
							font-family: var(--font-family);
						}
					}

				}

			}

			.btn {
				margin-top: 4vw;
				display: flex;
				// justify-content: center;

				&>button {
					padding: 1vw 2vw;
					border-radius: 30px;
					background-color: var(--bg-color-btn);

					&>span {
						margin-right: 1.25vw;
						color: var(--text-color-base);
						font-size: var(--font-size-sm);
						font-family: var(--font-family);
						font-weight: var(--font-weight-s);
					}

					&>img {
						width: 3vw;
					}
				}
			}
		}

		.right {

			&>img {
				width: 55.3125vw;
				height: 50vw;
			}
		}

		&:nth-child(2n) {
			margin-top: -8vw;
			flex-direction: row-reverse;

			.left {
				padding-left: 0;

				.content {

					.content_item {
						padding: 2.2vw 0;
						border-bottom: 1px solid #E5E5E5;
						margin: 0;
					}
				}

				.btn{
					display: none;
				}
			}
		}
	}
}

.safe {
	display: none;
	background-color: pink;

	.left {}

	.right {

		.swiper-container {
			width: 600px;
			height: 300px;
		}
	}
}
</style>

<template>
    <div class="item">
        <template v-if="vertical">
            <div class="figure">
                <img :src="item.image" alt="figure">
                <span v-text="item.title"></span>
            </div>
            <div class="explain">
                <div class="content" v-text="item.text"></div>
            </div>
        </template>
        <template v-else>
            <div class="figure">
                <img :src="item.image" alt="figure">
            </div>
            <div class="explain">
                <div class="title" v-text="item.title"></div>
                <div class="content" v-text="item.text"></div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Item',
    props: ['vertical','item']
}
</script>

<style lang="scss" scoped>
.item{
    background-color: skyblue;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// element-ui全部引入
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)

//element-ui按需引入，需要使用的组件单独配置成了一个文件
/* import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index' */

// import './assets/font_jiantou/iconfont.css'
import i18n from './locale'
import mixin from './mixin'
import axios from 'axios'

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.mixin(mixin)

// 配置请求根路径
// 测试接口前缀
// axios.defaults.baseURL = 'http://itest.wzmok.cn/api/';
// 生产接口前缀
axios.defaults.baseURL = 'https://www.nsd2020.com/api/'

// 挂在到Vue实例，后面可通过this.$http调用
Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

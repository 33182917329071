<template>
    <div class="footer">
        <div class="more" v-show="this.$route.path != '/backstage'">
            <div class="item" v-for="(item, index) in alldata.footer.special" :key="index">
                <span v-text="item.text" @click="gotopage(item.path)"></span>
            </div>
        </div>
        <div class="copyright"
            :class="{ allwid: this.$route.path == '/backstage', left: this.$route.path == '/backstage' }">
            <!-- <div v-text="alldata.footer.copyright" :class="{left: this.$route.path == '/backstage'}"></div> -->
            <div class="item" v-for="(item, index) in copyright" :key="index">
                {{ item.title }}: {{ item.value}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterRecruit',
    data() {
        return {
            copyright: []
        }
    },
    mounted() {
        this.getPreviousData();
    },
    methods: {
        getPreviousData() {
            this.copyright = [];
            this.$http.post('commons/menus/footer', {
                language: this.alldata.nav.current.id
            }).then(res => {
                const { data } = res;
                if (data.code != 1) return this.$message.error(data.msg);
                for (let item of data.data) {
                    if (item.id == 11 || item.id == 25||item.id==10||item.id==24) {
                        this.copyright.push(item);
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    overflow: auto;

    .more {
        margin: 3vw 18.4375vw 2vw;
        display: flex;
        justify-content: center;

        .item {
            padding: 0 3.5vw;
            border-right: 1px solid var(--text-color-grey);
            line-height: 0.8vw;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: 0;
            }

            &>span {
                cursor: pointer;
                color: var(--text-color);
                font-size: var(--font-size-sm);
                font-family: var(--font-family);
                font-weight: var(--font-weight-s);
            }
        }
    }

    .copyright {
        // background-color: #fff;
        margin: 0 18.4375vw;
        padding: 1.5vw 0;
        border-top: 1px solid #B3C0D1;
        display: flex;
        justify-content: center;

        .item {
            color: var(--text-color-special);
            font-size: var(--font-size-xs);
            font-family: var(--font-family);
            font-weight: var(--font-weight-s);

            &:not(:last-child) {
                margin-right: 1vw;
            }
        }
    }

    .left {
        justify-content: flex-start;
    }

    .allwid {
        margin: 0;
        padding: 1.5vw 3.5vw;
    }
}
</style>